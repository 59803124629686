import React, { useEffect } from "react";
import Header from "../../components/layout/header";
import {
  Box,
  makeStyles,
  Grid,
  Typography,
  Button
} from "@material-ui/core";
import useGlobalStyles from "../../utils/global-style";
import { NotificationManager } from "react-notifications";
import AppConstants from "../../utils/app-constants";
import { useState } from "react";
import Axios from "axios";
import {
  SHOW_ERROR_NOTIFICATION
} from "../../utils/app-util";
import Loader from "../../components/misc/loader";
import EditIcon from "@material-ui/icons/Edit";


const useStyles = makeStyles((theme) => ({
  appbar: {
    background: theme.palette.bg,
  },

  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },

  container: {
    marginTop: "1%",
    padding: theme.spacing(10),
  },

  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "5px",
    marginTop: "40px"
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  input: {
    display: "none",
  },
  img: {
    width: theme.spacing(50),
    height: theme.spacing(50),
  },
  imgCaptureButton: {
    position: "absolute",
    bottom: -10,
    right: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    boxShadow: "0px 5px 20px #00000000",
  },
  style_1: {
    width: "100%",
    marginLeft: "2%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  style_2: {
    width: "100%",
    marginLeft: "2%",
    maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 14,
  },
  dropdown: {
    width: "100%",
    marginLeft: "2%",
    //maxWidth: "50%",
    marginRight: "4%",
    backgroundColor: "white",
    borderRadius: 7,
    marginTop: "1%"
  },
  imageLoader: {
    position: "absolute",
    top: "40%",
    left: "35%",
  },
  signContainer: {
    minWidth: "30%",
    maxWidth: "35%",
  },
  signatureImage: {
    height: "100px",
    maxWidth: "80%",
    marginLeft: "15px",
  },
}));

const LetterHead = (props) => {
  const { respo } = props;

  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [age, setAge] = React.useState('');


  const [data, setData] = useState({
    id: "",
    logo: "",
  });


  const onChangeData = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };


  const [imgData, setImgData] = useState(respo?.topLetterHead);
  const [picture, setPicture] = useState(respo?.topLetterHead);

  const [faviconData, setFaviconData] = useState(respo?.bottomLetterHead);
  const [faviconImage, setFaviconImage] = useState(respo?.bottomLetterHead);

  const [logoStatus, setLogoStatus] = useState(false);
  const [faviState, setFaviState] = useState(false);

  const [receiptHeader, setReceiptHeader] = useState(respo?.receiptHeader);
  const [receiptHeaderData, setReceiptHeaderData] = useState(respo?.receiptHeader);
  const [receiptHeaderState, setreceiptHeaderState] = useState(false);



  const [receiptFooter, setReceiptFooter] = useState(respo?.receiptFooter);
  const [receiptFooterData, setReceiptFooterData] = useState(respo?.receiptFooter);
  const [receiptFooterStatus, setreceiptFooterStatus] = useState(false);


  const [loading, setLoading] = useState(false)

  const handleInputs = (e) => {

    const files = Array.prototype.slice.call(e.target.files);

    const supportedFormats = [
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only JPG, JPEG, and PNG files are allowed."
      );
      return;
    }
    
    const name = e.target.name;
    if (name === "topLetterHead") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setPicture(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImgData(reader.result);
          setLogoStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    if (name === "bottomLetterHead") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setFaviconImage(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setFaviconData(reader.result);
          setFaviState(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    if (name === "ReceiptHeader") {
  
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setReceiptHeader(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setReceiptHeaderData(reader.result);
          setreceiptHeaderState(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    if (name === "ReceiptFooter") {
      const value = e.target.files[0];
      if (e.target.files[0]) {
        setReceiptFooter(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setReceiptFooterData(reader.result);
          setreceiptFooterStatus(true);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };


  useEffect(() => {
    setLoading(true)
    setData({

      topLetterHead: respo?.topLetterHead,
      bottomLetterHead: respo?.bottomLetterHead,
      id: respo?._id,
    });

  }, []);


  const [lodingStatus, setLoadingStatus] = useState({
    logoLodingStatus: false,
    faviconLoadingStatus: false,
    formLoadingStatus: false,
    receiptFooterStatus: false,
    receiptHeaderStatus: false,
  })

  const handleSaveImage = async (type) => {


    const formData = new FormData();

    formData.append("id", respo._id);

    if (type === "topLetterHead") {
      formData.append("topHead", picture);
      setLoadingStatus({
        logoLodingStatus: true,
        faviconLoadingStatus: false,
        formLoadingStatus: false,
        receiptFooterStatus: false,
        receiptHeaderStatus: false,
      })
    }
    else if (type === "bottomLetterHead") {
      formData.append("bottomHead", faviconImage);
      setLoadingStatus({
        logoLodingStatus: false,
        faviconLoadingStatus: true,
        formLoadingStatus: false,
        receiptFooterStatus: false,
        receiptHeaderStatus: false,
      })

    }
    else if (type === "ReceiptHeader") {
      formData.append("receiptHeader", receiptHeader);
      setLoadingStatus({
        logoLodingStatus: false,
        faviconLoadingStatus: false,
        formLoadingStatus: false,
        receiptFooterStatus: true,
        receiptHeaderStatus: false,
      })
    }
    else if (type === "ReceiptFooter") {
      formData.append("receiptFooter", receiptFooter);
      setLoadingStatus({
        logoLodingStatus: false,
        faviconLoadingStatus: false,
        formLoadingStatus: false,
        receiptFooterStatus: false,
        receiptHeaderStatus: true,
      })
    }

    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}update_letter_heads`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response) {
        if (response.data.message.toString() === "Updated Successfully!") {
          setLogoStatus(false);
          setFaviState(false);
          setLoadingStatus({
            logoLodingStatus: false,
            faviconLoadingStatus: false,
            formLoadingStatus: false,
            receiptFooterStatus: false,
            receiptHeaderStatus: false,
          })

          NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
        } else {
          setLoadingStatus({
            logoLodingStatus: false,
            faviconLoadingStatus: false,
            formLoadingStatus: false,
            receiptFooterStatus: false,
            receiptHeaderStatus: false,
          })
          NotificationManager.success(
            "Something went wrong Please try again"
          );
        }
      }
    } catch (error) {
      console.log(error);
    }


    // if (type == "bottomLetterHead") {
    //     setLoadingStatus({
    //         logoLodingStatus: false,
    //         faviconLoadingStatus:true,
    //         formLoadingStatus:false,
    //       })

    //   const formData = new FormData();
    //   formData.append("bottomHead", type);
    //   formData.append("id", respo._id);
    //   formData.append("topHead", respo?.topLetterHead);
    //   try {
    //     const response = await Axios({
    //       method: "post",
    //       url: `${process.env.REACT_APP_API2_HOST}update_letter_heads`,
    //       data: formData,
    //       headers: { "Content-Type": "multipart/form-data" },
    //     });
    //     if (response) {
    //       if (response) {
    //         setFaviState(false);
    //         setFaviState(false);
    //         setLoadingStatus({
    //           logoLodingStatus: false,
    //           faviconLoadingStatus: false,
    //           formLoadingStatus:false,
    //         })

    //         NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS);
    //       } else {
    //         NotificationManager.success(
    //           "Something went wrong Please try again"
    //         );
    //         setLoadingStatus({
    //             logoLodingStatus: false,
    //             faviconLoadingStatus: false,
    //             formLoadingStatus:false,
    //           })
    //       }
    //     }
    //   } catch (error) {
    //   }
    // }



  };



  return (
    <>
      <Header title="Letter Head" />
      <div style={{ width: "93%", marginLeft: "2%", paddingBottom: "3%" }}>
        <Grid className={classes.logoContainer}>
          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Prescription Header
              </Typography>
            </Box>

            <div style={{ width: '100%' }}>
              <div class="col-12">
                <input
                  type="file"
                  placeholder="favicon"
                  name="topLetterHead"
                  class="form-control"
                  id="logo"
                  autocomplete="off"
                  onChange={handleInputs}
                  style={{ visibility: "hidden" }}
                />
                <div className="previewProfilePic">
                  {/* {loading ? (<Loader width="150px" height="15px" color="primary" />):( */}
                  <img
                    className="playerProfilePic_home_tile"
                    src={imgData || respo?.topLetterHead}
                    alt="logo"
                    style={{ width: '100%' }}
                  // width="160"
                  // style={{height:'30vh'}}
                  />
                  {/* )} */}
                </div>
              </div>
            </div>
            <br />
            {logoStatus ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
                onClick={() => handleSaveImage("topLetterHead")}
              >
                {lodingStatus.logoLodingStatus ? <Loader width="200px" height="20px" color="primary" /> : "SAVE"}
              </Button>
            ) : (
              <label htmlFor="logo">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  <EditIcon /> &nbsp; Edit
                </Button>
              </label>
            )}
            &nbsp;

          </Box>

          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Prescription Footer
              </Typography>
            </Box>

            <div class="col-12">
              <input
                type="file"
                placeholder="favicon"
                name="bottomLetterHead"
                class="form-control"
                id="favico"
                autocomplete="off"
                onChange={handleInputs}
                style={{ visibility: "hidden" }}
              />

              <div className="previewProfilePic">
                {/* {loading ? (<Loader width="150px" height="15px" color="primary" />):( */}
                <img
                  className="playerProfilePic_home_tile"
                  src={faviconData || respo?.bottomLetterHead}
                  alt="logo"
                  style={{ width: '100%' }}
                />
                {/* )} */}
              </div>
            </div>
            <br />
            {faviState ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
                onClick={() => handleSaveImage("bottomLetterHead")}
              >
                {lodingStatus.faviconLoadingStatus ? <Loader width="200px" height="20px" color="primary" /> : "SAVE"}
              </Button>
            ) : (
              <label htmlFor="favico">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  <EditIcon /> &nbsp; Edit
                </Button>
              </label>
            )}
            &nbsp;

          </Box>
        </Grid>
      </div>


      <div style={{ width: "93%", marginLeft: "2%", paddingBottom: "3%" }}>
        <Grid className={classes.logoContainer}>
          <Box className={classes.prescriptionSection}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Receipt Header
              </Typography>
            </Box>

            <div style={{ width: '100%' }}>
              <div class="col-12">
                <input
                  type="file"
                  placeholder="favicon"
                  name="ReceiptHeader"
                  class="form-control"
                  id="receipt-header"
                  autocomplete="off"
                  onChange={handleInputs}
                  style={{ visibility: "hidden" }}
                />
                <div className="previewProfilePic">
                  {/* {loading ? (<Loader width="150px" height="15px" color="primary" />):( */}
                  <img
                    className="playerProfilePic_home_tile"
                    src={receiptHeaderData}
                    alt="logo"
                    style={{ width: '100%' }}
                  // width="160"
                  // style={{height:'30vh'}}
                  />
                  {/* )} */}
                </div>
              </div>
            </div>
            <br />
            {receiptHeaderState ? (
              <Button
                variant="contained"
                color="primary"
                component="span"
                className={classes.button}
                onClick={() => handleSaveImage("ReceiptHeader")}
              >
                {lodingStatus.receiptFooterStatus ? <Loader width="200px" height="20px" color="primary" /> : "SAVE"}
              </Button>
            ) : (
              <label htmlFor="receipt-header">
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                >
                  <EditIcon /> &nbsp; Edit
                </Button>
              </label>
            )}
            &nbsp;

          </Box>

          <Box className={classes.prescriptionSection} style={{display:"none"}}>
            <Box
              mt="2rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" className={classes.prescriptionTitle}>
                Receipt Footer
              </Typography>
            </Box>
            <form method="post">
              <div class="col-12">
                <input
                  type="file"
                  placeholder="favicon"
                  name="ReceiptFooter"
                  class="form-control"
                  id="receipt-footer"
                  autocomplete="off"
                  onChange={handleInputs}
                  style={{ visibility: "hidden" }}
                />

                <div className="previewProfilePic">
                  {/* {loading ? (<Loader width="150px" height="15px" color="primary" />):( */}
                  <img
                    className="playerProfilePic_home_tile"
                    src={receiptFooterData}
                    alt="logo"
                    style={{ width: '100%' }}
                  />
                  {/* )} */}
                </div>
              </div>
              <br />
              {receiptFooterStatus ? (
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  className={classes.button}
                  onClick={() => handleSaveImage("ReceiptFooter")}
                >
                  {lodingStatus.receiptHeaderStatus ? <Loader width="200px" height="20px" color="primary" /> : "SAVE"}
                </Button>
              ) : (
                <label htmlFor="receipt-footer">
                  <Button
                    variant="contained"
                    color="primary"
                    component="span"
                    className={classes.button}
                  >
                    <EditIcon /> &nbsp; Edit
                  </Button>
                </label>
              )}
              &nbsp;
            </form>
          </Box>
        </Grid>
      </div>
    </>
  );
};

export default LetterHead;
