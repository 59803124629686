import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from "react-router-dom"
import DataService from '../../utils/services/data-service'
import { Grid, makeStyles } from '@material-ui/core'
import Api from '../../utils/api'
import useGlobalStyles from '../../utils/global-style'
import { DEFAULT_ERROR_CALLBACK } from '../../utils/app-util'
import { getFormattedDate } from '../../utils/app-util'
import Dialog from '@mui/material/Dialog'
import Slider from 'react-slick'
import Header from '../../components/layout/header'
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Loader from '../../components/misc/loader'
import Api2 from '../../utils/api2'
import { useHistory } from "react-router-dom"
import Routes from '../../routes/routes'
import PatientInfoCard from './ipd/PatientInfoCard'
import { CustomDataGrid } from '../../components/grid/mui/CustomDataGrid'
import { AppBar, Toolbar, IconButton, Typography, Box, Tooltip } from '@mui/material'
import dayjs from 'dayjs'
import { useTheme } from "@material-ui/core/styles";
import VisibilityIcon from '@mui/icons-material/Visibility'
import GetAppIcon from '@mui/icons-material/GetApp'
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import { saveAs } from 'file-saver'
import { useGetAdmitInfo } from '../../apis/AdmitAPI'
import DeleteReportConfirmationModal from '../../components/modal/DeleteReportConfirmationModal'
import { DeleteReport } from '../../apis/ReportAPI'
import AppConstants from '../../utils/app-constants'

const NewReport = () => {
    const history = useHistory();
    const useStyles = makeStyles((theme) => ({
        queue: {
            width: '40px',
            textAlign: 'center',
        },
        presBox: {
            height: "20vh",
            overflowY: "scroll",
        },
        container: {
            margin: '20px auto',
            width: '96%',
            padding: theme.spacing(8),
            borderRadius: theme.spacing(0, 6, 6, 6),
        },
        textStyle: {
            color: theme.palette.common.black,
            fontWeight: 'bold',
            opacity: '0.8',
            fontSize: '0.7rem',
            width: '10%',
        },
        textMoreStyle: {
            color: theme.palette.common.black,
            fontWeight: 'bold',
            opacity: '0.8',
            fontSize: '0.7rem',
            width: '4%',
        },
        cellTextStyle: {
            color: theme.palette.common.black,
            fontSize: '0.7rem',
        },
        calledCellStyle: {
            backgroundColor: '#faebd7',
        },

        header_styling: {
            // margin: "0px",
            width: '1%',

            // gap: "0"

        },


        body_styling: {
            width: '1%',
            // margin: "0px",
            // gap: "0"



        },



        icon: {
            margin: theme.spacing(0, 4),
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            opacity: '0.8',
            padding: theme.spacing(4),
            '&:hover': {
                backgroundColor: theme.palette.common.black,
                color: theme.palette.common.white,
                opacity: '0.6',
            },
            '&:disabled': {
                border: '1px solid rgba(0, 0, 0, 0.26)',
            },





        },

    }));
    const globalClass = useGlobalStyles();
    const classes = useStyles();
    const theme = useTheme();

    const [openReport, setOpenReport] = useState(false);
    const [patientName, setPatientName] = useState();
    const [roomNumber, setRoomNumber] = useState();
    const [doctorName, setDoctorName] = useState();
    const [patientUhid, setPatientUhid] = useState();
    const [provisionalDiagnosis, setProvisionalDiagnosis] = useState();
    const [plan, setPlan] = useState();
    const [patientStatus, setPatientStatus] = useState();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [reportToDelete, setReportToDelete] = useState(null);
    const [mobileNumber, setMobileNumber] = useState();


    // delete report mutation
    const { mutate: deleteReport } = DeleteReport();

    const handleCloseReport = useCallback(() => {
        setOpenReport(false);
    }, []);

    const handleImageClick = (val) => {
        let data = val;
        let w = window.open("about:blank");
        let image = new Image();
        image.src = data;
        setTimeout(function () {
            w.document.write(image.outerHTML);
        }, 0);
    };

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search)
    const slug = searchParams.get('slug');
    const admitId = searchParams.get('admitId');

    // fetch patient profile info with patient slug
    const { data: patientProfileInfo, isLoading: isPatientProfileInfoLoading } = useGetAdmitInfo(admitId);



    const [patientReports, setPatientReports] = useState([]);
    const [isloading, setisloading] = useState(false)
    const [patientdetails,setPatientDetails]=useState({})

    useEffect(() => {
        if (patientProfileInfo) {
            setPatientName(patientProfileInfo?.patient?.name);
            setPatientUhid(patientProfileInfo?.patient?.uhid);
            setPatientStatus(patientProfileInfo?.status);
            setProvisionalDiagnosis(patientProfileInfo?.prov);
            setPlan(patientProfileInfo?.plan);
            setRoomNumber(patientProfileInfo?.roomNumber);
            setDoctorName(patientProfileInfo?.doctor?.name);
            setMobileNumber(patientProfileInfo?.patient?.mobileNumber);
        }
    }, [patientProfileInfo])

    // fetch reports
    const fetchReports = useCallback(() => {
        setisloading(true);
        const subscriptions = [];
    
        const fetchReportsSubscription = DataService.fetchList("report/search", 0, 100, {
            patientSlug: {
                values: [slug],
                filterType: "set",
            },
        }).subscribe({
            next: (resp) => {
                const reports = [...resp.content];
                const newestReports = reports.reverse();
                setPatientReports(newestReports);
                setisloading(false);
            },
            error: (error) => {
                console.error("Error fetching reports:", error);
                setisloading(false);
            }
        });
    
        subscriptions.push(fetchReportsSubscription);
    
        return () => {
            subscriptions.forEach(subscription => subscription.unsubscribe());
        };
    }, [slug]);

    useEffect(() => {
        fetchReports();
    }, [fetchReports]);

    const closeDeleteModal = () => {
        setReportToDelete(null);
        setOpenDeleteModal(false);
    }

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    const [selectedReport, setSelectedReport] = useState([]);
    const [selectSingleImage, setSelectSingleImage] = useState(null)
    const [reportType, setReportType] = useState(null);

    const handleSelectedReport = (report) => {
        setSelectedReport(report);
        Api.get(`report/${report.slug}`).subscribe(
            (resp) => {
                setSelectSingleImage(resp.data.data)
                setOpenReport(true);
                if (resp.data.data?.images) {
                    let fileType = null;
                    fileType = resp.data.data.images.map((data) => data.contenttype);
                    setReportType(fileType);
                }
            },
            (error) => {
                DEFAULT_ERROR_CALLBACK(error);
            }
        );
    };

    const handleAddReport = () => {
        history.push(`${Routes.REPORT}?patientSlug=${slug}&appointmentSlug=${''}`)
    }

    const [selectedReports, setSelectedReports] = useState([]);

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            setSelectedReports(patientReports.map((report) => report.id));
        } else {
            setSelectedReports([]);
        }
    };

    const handleSelectClick = (event, id) => {
        const selectedIndex = selectedReports.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedReports, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedReports.slice(1));
        } else if (selectedIndex === selectedReports.length - 1) {
            newSelected = newSelected.concat(selectedReports.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedReports.slice(0, selectedIndex),
                selectedReports.slice(selectedIndex + 1),
            );
        }

        setSelectedReports(newSelected);
    };

    const handleViewReport = (event, report) => {
        event.stopPropagation();
        handleSelectedReport(report);
    };

    const handleDownloadReport = (event, report) => {
        event.stopPropagation();
        Api.get(`report/${report.slug}`).subscribe(
            (resp) => {
                const reportData = resp.data.data;
                if (reportData?.images && reportData.images.length > 0) {
                    reportData.images.forEach((image, index) => {
                        const blob = dataURItoBlob(image.content);
                        const fileName = `${reportData.reportName}_${index + 1}.${getFileExtension(image.contenttype)}`;
                        saveAs(blob, fileName);
                    });
                } else {
                    console.error('No images found in the report');
                    // You might want to show a user-friendly message here
                }
            },
            (error) => {
                console.error('Error downloading report:', error);
                DEFAULT_ERROR_CALLBACK(error);
            }
        );
    };

    // handle delete report with modal 2FA
    const handleDeleteReport = (event, report) => {
        event.stopPropagation();
        setReportToDelete(report);
        setOpenDeleteModal(true);
    }

    const confirmDeleteReport = (reportId) => {
        deleteReport(reportId, {
            onSuccess: () => {
                fetchReports();
            }
        });
    }

    // Helper function to convert data URI to Blob
    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: mimeString });
    };

    // Helper function to get file extension from content type
    const getFileExtension = (contentType) => {
        switch (contentType) {
            case 'image/jpeg':
                return 'jpg';
            case 'image/png':
                return 'png';
            case 'application/pdf':
                return 'pdf';
            default:
                return 'unknown';
        }
    };

    const [zoomLevel, setZoomLevel] = useState(100);

    return (
        <div>
            <Header showBackBtn title="Report" />
            <Box sx={{ paddingLeft: "40px", paddingRight: "40px" }}>
                <PatientInfoCard 
                    patientName={patientName}
                    patientUhid={patientUhid}
                    patientStatus={patientStatus}
                    provisionalDiagnosis={provisionalDiagnosis}
                    plan={plan}
                    roomNumber={roomNumber}
                    doctorName={doctorName}
                    mobileNumber={mobileNumber}
                 />
            </Box>

            <Grid container item style={{ display: "flex", justifyContent: "center", paddingLeft: "40px", paddingRight: "40px", paddingTop: "20px" }}>
                {isloading ? (
                    <Loader />
                ) : (
                    <Box sx={{ width: "100%", height: "calc(100vh - 250px)" }}>
                        <CustomDataGrid
                            rows={patientReports}
                            columns={[
                                { field: 'reportName', headerName: 'Report Name', flex: 10 },
                                { 
                                    field: 'reportDate', 
                                    headerName: 'Report Date', 
                                    flex: 4, 
                                    valueFormatter: (params) => dayjs(params).format(AppConstants.DATE_FORMAT)
                                },
                                {
                                    field: 'actions',
                                    headerName: 'Actions',
                                    width: "120px",
                                    flex: 2,
                                    align: 'center',
                                    renderCell: (params) => (
                                        <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            
                                            <Tooltip title="View Report">
                                                <IconButton onClick={(event) => handleViewReport(event, params.row)}>
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Download Report">
                                                <IconButton onClick={(event) => handleDownloadReport(event, params.row)}>
                                                    <GetAppIcon />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete Report">
                                                <IconButton onClick={(event) => handleDeleteReport(event, params.row)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Box>
                                    ),
                                },
                            ]}
                            pageSize={10}
                            rowsPerPageOptions={[5, 10, 20]}
                            disableSelectionOnClick
                            toolBarButtonTitle="Add Report"
                            handleToolBarButtonClick={handleAddReport}
                            sx={{ width: '100%' }}
                        />
                    </Box>
                )}
            </Grid>
            <Dialog
                className={classes.dialogBackground}
                fullScreen
                open={openReport}
                onClose={handleCloseReport}
                
            >
                <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                    <AppBar position="static" elevation={0} sx={{ backgroundColor: theme.palette.primary.main }}>
                        <Toolbar sx={{ justifyContent: 'space-between', padding: theme.spacing(1, 2) }}>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleCloseReport}
                                aria-label="close"
                                sx={{ marginRight: theme.spacing(2) }}
                            >
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                {selectSingleImage?.reportName}
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="subtitle1" sx={{ marginRight: theme.spacing(2) }}>
                                    {getFormattedDate(selectSingleImage?.reportDate, "Do MMMM YYYY")}
                                </Typography>
                                {selectSingleImage?.value && (
                                    <Typography variant="subtitle1">
                                        Value: {selectSingleImage?.value}
                                    </Typography>
                                )}
                            </Box>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ flexGrow: 1, overflow: 'hidden', position: 'relative' }}>
                        {selectSingleImage?.images ? (
                            <Slider
                                {...settings}
                                prevArrow={<IconButton><ArrowBackIosIcon /></IconButton>}
                                nextArrow={<IconButton><ArrowForwardIosIcon /></IconButton>}
                            >
                                {selectSingleImage.images.map((im, index) => (
                                    <Box key={index} sx={{ height: 'calc(100vh - 64px)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {im?.contenttype === "application/pdf" ? (
                                            <iframe
                                                title={`Report picture ${index + 1}`}
                                                src={im.content}
                                                style={{ width: '100%', height: '100%', border: 'none' }}
                                            />
                                        ) : (
                                            <img
                                                src={im?.content}
                                                alt={`Report ${index + 1}`}
                                                style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', transform: `scale(${zoomLevel / 100})` }}
                                                onClick={() => handleImageClick(im?.content)}
                                            />
                                        )}
                                    </Box>
                                ))}
                            </Slider>
                        ) : (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Typography variant="h6">Report image is not available.</Typography>
                            </Box>
                        )}
                    </Box>
                    <Box sx={{ position: 'absolute', bottom: 16, right: 16 }}>
                        <IconButton onClick={() => setZoomLevel(prev => Math.min(prev + 10, 200))}>
                            <ZoomInIcon />
                        </IconButton>
                        <IconButton onClick={() => setZoomLevel(prev => Math.max(prev - 10, 50))}>
                            <ZoomOutIcon />
                        </IconButton>
                    </Box>
                </Box>
            </Dialog>
            {/* Delete Confirmation Modal */}
            <DeleteReportConfirmationModal 
                open={openDeleteModal} 
                onClose={closeDeleteModal} 
                reportToDelete={reportToDelete}
                onConfirm={confirmDeleteReport} 
            />
        </div>
    )
}

export default NewReport
