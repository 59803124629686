import React, { Component} from "react";
import { AgGridReact } from "ag-grid-react";
import GridAction from "./grid-action";
import GRID_OPTIONS from "./grid-options";
import ServerDataSource from "../../utils/server-data-source";
import AppConstants from "../../utils/app-constants";
import moment from "moment";
import fileDownload from "js-file-download";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_ERROR_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../utils/app-util";
import Api from "../../utils/api";
import Bro from "brototype";
import AlertDialog from "../layout/alert-dailog";
import { validateEmail } from '../../utils/app-util'
import { NotificationManager } from "react-notifications";
import Axios from "axios";
import AuthService from "../../utils/services/auth-service";
import Api2 from "../../utils/api2";
import GridHeaderCheckbox from "./GridHeaderCheckbox";


class GenericGrid extends Component {

  state = { editMode: false, visible: false, isDialogVisible: false, data: {} };

  newDataState = {}
  
  componentDidMount() {
    this.baseUrl = this.props.baseUrl;
    this.columnDefGenerator = this.props.columnDefGenerator;
    let columnDefs = [];
    if (this.props.checkboxSelection) {
      columnDefs = [{
        checkboxSelection: true,
        headerComponentFramework: GridHeaderCheckbox,
        width: 100,
        pinned: "left",
      }, ...new this.columnDefGenerator().generateColumns().map(colDef => ({
          ...colDef,
          minWidth: 100
        }))
      ]
    } else {
      columnDefs = new this.columnDefGenerator().generateColumns().map(colDef => ({
          ...colDef,
          minWidth: 100
        }))
    }
    this.setState({ columnDefs });

    // Add event listener for window resize
    window.addEventListener('resize', this.onWindowResize);
  }

  componentWillUnmount() {
    // Remove event listener when component unmounts
    window.removeEventListener('resize', this.onWindowResize);
  }

  onWindowResize = () => {
    if (this.gridApi) {
      this.gridApi.sizeColumnsToFit();
    }
  };

  onGridReady = (params) => {
    const { defaultFilter } = this.props;
    this.gridApi = params.api;
    this.columnApi = params.coloumnApi;
    const datasource = ServerDataSource.instance({
      size: AppConstants.PAGE_SIZE,
      url: this.props.baseUrl,
      defaultFilter,
    });
    

    this.gridApi.setServerSideDatasource(datasource);
    if (!this.props.suppressAutoFit) {
      params.api.sizeColumnsToFit();
    }

  };
  
 

  onChatBtnClick = (data) => {
    this.props.onChatBtnClick(data)
  }

  onUpdateBtnClicked = (data) => {
    this.props.onUpdateBtnClicked(data);
  };

  onEyeBtnClicked = (data) => {
    this.props.onEyeBtnClicked(data);
  };
  onMailButtonClicked=(data)=>{
   
    this.props.onMailButtonClicked(data);
  }

  onbillbuttonBtnClicked = (data) => {
    this.props.onbillbuttonBtnClicked(data);
  };

  onLogButtonClicked = (data) => {
    this.props.onLogButtonClicked(data);
  };
  onfeedbackButtonClicked = (data) => {
    this.props.onfeedbackButtonClicked(data);
  };

  onBlockButtonClick = (data) => {
    this.props.onBlockButtonClick(data);
  }

  handleClose = () => {
    this.setState({ isDialogVisible: false });
  };

  confirmPayment = () => {
    const { data } = this.state;
    Api.put(`order/status/${data.slug}/CONFIRMED`).subscribe((resp) => {
      SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
      this.gridApi.purgeServerSideCache();
      this.handleClose();
    });
  };

  handleCellUpdate = (url, data) => {

    Api.put(url, data).subscribe(
      () => {
        NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS)
      },
      (err) => {
        DEFAULT_ERROR_CALLBACK(err);
        this.gridApi.purgeServerSideCache();
      }
    );
  };

  onCellValueChanged = (params) => {
    if (params.newValue === params.oldValue) {
      return;
    }
    let url;
    let field;
    field = Bro(params).iCanHaz("colDef.field");

    // if (this.baseUrl === "doctor") {
    //   url = this.baseUrl + "?propChanged=" + "speciality";
    //   console.log('test_baseurlif',url);
    // }

    // else {
    //   url = this.baseUrl + "?propChanged=" + field;
    //   console.log('test_baseurlelse',url);
    // }
    if (this.baseUrl === "doctor" && field === "specialityName") {
      field = 'speciality'
    }

    url = this.baseUrl + "?propChanged=" + field;
    const { data } = params;
    data.speciality = data.specialityName
    data.specialityName = ""

    delete data.profilePic;
    delete data.profilePicString;
    delete data.signature;
    delete data.topLetterHead;
    delete data.bottomLetterHead;
    delete data.paymentOptions

    if (!data) {
      return;
    }
    if (this.baseUrl !== "referral-doctors") {
      this.handleCellUpdate(url, data);
    }
    else {
      const { email, name, mobileNumber, _id } = data;
      const payload = { email, name, mobileNumber };
      Axios.patch(`${process.env.REACT_APP_API2_HOST}referral-doctors/${_id}`, payload)
        .then(() => {
          NotificationManager.success(AppConstants.MSG.UPDATE_SUCCESS)
        })
        .catch((err) => {
          DEFAULT_ERROR_CALLBACK(err)
          this.gridApi.purgeServerSideCache();
        })
    }
  };
  

  onToggleEditMode = (value) => {
    const columnDefs = new this.columnDefGenerator(value).generateColumns();
    this.setState({ columnDefs, editMode: value });
  };

  onGlobalSearch = (value, params) => {
    const val = value.search;
    const filters = this.gridApi.getFilterModel();
    filters["_"] = { filter: val ? val : "" };
    this.gridApi.setFilterModel(filters);

  };

  onReset = () => {
    this.gridApi.setFilterModel(null);
    this.gridApi.onFilterChanged();
    this.searchFormApi && this.searchFormApi.reset();
  };

  onRowSelectionChanged = (event) => {
    this.setState({
      selectedIds: event.api.getSelectedNodes().map((node) => (node.data.id || node.data._id)),
      SelectpatientSlug: event.api.getSelectedNodes().map((node) => (node.data.slug)),
      row: event.api.getSelectedNodes().map((node) => node.data),
    });
  };


  onDelete = async () => {
    const token = AuthService.getToken();
    const { selectedIds } = this.state;


    if (!selectedIds || selectedIds.length <= 0) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_ROW_MSG);
    } else {
      var r = window.confirm(AppConstants.MSG.DELETE_CONFIRM);
      if (r === true) {
        if (this.baseUrl === "referral-doctors") {
          await Axios.delete(`${process.env.REACT_APP_API2_HOST}referral-doctors/${selectedIds}`)
            .then(() => {
              SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.DELETE_SUCCESS);
              this.gridApi.purgeServerSideCache();
              this.gridApi.deselectAll();
            });
        }
        else if (this.baseUrl === "patient" && (this.user.roleName === "DOCTORADMIN" || this.user.roleName === "DOCTOR")) {
          const formData = new FormData();
          formData.append("slug", this.state.SelectpatientSlug);

          try {
            const response = await Axios({
              method: "post",
              url: `${process.env.REACT_APP_API_HOST}/patient/delete_all_patient_history`,
              data: formData,
              headers: { "Content-Type": "application/json", "X-AUTH-TOKEN": token },
            });
            SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.DELETE_SUCCESS);
            this.gridApi.purgeServerSideCache();
            this.gridApi.deselectAll();
          } catch (error) {
            DEFAULT_ERROR_CALLBACK(error);
            this.gridApi.deselectAll();
          }
        }
        else {
          Api.post(`${this.baseUrl}/delete`, selectedIds).subscribe(
            (resp) => {

              SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.DELETE_SUCCESS);
              this.gridApi.purgeServerSideCache();
              this.gridApi.deselectAll();
            },
            (error) => {
              DEFAULT_ERROR_CALLBACK(error);
              this.gridApi.deselectAll();
            }
          );
        }
      }
    }
  };

  customAction = (action) => {
    const { selectedIds } = this.state;
    if (!selectedIds || selectedIds.length <= 0) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_ROW_MSG);
    } else {
      var r = window.confirm(AppConstants.MSG.DELETE_CONFIRM);
      if (r === true) {
        action(selectedIds).subscribe(
          () => {
            SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.UPDATE_SUCCESS);
            this.gridApi.purgeServerSideCache();
            this.gridApi.deselectAll();
          },
          (error) => {
            DEFAULT_ERROR_CALLBACK(error);
            this.gridApi.deselectAll();
          }
        );
      }
    }
  };

  bulkUpdateOperation = (actionCb) => {
    actionCb(this.gridApi?.getDisplayedRowCount() || 0, this.gridApi);
  };

  user = JSON.parse(localStorage.getItem("USER"));
  defaultFilter = {
    doctorSlug: { filterType: 'set', values: [`${this.user.slug}`] },
  }

  onExport = () => {
    const { csvName } = this.props;

    if (this.baseUrl === "emr-template") {
      Api2.get(`export_emr?slug=${this.user.slug}`
      ).subscribe((resp) => {
        if (resp) {

          var str = resp.data.data;
          // var finalData = str.replace(/\\/g, "");
          if (str.includes('\"')) { str = JSON.parse(str); }
          let filename = "emr-template.json";
          let contentType = "application/json;charset=utf-8;";
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(str)))], { type: contentType });
            navigator.msSaveOrOpenBlob(blob, filename);
          } else {
            var a = document.createElement('a');
            a.download = filename;
            a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(str));
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        }

      })
    }

    else {
      Api.post(
        `${this.baseUrl}/export`,
        {
          filterModel: this.gridApi.getFilterModel(),
          defaultFilter: this.defaultFilter
        },
        { responseType: "blob" }
      ).subscribe(
        (response) => {

          var BOM = "\uFEFF";
          fileDownload(
            response.data,
            `${moment().format("YYYYMMDD_HHmmss")}_${csvName}.csv`,
            "text/csv; charset=utf-8",
            BOM
          );
        },
        (error) => {
          if (error.response) {
            DEFAULT_ERROR_CALLBACK(error);
          }
        }
      );
    }
  };

  onUpload = (data) => {
    SHOW_SUCCESS_NOTIFICATION(data.message || AppConstants.MSG.IMPORT_SUCCESS);
    this.gridApi.purgeServerSideCache();
  };

  clonedElementWithMoreProps = (comp, props) => {
    return React.cloneElement(comp, props);
  };

  setSearchFormApi = (api) => {
    this.searchFormApi = api;

  };

  onCopy = () => {
    const { row } = this.state;
    if (!row) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.SELECT_ROW_MSG);
      return;
    }
    if (row.length > 1) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.COPY_WARN);
      return;
    }
    if (row[0]) {
      let tempRow = row[0];
      tempRow.name =
        row[0].name + ` - Copy (${moment().format("MMDDYYYYhmmss")})`;
      delete tempRow.id;
      delete tempRow.createdBy;
      delete tempRow.createdByUser;
      delete tempRow.dateCreated;
      delete tempRow.dateModified;
      delete tempRow.lastModifiedBy;
      delete tempRow.lastModifiedByUser;
      delete tempRow.slug;
      delete tempRow.speciality;
      this.create(tempRow);
    }
  };

  create = (data) => {
    Api.post(`${this.baseUrl}`, data).subscribe(
      (data) => {
        this.setState({ visible: false });
        SHOW_SUCCESS_NOTIFICATION(AppConstants.MSG.ADD_SUCCESS);
        this.gridApi.purgeServerSideCache();
      },
      (error) => {
        this.setState({ visible: false });
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  refreshGridData = () => {
    if (this.gridApi) {
      this.gridApi.purgeServerSideCache();
    }
  }

  render() {
    const {
      title,
      gridOptions,
      addNewComponent,
      hideToggleEditMode,
      hideDelete,
      hideExport,
      hideOnUpload,
      hideOnCopy,
      hideOnActivate,
      hideOnResetPassword,
      hideAddNew,
      hideGlobalSearch,
      isListView,
      toggleView,
      addText,
      customActions = [],
      bulkOperationOnRows = [],
      // LogButton,
    } = this.props;
    
    const { columnDefs, visible, isDialogVisible } = this.state;
    const options = gridOptions || GRID_OPTIONS;
    const decoratedCustomActions = customActions.map((act) => {
      return { ...act, action: () => this.customAction(act.onClick) };
    });
  
    const bulkUpdateOperation = bulkOperationOnRows.map((act) => {
      return { ...act, action: () => this.bulkUpdateOperation(act.onClick) };
    });


    return (
      <React.Fragment>
        <GridAction
          title={title}
          addText={addText}
          onGroupBtnClick={toggleView}
          isListView={isListView}
          onCopy={!hideOnCopy && this.onCopy}
          getSearchFormApi={this.setSearchFormApi}
          onReset={this.onReset}
          onExport={!hideExport && this.onExport}
          onUpload={!hideOnUpload && this.onUpload}
          onResetPassword={!hideOnResetPassword && this.onResetPassword}
          onActivate={!hideOnActivate && this.onActivate}
          onDelete={!hideDelete && this.onDelete}
          baseUrl={this.baseUrl}
          onGlobalSearch={!hideGlobalSearch && this.onGlobalSearch}
          hideAddNew={hideAddNew}
          onToggleEditMode={!hideToggleEditMode && this.onToggleEditMode}
          customActions={decoratedCustomActions}
          bulkOperationOnRows={bulkUpdateOperation}
        ></GridAction>

        {!visible && (
            <div
              className="ag-theme-material grid-size"
            >
              <AgGridReact
                context={this}
                onCellValueChanged={this.onCellValueChanged}
                onGridReady={this.onGridReady}
                columnDefs={columnDefs}
                pagination={true}
                onSelectionChanged={this.onRowSelectionChanged}
                getRowClass={(params) => {
                  return params?.data?.isBlocked ? 'blocked-row' : '';
                }}
                {...options}
              ></AgGridReact>
            </div>
        )}
        {visible &&
          this.clonedElementWithMoreProps(addNewComponent, {
            visible,
            onHide: () => this.setState({ visible: false }),
            onSubmit: this.create,
          })}
        {isDialogVisible && (
          <AlertDialog
            open={isDialogVisible}
            handleClose={this.handleClose}
            onAgreedBtnClicked={this.confirmPayment}
          />
        )}
      </React.Fragment>
    );
  }
}

export default GenericGrid;
