import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import { Badge, ListItem } from "@material-ui/core";

import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ChatIcon from "@material-ui/icons/ChatBubbleOutline";
import PeopleIcon from "@material-ui/icons/PeopleAltOutlined";
import WorkOutlineIcon from '@material-ui/icons/WorkOutline';
import AssignmentIcon from "@material-ui/icons/AssignmentOutlined";
import DoctorIcon from "@material-ui/icons/LocalHospitalOutlined";
import ForumIcon from "@material-ui/icons/ForumOutlined";
import LogoutIcon from "@material-ui/icons/ExitToAppOutlined";
import MobileScreenShareIcon from "@material-ui/icons/MobileScreenShare";
import PaymentIcon from "@material-ui/icons/PaymentOutlined";
import SettingIcon from "@material-ui/icons/SettingsOutlined";
import EmrIcon from "@material-ui/icons/ViewQuiltOutlined";
import { Call } from "@material-ui/icons";
import Routes from "./routes";
import ProfileBox from "../components/layout/profile-box";
import { Link, useHistory, useLocation } from "react-router-dom";
import { Typography, makeStyles, Box, Grid } from "@material-ui/core";
import { SidebarContext } from "../context/sidebar";
import AuthService from "../utils/services/auth-service";
import isAuthorised from "../utils/role-util";
import AppConstants from "../utils/app-constants";
import { AppointmentsState, ChatsState, PaymentsState } from "../states";
import DataService from "../utils/services/data-service";
import Api from "../utils/api";
import { PersonOutline, Widgets, Close } from "@material-ui/icons";
import { useState } from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  fontColor: {
    color: theme.palette.primary.contrastText,
  },
  logo: {
    width: 100,
    height: 100,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  container: {
    marginTop: theme.spacing(8),
    marginLeft: theme.spacing(8),
  },
  "is-active": {
    backgroundColor: "rgba(0,0,0,0.2)",
    transition: "none",
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.2)",
    },
  },
}));

const ListItems = (props) => {
  const sidebarContext = SidebarContext.useContainer();
  const classes = useStyles();

  // const [open, setOpen] = React.useState(false);

  // --- for profile switching -----------
  const isPrimaryProfile = useSelector(state => state.switchProfile.isPrimaryProfile);

  // function handleClick() {
  //   setOpen(!open);
  // }

  const location = useLocation();
  let history = useHistory();
  const tenant = AuthService.getTenant();

  const handleLogOut = () => {
    sidebarContext.handleResetSidebarState();
    AuthService.logout().subscribe((resp) => {
      history.replace(Routes.LOGIN);
    });
  };

  const [appointments, setAppointments] = useRecoilState(AppointmentsState);
  const [chats, setChats] = useRecoilState(ChatsState);
  const [payments, setPayments] = useRecoilState(PaymentsState);
  const [ipdrequest, setipdrequest] = useState([]);
  // const [visitrequest, setVisitrequest] = useState(null);
  const user = AuthService.getUser();


  const [showActivityRegistery, setShowActivityRegistery] = useState(true);
  const handleButtonClick = () => {
    setShowActivityRegistery(true);
  };
  const handleclosebtn = () => {
    setShowActivityRegistery(false);
  }

  const getFilterObj = ({ date, doctorSlug, patientSlug, status }) => {
    return {
      date: date
        ? {
          type: "equals",
          filter: date,
          filterType: "date",
        }
        : null,
      doctorSlug: doctorSlug
        ? {
          filterType: "set",
          values: [doctorSlug],
        }
        : null,
      patientSlug: patientSlug
        ? {
          filterType: "set",
          values: [patientSlug],
        }
        : null,
      status: status ? { values: status, filterType: "set" } : null,
    };
  };

  const [filter, setFilter] = React.useState(
    getFilterObj({
      date: new Date().valueOf(),
      doctorSlug: user?.slug,
      status: null,
    })
  );

  const fetchAppointments = () => {
    // const filter = {
    //   date: {
    //     filter: new Date().valueOf(),
    //     filterType: "date",
    //     type: "equals",
    //   },
    // };
    DataService.fetchList("appointment/search", 0, 50, filter).subscribe(
      (resp) => {
        setAppointments(resp.content);
      }
    );
  };


  const fetchipdrequest = () => {

    Api.post('admit/staff-request/search', {
      "size": 20,
      "page": 0,
      "filterModel": {
        "status": {
          "type": "equals",
          "value": "Active"
        }
      },
      "sortModel": []
    }).subscribe((resp) => {
      setipdrequest(resp.data.data.content)
    })

  };



  const fetchChatSessions = () => {
    if (isAuthorised(AppConstants.ENTITY.CHAT)) {
      Api.get("/chat-session/my?size=100").subscribe((resp) => {
        const chatList = resp.data?.data?.content ?? [];
        setChats(chatList);
      });
    }
  };

  let defaultFilter = "";

  if (user.roleName === "DOCTOR" || user.roleName === "DOCTOR_ADMIN") {
    defaultFilter = {
      doctorSlug: { filterType: "set", values: [`${user.slug}`] },
    };
  } else {
    defaultFilter = { doctorSlug: { filterType: "set", values: [] } };
  }

  const fetchPayments = () => {
    const payload = {
      filterModel: defaultFilter,
      page: 0,
      size: 10,
      sortModel: [],
    };
    Api.post("/order/search-short", payload).subscribe((resp) => {

      setPayments(resp?.data?.data?.content);
    });
  };

  // const fetchvisitrequest = () => {
  //   const payload = {
  //     filterModel: defaultFilter,
  //     page: 0,
  //     size: 10,
  //     sortModel: [],
  //   };
  //   Api.post("/order/search-short", payload).subscribe((resp) => {

  //     setVisitrequest(resp?.data?.data?.content);
  //   });
  // };
  useEffect(fetchAppointments, []);
  useEffect(fetchChatSessions, []);
  useEffect(fetchPayments, []);
  useEffect(fetchipdrequest, []);
  // useEffect(fetchvisitrequest, []);

  const totalPendingAppointments = appointments.filter(
    (appointment) => appointment.status === "CHECKED_IN"
  ).length;

  // to update the sidebar chat counter when there is change in chat screen.
  const [totalPendingChats, setTotalPendingChats] = useState(0);
  useEffect(() => {
    setTotalPendingChats(chats.filter(
      (chat) => chat.hasResolvedMessages
    ).length)
  }, [chats])

  const totalPendingPayments = payments.filter(
    (payment) => payment.status === "PENDING_APPROVAL"
  ).length;


  const totalipdrequest = ipdrequest.length;




  const isActive = (value) =>
    location.pathname === value ? classes["is-active"] : "";

  const getUser = JSON.parse(localStorage.getItem("USER"));

  return (
    <div>
      {showActivityRegistery ? (
        // Close
        <Close
          style={{
            fontSize: "30px",
            textAlign: "center",
            position: "relative",
            left: "7px",
          }}
          onClick={() => {
            props.onClick();
            props.onClose();
            handleclosebtn();
            props.reducewidth();
          }}
        />
      ) : (
        // open
        <button
          style={{ background: "none", border: "none", color: "white" }}
          onClick={() => {
            handleButtonClick();
            props.onClick();
            props.shiftContent();
          }}
        >
          <div>
            {" "}
            <Widgets
              style={{
                fontSize: "22px",
                textAlign: "center",
                position: "relative",
                left: "7px",
              }}
            />
          </div>
        </button>
      )}
      {showActivityRegistery ? (
        <Box mb={4} className={classes.container}>
          <ListItem
            onClick={sidebarContext.handleItemClick}
            component={Link}
            to={Routes.DASHBOARD}
          >
            <Typography
              className={classes.fontColor}
              component="h1"
              variant="h4"
            >
              <strong> {tenant && tenant.name}</strong>
            </Typography>
          </ListItem>
          <Grid item xs={12} sm={12} md={12}>
            <ProfileBox user={user} />
            <div style={{ textAlign: "center" }}></div>
          </Grid>
        </Box>
      ) : null}

      {/*       {user?.roleName === AppConstants.ROLE.QUEUE  &&
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.DASHBOARD}
          className={isActive(Routes.DASHBOARD)}
          button
        >
          <ListItemIcon>
            <DashboardIcon className={classes.fontColor} />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        } */}
      {isAuthorised(AppConstants.ENTITY.APPOINTMENT) && (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.APPOINTMENT}
          className={isActive(Routes.APPOINTMENT)}
          button
        >
          <ListItemIcon>
            <AssignmentIcon className={classes.fontColor} />
          </ListItemIcon>
          {showActivityRegistery ? (
            <>
              {totalPendingAppointments ? (
                <Badge
                  badgeContent={totalPendingAppointments}
                  color="secondary"
                >
                  <ListItemText primary="Appointments" />
                </Badge>
              ) : (
                <ListItemText primary="Appointments" />
              )}
            </>
          ) : null}
        </ListItem>
      )}

      {isAuthorised(AppConstants.ENTITY.APPOINTMENT) && isPrimaryProfile && (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.UPDATE_APPOINTMENT}
          className={isActive(Routes.UPDATE_APPOINTMENT)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <AssignmentIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Update Appointments" />
            </>
          ) : (
            <ListItemIcon>
              <AssignmentIcon className={classes.fontColor} />
            </ListItemIcon>
          )}
        </ListItem>
      )}

      {/* #445b79 */}
      {/* <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.SEARCHFUNCTION}
          className={isActive(Routes.SEARCHFUNCTION)}
          button
        >
          <ListItemIcon>
            <Search className={classes.fontColor} />
          </ListItemIcon>
          <ListItemText primary="Search" />
        </ListItem> */}
      {isAuthorised(AppConstants.ENTITY.CHAT) && isPrimaryProfile && (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.CHAT}
          className={isActive(Routes.CHAT)}
          button
        >
          <ListItemIcon>
            <ChatIcon className={classes.fontColor} />
          </ListItemIcon>
          {showActivityRegistery ? (
            <>
              {totalPendingChats ? (
                <Badge
                  className="side-nav-badge"
                  badgeContent={totalPendingChats}
                  color="secondary"
                >
                  <ListItemText primary="Chats" />
                </Badge>
              ) : (
                <ListItemText primary="Chats" />
              )}
            </>
          ) : null}
        </ListItem>
      )}

      <ListItem
        onClick={sidebarContext.handleItemClick}
        component={Link}
        to={Routes.JOB}
        className={isActive(Routes.JOB)}
        button
      >
        <ListItemIcon>
          <WorkOutlineIcon className={classes.fontColor} />
        </ListItemIcon>
        <ListItemText primary="Jobs" />
      </ListItem>

      {isAuthorised(AppConstants.ENTITY.PATIENT) && (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.PATIENT}
          className={isActive(Routes.PATIENT)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <PeopleIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Patient" />
            </>
          ) : (
            <ListItemIcon>
              <PeopleIcon className={classes.fontColor} />
            </ListItemIcon>
          )}
        </ListItem>
      )}

      <ListItem
        onClick={sidebarContext.handleItemClick}
        component={Link}
        to={Routes.OTP}
        className={isActive(Routes.OTP)}
        button
      >
        {showActivityRegistery ? (
          <>
            <ListItemIcon>
              <MobileScreenShareIcon className={classes.fontColor} />
            </ListItemIcon>
            <ListItemText primary="OTP" />
          </>
        ) : (
          <ListItemIcon>
            <MobileScreenShareIcon className={classes.fontColor} />
          </ListItemIcon>
        )}
      </ListItem>

      {(getUser?.roleName === "ADMIN" ||
        getUser?.roleName === "DOCTOR_ADMIN") &&
      isPrimaryProfile ? (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.DOCTOR}
          className={isActive(Routes.DOCTOR)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <DoctorIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Staff" />
            </>
          ) : (
            <ListItemIcon>
              <DoctorIcon className={classes.fontColor} />
            </ListItemIcon>
          )}
        </ListItem>
      ) : null}

      {(getUser?.roleName === "DOCTOR" ||
        getUser?.roleName === "DOCTOR_ADMIN") &&
      isPrimaryProfile ? (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.REFERAL_DOCTOR}
          className={isActive(Routes.REFERAL_DOCTOR)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <DoctorIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Referral Doctors" />
            </>
          ) : (
            <ListItemIcon>
              <DoctorIcon className={classes.fontColor} />
            </ListItemIcon>
          )}
        </ListItem>
      ) : null}

      {isAuthorised(AppConstants.ENTITY.DOCTOR) && isPrimaryProfile && (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.EMR}
          className={isActive(Routes.EMR)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <EmrIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="EMR Templates" />
            </>
          ) : (
            <ListItemIcon>
              <EmrIcon className={classes.fontColor} />
            </ListItemIcon>
          )}
        </ListItem>
      )}

      {isPrimaryProfile && (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.MEDICATION}
          className={isActive(Routes.MEDICATION)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <EmrIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Medication" />
            </>
          ) : (
            <ListItemIcon>
              <EmrIcon className={classes.fontColor} />
            </ListItemIcon>
          )}
        </ListItem>
      )}

      {(getUser?.roleName === "DOCTOR" ||
        getUser?.roleName === "DOCTOR_ADMIN") &&
      isPrimaryProfile ? (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.CHAT_PACKAGE}
          className={isActive(Routes.CHAT_PACKAGE)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <ForumIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Chat Packages" />
            </>
          ) : (
            <ListItemIcon>
              <ForumIcon className={classes.fontColor} />
            </ListItemIcon>
          )}
        </ListItem>
      ) : null}

      {isAuthorised(AppConstants.ENTITY.PAYMENT) && isPrimaryProfile && (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.PAYMENT}
          className={isActive(Routes.PAYMENT)}
          button
        >
          <ListItemIcon>
            <PaymentIcon className={classes.fontColor} />
          </ListItemIcon>
          {showActivityRegistery ? (
            <>
              {totalPendingPayments ? (
                <Badge
                  className="side-nav-badge"
                  badgeContent={totalPendingPayments}
                  color="secondary"
                >
                  <ListItemText primary="Payments" />
                </Badge>
              ) : (
                <ListItemText primary="Payments" />
              )}
            </>
          ) : null}
        </ListItem>
      )}

      {isPrimaryProfile && <ListItem
        onClick={sidebarContext.handleItemClick}
        component={Link}
        to={Routes.PROFILE}
        className={isActive(Routes.PROFILE)}
        button
      >
        {showActivityRegistery ? (
          <>
            <ListItemIcon>
              <PersonOutline className={classes.fontColor} />
            </ListItemIcon>
            <ListItemText primary="Profile" />
          </>
        ) : (
          <ListItemIcon>
            <PersonOutline className={classes.fontColor} />
          </ListItemIcon>
        )}
      </ListItem>}

      {(getUser?.roleName === "ADMIN" || getUser?.roleName === "DOCTOR_ADMIN") && isPrimaryProfile ? (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.SETTINGS}
          className={isActive(Routes.SETTINGS)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <SettingIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </>
          ) : (
            <ListItemIcon>
              <SettingIcon className={classes.fontColor} />
            </ListItemIcon>
          )}
        </ListItem>
      ) : null}

      {(getUser.roleName === "DOCTOR_ADMIN" ||
      getUser.roleName === "DOCTOR" ||
      getUser.roleName === "NURSE") && isPrimaryProfile ? (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.IPDREQUEST}
          className={isActive(Routes.IPDREQUEST)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <Call className={classes.fontColor} />
              </ListItemIcon>
              {totalipdrequest ? (
                <Badge badgeContent={totalipdrequest} color="secondary">
                  <ListItemText primary="IPD Request" />
                </Badge>
              ) : (
                <ListItemText primary="IPD Request" />
              )}
            </>
          ) : (
            <>
              <ListItemIcon>
                <Call className={classes.fontColor} />
              </ListItemIcon>
              {totalipdrequest ? (
                <Badge badgeContent={totalipdrequest} color="secondary">
                  <ListItemText primary="IPD Request" />
                </Badge>
              ) : (
                ""
              )}
            </>
          )}
        </ListItem>
      ) : null}

      {/* <ListItem button onClick={handleClick} className={classes.fontColor}>
        <ListItemIcon className={classes.fontColor}>
          <SettingIcon />
        </ListItemIcon>
        <ListItemText primary="SideSettings" />
        {open ? <IconExpandLess /> : <IconExpandMore />}
      </ListItem> */}
      {/* <Collapse in={open} timeout="auto" unmountOnExit>
        <Divider />
        <List>
          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Appointment_type}
              className={isActive(Routes.Appointment_type)}
              button
            >
              <ListItemIcon>
                <AssignmentIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Appointment type" />
            </ListItem>
          )}
          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Quelists}
              className={isActive(Routes.Quelists)}
              button
            >
              <ListItemIcon>
                <EmrIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Que lists" />
            </ListItem>
          )}
          {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Specialists}
              className={isActive(Routes.Specialists)}
              button
            >
              <ListItemIcon>
                <DoctorIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Specialists" />
            </ListItem>
          )}
          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Payment_option}
              className={isActive(Routes.Payment_option)}
              button
            >
              <ListItemIcon>
                <PaymentIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Payment Option" />
            </ListItem>
          )}
          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Customerstatus}
              className={isActive(Routes.Customerstatus)}
              button
            >
              <ListItemIcon>
                <EmrIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Customer Status" />
            </ListItem>
          )}

          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Social_media}
              className={isActive(Routes.Social_media)}
              button
            >
              <ListItemIcon>
                <EmrIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Social Media" />
            </ListItem>
          )}
          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Template}
              className={isActive(Routes.Template)}
              button
            >
              <ListItemIcon>
                <EmrIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Email Templates" />
            </ListItem>
          )}
          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Website}
              className={isActive(Routes.Website)}
              button
            >
              <ListItemIcon>
                <AssignmentIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Website Setting" />
            </ListItem>
          )}

          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Aboutus}
              className={isActive(Routes.Aboutus)}
              button
            >
              <ListItemIcon>
                <EmrIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="About us" />
            </ListItem>
          )}
          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Banner}
              className={isActive(Routes.Banner)}
              button
            >
              <ListItemIcon>
                <EmrIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Banners" />
            </ListItem>
          )}
          {isAuthorised(AppConstants.ROLE.DOCTOR) && (
            <ListItem
              onClick={sidebarContext.handleItemClick}
              component={Link}
              to={Routes.Department}
              className={isActive(Routes.Department)}
              button
            >
              <ListItemIcon>
                <DashboardIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Department Details" />
            </ListItem>
          )}
        </List>
      </Collapse> */}

      {(getUser?.roleName === "ADMIN" || getUser?.roleName === "DOCTOR_ADMIN") && isPrimaryProfile ? (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.ActivePatient}
          className={isActive(Routes.ActivePatient)}
          button
        >
          {showActivityRegistery ? (
            <>
              <ListItemIcon>
                <SettingIcon className={classes.fontColor} />
              </ListItemIcon>
              <ListItemText primary="Active Patient Status" />
            </>
          ) : (
            <ListItemIcon>
              <SettingIcon className={classes.fontColor} />
            </ListItemIcon>
          )}
        </ListItem>
      ) : null}
      {/* {getUser.roleName === "ADMIN" || getUser.roleName === "DOCTOR_ADMIN" ? (
        <ListItem
          onClick={sidebarContext.handleItemClick}
          component={Link}
          to={Routes.VISITREQUEST}
          className={isActive(Routes.VISITREQUEST)}
          button
        >
          <ListItemIcon>
            <SettingIcon className={classes.fontColor} />
          </ListItemIcon>
          {!visitrequest ? (
            <Badge
              className="side-nav-badge"
              badgeContent={"78"}
              color="secondary"
            >
               <ListItemText primary="Visit Request" />
            </Badge>):null}
         
        </ListItem>
      ) : null} */}

      {isPrimaryProfile && <ListItem
        onClick={sidebarContext.handleItemClick}
        component={Link}
        to={Routes.ACTIVITYREGISTRY}
        className={isActive(Routes.ACTIVITYREGISTRY)}
        button
      >
        {showActivityRegistery ? (
          <>
            <ListItemIcon>
              <AssignmentIcon className={classes.fontColor} />
            </ListItemIcon>
            <ListItemText primary="Activity Registry" />
          </>
        ) : (
          <ListItemIcon>
            <AssignmentIcon className={classes.fontColor} />
          </ListItemIcon>
        )}
      </ListItem>}

      {isPrimaryProfile && <ListItem onClick={handleLogOut} button>
        {showActivityRegistery ? (
          <>
            <ListItemIcon>
              <LogoutIcon className={classes.fontColor} />
            </ListItemIcon>
            <ListItemText primary="Log Out" />
          </>
        ) : (
          <ListItemIcon>
            <LogoutIcon className={classes.fontColor} />
          </ListItemIcon>
        )}
      </ListItem>}
    </div>
  );
};
export default ListItems;