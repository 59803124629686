import React, {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
} from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Button,
  Typography,
  FormControlLabel,
  Switch,
  AppBar,
  Toolbar,
  IconButton,
  Hidden,
  Checkbox,
  CircularProgress,
  TextField,
  Dialog,
  useMediaQuery,
} from "@material-ui/core";

import { withStyles, useTheme } from "@material-ui/core/styles";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { Form, Scope } from "informed";
import useGlobalStyles from "../../utils/global-style";
import clsx from "clsx";
import {
  TextInput,
  SelectInput
} from "../../components/sixsprint-react-ui";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import MenuIcon from "@material-ui/icons/Menu";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import PatientChat from "./patient-chat";
import LoopIcon from "@material-ui/icons/Loop";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  useQuery,
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_ERROR_NOTIFICATION,
  SHOW_INFO_NOTIFICATION,
  getFormattedDate,
} from "../../utils/app-util";
import Api from "../../utils/api";
import Api2 from "../../utils/api2";
import isAuthorised, { isCurrentRole } from "../../utils/role-util";
import AppConstants from "../../utils/app-constants";
import AuthService from "../../utils/services/auth-service";
import VitalsDialog from "./vital-dialog";
import DataService from "../../utils/services/data-service";
import { SidebarContext } from "../../context/sidebar";
import Loader from "../../components/misc/loader";
import TemplateDialog from "./template-dialog";
import Medication from "../../components/layout/medication";
import Routes from "../../routes/routes";
import moment from "moment";
import Autocomplete from "../../components/misc/autocomplete";
import ReferalDoctor from "./referal-doc";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { format } from "date-fns";
import QRCode from "qrcode.react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Prescriptionchat from "./prescriptionchat";
import CloseIcon from "@material-ui/icons/Close";
import dayjs from "dayjs";
import "dayjs/locale/en";

// v2 imports

import AdmitPatient from "./admitPatient";
import { TextAreaCustom } from "./TextAreaCustom";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useSkipFirstRenderEffect from "../../hooks/useSkipFirstRenderEffect";
import { UploadAPI } from "../../apis/UploadAPI";

const CustomKeyboardDatePicker = withStyles({
  root: {
    "& .MuiInputBase-input": {
      pointerEvents: "none", // Disable text input
    },
  },
})(KeyboardDatePicker);

const useStyles = makeStyles((theme) => ({
  containerBox: {
    flexGrow: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
    margin: theme.spacing(2, 0),
    padding: theme.spacing(8, 12),
  },
  presBox: {
    height: "20vh",
    overflowY: "scroll",
  },
  title: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "0.7rem",
  },
  drawer: {
    width: "100%",
    padding: theme.spacing(4),
    backgroundColor: theme.palette.bg,
    marginTop: "-10px",
  },
  my: {
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  dialogBackground: {
    backgroundColor: theme.palette.bg,
    width: "90%",
    height: "97%",
    margin: "auto",
  },
  reportClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  reportCard: {
    margin: "2px",
  },
  multipleImageContainer: {
    width: "89%",
    maxHeight: "70%",
    margin: "0px auto",
  },
  reportMultipleImage: {
    maxHeight: "60%",
    width: "60%",
    margin: "0px auto",
    borderRadius: "15px",
  },
  reportPdf: {
    width: "80%",
    height: "500px",
    margin: "0px 70px",
  },
  noReportImage: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30%",
  },
  noReport: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  closeContainer: {
    display: "flex",
  },
  closeHeading: {
    fontSize: "12px",
  },
  reportTitle: {
    alignSelf: "center",
    marginLeft: "50%",
    transform: "translate(-75%)",
  },
  reportImage: {
    height: "94%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },

  grow: {
    flexGrow: 1,
  },
  appbar: {
    background: theme.palette.bg,
    paddingTop: "1rem",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.black,
    fontWeight: "bold",
    opacity: "0.9",
  },
  input: {
    display: "none",
  },
  prescriptionSection: {
    marginBottom: "2rem",
  },
  container: {
    padding: theme.spacing(10),
  },
  textField: {
    width: "80%",
  },
  newtextField: {
    border: "none",
    "& .PrivateNotchedOutline-root-878": {
      borderWidth: "0px",
    },
  },

  printBtnContainer: {
    width: "100%",
    padding: theme.spacing(6),
  },

  loaderStyle: {
    color: "white",
  },
  dialogBackground: {
    backgroundColor: theme.palette.bg,
    width: "90%",
    height: "90%",
    margin: "auto",
  },
  containerBox: {
    width: "100%",
    flexGrow: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 5px 20px #00000008",
    borderRadius: 14,
    margin: theme.spacing(5, 0),
    padding: theme.spacing(8, 12),
  },
  presBox: {
    height: "20vh",
    overflowY: "scroll",
  },
  reportTitle: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: "0.7rem",
  },
  reportClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  reportImage: {
    height: "94%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },
  multipleImageContainer: {
    width: "89%",
    maxHeight: "70%",
    margin: "0px auto",
  },
  reportMultipleImage: {
    maxHeight: "60%",
    width: "60%",
    margin: "0px auto",
    borderRadius: "15px",
  },
  reportPdf: {
    width: "80%",
    height: "500px",
    margin: "0px 70px",
  },
  noReportImage: {
    display: "flex",
    justifyContent: "center",
    paddingTop: "30%",
  },
  noReports: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  reportContainer: {
    display: "flex",
  },
  closeTitle: {
    fontSze: "12px",
  },
  reportHeading: {
    alignSelf: "center",
    marginLeft: "50%",
    transform: "translate(-75%)",
  },
  prescriptionTitle: {
    fontSize: "0.8rem",
    fontWeight: "700",
    opacity: "0.7",
    lineHeight: "normal",
    marginBottom: "10px",
  },
  diagramContainer: {
    display: "flex",
  },
  diagramClose: {
    height: "10px",
    width: "20px",
    display: "flex",
    margin: "5px",
    borderRadius: "20px",
    padding: "10px 15px",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "lightgray",
  },
  closeTitle: {
    fontSze: "12px",
  },
  diagramImage: {
    height: "90%",
    width: "90%",
    margin: "auto",
    borderRadius: "15px",
  },

  "prescription-details": {
    padding: "40px 30px",
    marginBottom: "2.5rem",

    "&__column": {
      [theme.breakpoints.down("sm")]: {
        "&:nth-last-child(n+3)": { marginBottom: "20px" },
      },

      [theme.breakpoints.up("md")]: {
        "&:nth-last-child(n+4)": { marginBottom: "20px" },
      },
    },

    "&__title": {
      color: "#1A1A1A",
      font: "bold 16px/18px Comfortaa",
      marginBottom: "8px",
    },

    "&__sub-title": {
      color: "#6D6D6D",
      font: "lighter 16px/18px Comfortaa",
      paddingBottom: "12px",
      borderBottom: "1px solid #E6E6E6",
    },
  },

  "prescription-qr": {
    textAlign: "center",
    padding: "20px 30px 30px",
    marginBottom: "2.5rem",

    [theme.breakpoints.down("sm")]: {
      padding: "20px",
    },

    "&__img": {
      maxWidth: "100%",
      margin: "-15px",
    },
  },
  vitals: {
    "&__column": {
      [theme.breakpoints.down("sm")]: {
        "&:nth-last-child(n+3)": { marginBottom: "30px" },
      },

      [theme.breakpoints.up("md")]: {
        "&:nth-last-child(n+4)": { marginBottom: "30px" },
      },
    },
  },
  otherEmailField: {
    width: "400px",
  },
  vitalSign: {
    maxWidth: "300px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "230px",
    },

    "& h6": {
      width: "40px",
    },

    "& .MuiPaper-root": {
      flex: 1,
    },
  },
  restrictionControlBox: {
    display: "flex",
    alignItems: "center",
  },
  clrBtnBox: {
    padding: 20,
  },

  imageDiv: {
    maxWidth: "100px",
    width: "100%",
    height: "100px",
    maxHeight: "100%",
    border: "1px solid black",
    borderRadius: "50%",
  },
}));

const Prescription = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const newId = searchParams.get("id");

  const [patientSlug1, setPatientslug1] = useState("");
  const [profiledata, setProfileData] = useState({});
  const [showPatientSummary, setshowPatientSummary] = useState(true);

  const theme = useTheme();
  const isMobiles = useMediaQuery(theme.breakpoints.down("sm"));
  const isMobile = useMediaQuery("(max-width: 600px)");
  const isdesktop = useMediaQuery("(max-width: 1400px)");

  const history = useHistory();
  const isPrimaryProfile = useSelector(state => state?.switchProfile?.isPrimaryProfile);
  const primaryProfileInfo = useSelector(state => state?.switchProfile?.primaryProfileInfo);
  const switchedProfileInfo = useSelector(state => state.switchProfile?.switchedProfileInfo);

  const handleformclick = () => {
    setisformopen(!isformopen);
    fetchPrescription();
  };

  const handlePatientSummaryClick = () => {
    setPatientSummaryOpen(!isPatientSummaryOpen);
  };

  // chat btn
  const handlechatbtnclick = () => {
    setischatopen(!ischatopen);
  };
  const handleqrClick = () => {
    setisqropen(!isqropen);
  };
  useEffect(() => {
    isMobile ? setisqropen(false) : setisqropen(true);
    isMobile ? setPatientSummaryOpen(false) : setPatientSummaryOpen(true);
    isMobile ? setisformopen(false) : setisformopen(true);
    isMobile ? setischatopen(false) : setischatopen(true);
  }, [isMobile]);

  const [isqropen, setisqropen] = useState(isMobile ? false : true);
  const [isformopen, setisformopen] = useState(isMobile ? false : true);
  const [isPatientSummaryOpen, setPatientSummaryOpen] = useState(true);
  const [ischatopen, setischatopen] = useState(false);
  const [loadChat, setLoadChat] = useState(false);

  dayjs.locale("en");
  const globalClass = useGlobalStyles();
  const classes = useStyles();
  const presFileRef = useRef(null);
  const [appointment, setAppointment] = useState(null);

  const [prescription, setPrescription] = useState(null);

  const [prevPrescrptn, setPrevPrescrptn] = useState(null);

  const [medications, setMedications] = useState([]);

  const [vitalEditMode, setVitalEditMode] = useState(false);
  const [comEditMode, setComEditMode] = useState(false);
  const [templateMode, setTemplateMode] = useState(false);
  const [templateInfo, setTemplateInfo] = useState(null);
  const [isImageLoading, setImageLoading] = useState(false);
  const [imageLabel, setImageLabel] = useState(null);
  const [images, setImages] = useState([]);
  const [imagesPrecription, setImagePrecription] = useState([]);
  const [diagrams, setDiagrams] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);

  const [emrTemplates, setEmrTemplates] = useState([]);

  const [currentEmrTemp, setCurrentEmrTemp] = useState(null);
  const [isFollowUpRqrdChecked, setIsFollowUpRqrdChecked] = useState(false);

  const [appointmentTypes, setAppointmentTypes] = useState(false);
  const [appoinmentTypeId, setAppointmentId] = useState(null);
  const [isSlotLoading, setIsSlotLoading] = useState(false);
  const [selectedTime, setSelectedTime] = useState(null);
  const [slots, setSlots] = useState(null);

  const [isNewAppointment, setIsNewAppointment] = useState(false);
  const [vitalType, setVitalType] = useState(null);
  const [printLetterHeadChecked, setPrintLetterHead] = useState(false);
  const [isTestNeeded, setIsTestNeeded] = useState(false);
  const [reports, setReports] = useState([]);
  const [openReport, setOpenReport] = useState(false);
  const [selectedReport, setSelectedReport] = useState([]);
  const [selectSingleImage, setSelectSingleImage] = useState(null);

  const [reportType, setReportType] = useState(null);
  const [medList, setMedList] = useState([]);
  const [open, setOpen] = useState(false);

  const formApi = useRef();
  const patientFormApi = useRef();
  const sidebarContext = SidebarContext.useContainer();
  const gender = ["MALE", "FEMALE", "OTHERS"];
  const user = AuthService.getUser();
  const [submitButton, setSubmitButton] = useState(null);
  const [followUpDurationNumber, setFollowUpDurationNumber] = useState(null);
  const [followUpDurationInterval, setFollowUpDurationInterval] =
    useState("Weeks");

  const [selectedPrevPres, setSelectedPrevPres] = useState([]);

  const [prov, setProv] = useState(null);
  const [chiefComplaints, setChiefComplaints] = useState(null);
  const [hopi, setHopi] = useState(null);
  const [remarks, setRemarks] = useState(null);
  const [examination, setExamination] = useState(null);
  const [surgicalPlan, setSurgicalPlan] = useState(null);
  const [restrictions, setRestrictions] = useState(null);
  const [investigation, setInvestigation] = useState(null);
  const [notes, setNotes] = useState(null);
  const [referringDoc, setReferringDoc] = useState(null);

  const [referringDocNo, setReferringDocNo] = useState(null);
  const [referringDocEmail, setReferringDocEmail] = useState(null);
  const [referringOtherEmail, setReferringOtherEmail] = useState(null);
  // const [availableTemplatesLabel, setAvailableTemplatesLabel] = useState([])
  const [prevPrescriptionMedications, setPrevPrescriptionMedications] =
    useState([]);
  const [templateMedications, setTemplateMedications] = useState([]);
  const [templateSelected, setTemplateSelected] = useState(false);
  const [selectedEmrOption, setSelectedEmrOption] = useState({});

  // const [selectedPrescriptionOption, setSelectedPrescriptionOption] = useState({});
  const [selectedPrescriptionOption, setSelectedPrescriptionOption] =
    useState(null);

  const [selectedMedicationOption, setSelectedMedicationOption] = useState({});
  const [selectedMedicationPrev, setSelectedMedicationPrev] = useState({});
  const [selectedDiagram, setSelectedDiagram] = useState(null);
  const [viewDiagram, setViewDiagram] = useState(false);
  const [tempRestrictionOption, setTempRestrictionOption] = useState(null);
  const [individualTemplateOption, setIndividualTemplateOption] = useState({});
  const [individualPrevPrisc, setIndividualPrevPrisc] = useState({});
  const [investigationOption, setinvestigationOption] = useState(null);

  const [selectedCoMorbPrev, setSelectedCoMorbPrev] = useState({});

  const [selectedVitalsPrev, setSelectedVitalsPrev] = useState({});

  const [selectedOption, setSelectedOption] = useState({});

  const [allAppointDetails, setAllAppoinment] = useState(null);

  const DocSlug = JSON.parse(localStorage.getItem("USER"));

  const [endDateTime, setEndDateTime] = useState();
  const [startDateTime, setStartDateTime] = useState(null);
  const [minutes, setMinutes] = useState(null);
  const [slotId, setSlotId] = useState(null);
  const [fees, setFees] = useState(null);
  const [emrTemplateID, setEmrTemplateID] = useState("");

  const token = AuthService.getToken();

  const [uploadedDiagramid, setUploadedDiagramid] = useState([]);
  const [uploadedDiagram, setUploadedDiagram] = useState([]);

  const [toBeCalled, setToBeCalled] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [checkedReferal, setcheckedReferal] = useState(false);
  const [doctorSlug, setDoctorSlug] = useState(null);

  //const [admitType, setAdmitType] = useState(false);

  const admitType = useRef(false);

  const [isDetailedConsultRequired, setIsDetailedConsultRequired] =
    useState(false);
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // to redirect back to appointment screen when switching profile
  // avoiding redirect on initial render
  useSkipFirstRenderEffect(() => {
    history.push(Routes.APPOINTMENT);
  }, [isPrimaryProfile]);

  const [specialistData, setSepcilistData] = useState(null);
  const [selectedDefaults, setSelectedDefaults] = useState(null);
  const [selectedEmrType, setSelectedEmr] = useState();

  const [selectedEmrOptions, setSelectedEmrOptions] = useState(null);

  const [firstLoad, setFirstLoad] = useState(false);

  const [typEmrTemplate, setTypeEmrTemplate] = useState(null);
  const [emrObject, setEmrObject] = useState({});
  const [patientDOB, setPatientDOB] = useState(null);

  const [textData, setTextData] = useState([]);


  const handleTextChange = (event, field) => {
    const newValue = event.target.value;

    setEmrObject((prevFormData) => ({
      ...prevFormData,
      [field]: newValue,
    }));
  };

  const handleSave = () => {
    if (selectedEmrType) {
      setSubmitButton(0);
    } else {
      SHOW_ERROR_NOTIFICATION("EMR type is required");
      setSubmitButton(null);
      return;
    }
  };

  const provRef = useRef(prov);
  const chiefComplaintsRef = useRef(chiefComplaints);
  const hopiRef = useRef(hopi);
  const remarksRef = useRef(remarks);
  const examinationRef = useRef(examination);
  const surgicalPlanRef = useRef(surgicalPlan);
  const restrictionsRef = useRef(restrictions);
  const investigationRef = useRef(investigation);
  const notesRef = useRef(notes);
  const referringDocRef = useRef(referringDoc);
  const referringDocNoRef = useRef(referringDocNo);
  const referringDocEmailRef = useRef(referringDocEmail);
  const referringOtherEmailRef = useRef(referringOtherEmail);
  const medicationsRef = useRef(medications);
  const imagesRef = useRef(imagesPrecription);

  const uploadedDiagramref = useRef(uploadedDiagram);
  const isFollowUpRqrdCheckedRef = useRef(isFollowUpRqrdChecked);
  const followUpDurationNumberRef = useRef(followUpDurationNumber);
  const followUpDurationIntervalRef = useRef(followUpDurationInterval);
  const printLetterHeadCheckedRef = useRef(printLetterHeadChecked);

  provRef.current = prov;
  chiefComplaintsRef.current = chiefComplaints;
  hopiRef.current = hopi;
  remarksRef.current = remarks;
  examinationRef.current = examination;
  surgicalPlanRef.current = surgicalPlan;
  restrictionsRef.current = restrictions;
  investigationRef.current = investigation;
  notesRef.current = notes;
  referringDocRef.current = referringDoc;
  referringDocNoRef.current = referringDocNo;
  referringDocEmailRef.current = referringDocEmail;
  referringOtherEmailRef.current = referringOtherEmail;
  medicationsRef.current = medications;
  imagesRef.current = imagesPrecription;
  uploadedDiagramref.current = uploadedDiagram;
  isFollowUpRqrdCheckedRef.current = isFollowUpRqrdChecked;
  followUpDurationNumberRef.current = followUpDurationNumber;
  printLetterHeadCheckedRef.current = printLetterHeadChecked;
  followUpDurationIntervalRef.current = followUpDurationInterval;

  useEffect(() => {
    if ((slug == null || slug == "") && newId !== null) {
      admitType.current = true;
      //setAdmitType(true);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setRefresh(!refresh);
    }, 10000);
  }, [refresh]);

  const todaysappointmentdata = async (afterResult) => {
    const filter = {
      date: {
        filter: new Date().valueOf(),
        filterType: "date",
        type: "equals",
      },
      doctorSlug: {
        filterType: "set",
        values: [DocSlug?.slug],
      },
    };
    await DataService.fetchList("appointment/search", 0, 100, filter).subscribe(
      (resp) => {
        const checkedIn = [];
        resp.content.forEach((pat) => {
          if (pat.status === "CHECKED_IN") {
            checkedIn.push(pat);
          }
        });
        const patientToBeCalled = [];
        checkedIn.forEach((pat) => {
          if (!pat.called) {
            patientToBeCalled.push(pat);
          }
        });
        if (afterResult) {
          afterResult(patientToBeCalled[0]?.slug);
        }
      }
    );
  };

  useEffect(() => {
    if (admitType.current) {
      return;
    }
    getAppointment();
  }, []);

  const [getStatus, setGetStatus] = useState(false);

  const getAppointment = async () => {
    const req = {
      filterModel: {
        id: query.get("slug"),
      },
    };
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}get_appointment`,
        data: req,
        headers: { "Content-Type": "application/json" },
      });

      if (response) {
        setAllAppoinment(response?.data?.data[0]);
        setGetStatus(true);
        setUploadedDiagram(response?.data?.data[0]?.prescription?.diagrams);
        setImagePrecription(response?.data?.data[0]?.prescription?.images);
        setEmrObject(response?.data?.data[0]?.prescription);
        setReferringOtherEmail(
          response?.data?.data[0]?.prescription?.otherEmails
        );
        setEmrTemplateID(
          response?.data?.data[0]?.prescription?.emrTemplateTypeId
        );
        setDoctorSlug(response?.data?.data[0]?.doctorSlug);
      }
    } catch {}
  };

  useEffect(() => {
    const filteredData = specialistData?.filter(
      (item) => item._id === emrTemplateID
    );
    if (filteredData?.length > 0) {
      setSelectedEmrOptions(filteredData[0]);
      // setSelectedEmr(filteredData[0]);
    }
  }, [emrTemplateID, specialistData]);

  useEffect(
    () =>
      selectedPrevPres?.forEach((press) => {
        press.label = `${
          appointment?.doctor?.name ? appointment?.doctor?.name : ""
        } ${"(" + moment(press?.date).format("DD MM YYYY") + ")"} ${
          press?.appoinmentType || ""
        }`;
      }),
    [selectedPrevPres]
  );

  const [saved, setSaved] = useState(false);
  const appointmentRef = useRef(appointment);
  const profiledataRef = useRef(profiledata);

  useEffect(() => {
    appointmentRef.current = appointment;
  }, [appointment]);
  useEffect(() => {
    profiledataRef.current = profiledata;
  }, [profiledata]);

  let query = useQuery();
  const slug = query.get("slug");

  let { vitals: configVitals } = AuthService.getConfig() || {};
  configVitals = configVitals || [];

  let { coMorbidities: configCoMorbidities } = AuthService.getConfig() || {};
  configCoMorbidities = configCoMorbidities || [];

  const [vitals, setVitals] = useState([]);
  const [coMorbidities, setCoMorbidities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCoMorbidities, setSelectedCoMorbidities] = useState([]);
  const [selectedVitals, setSelectedVitals] = useState([]);

  const selectedCoMorbiditiesRef = useRef(selectedCoMorbidities);
  const selectedVitalsRef = useRef(selectedVitals);

  useEffect(() => {
    setSelectedCoMorbidities(() => {
      const newCoMorbidities = coMorbidities.filter(({ checked }) => checked);
      return [...newCoMorbidities];
    });
    setSelectedVitals(() => {
      const newVitals = vitals.filter(({ checked }) => checked);
      return [...newVitals];
    });
  }, [coMorbidities, vitals]);

  useEffect(() => {
    selectedCoMorbiditiesRef.current = selectedCoMorbidities;
    selectedVitalsRef.current = selectedVitals;
  }, [selectedVitals, selectedCoMorbidities]);

  const printPrescriptionPdf = () => {
    const print_pdf = `
              <!DOCTYPE html>
              <html lang="en">
                <head>
                  <meta charset="UTF-8" />
                  <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                  <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                  <title>Prescription</title>
                  <style>
                    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

                    * {
                      margin: 0;
                      padding: 0;
                      -webkit-box-sizing: border-box;
                      box-sizing: border-box;
                    }

                    body {
                      font-family: 'Open Sans', sans-serif;
                    }

                    .patient {
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-orient: vertical;
                      -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                      flex-direction: column;
                      gap: 12px;
                    }

                    .patient__info {
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      gap: 24px;
                    }

                    .patient__name,
                    .patient__email,
                    .patient__address {
                      width: 100%;
                    }

                    .patient__dob,
                    .patient__gender,
                    .patient__contact,
                    .patient__id,
                    .patient__last-visit {
                      min-width: 9rem;
                      max-width: 9rem;
                    }

                    .patient__name > label,
                    .patient__email > label,
                    .patient__dob > label,
                    .patient__gender > label,
                    .patient__contact > label,
                    .patient__id > label,
                    .patient__address > label,
                    .patient__last-visit > label {
                      font-size: 10px;
                      font-weight: 500;
                      color: #5c5c5c;
                    }

                    .patient__name > h3,
                    .patient__email > h3,
                    .patient__dob > h3,
                    .patient__gender > h3,
                    .patient__contact > h3,
                    .patient__id > h3,
                    .patient__address > h3,
                    .patient__last-visit > h3 {
                      font-size: 14px;
                      font-weight: 600;
                      color: #1f1f1f;
                    }

                    .emr {
                      margin-top: 24px;
                      display: -webkit-box;
                      display: -ms-flexbox;
                      display: flex;
                      -webkit-box-orient: vertical;
                      -webkit-box-direction: normal;
                      -ms-flex-direction: column;
                      flex-direction: column;
                      gap: 1rem;
                    }

                    .prov label,
                    .chief-complaints label,
                    .hopi label,
                    .vitals label,
                    .co-morbidities label,
                    .examinations label,
                    .medications label,
                    .surgical-plan label,
                    .restrictions-and-instructions label,
                    .investigation label,
                    .follow-up label {
                      font-size: 16px;
                      font-weight: 600;
                      color: #5c5c5c;
                    }

                    .prov ul,
                    .chief-complaints ul,
                    .hopi ul,
                    .vitals ul,
                    .co-morbidities ul,
                    .examinations ul,
                    .medications ul,
                    .surgical-plan ul,
                    .restrictions-and-instructions ul,
                    .investigation ul,
                    .follow-up ul {
                      padding-left: 1.5rem;
                      margin-top: 6px;
                    }

                    .prov ul li,
                    .chief-complaints ul li,
                    .hopi ul li,
                    .vitals ul li,
                    .co-morbidities ul li,
                    .examinations ul li,
                    .medications ul li,
                    .surgical-plan ul li,
                    .restrictions-and-instructions ul li,
                    .investigation ul li,
                    .follow-up ul li {
                      font-size: 14px;
                      font-weight: 500;
                      color: #1f1f1f;
                    }

                    .vitals__details {
                      display: -ms-grid;
                      display: grid;
                      -ms-grid-columns: (1fr) [3];
                      grid-template-columns: repeat(3, 1fr);
                      grid-column-gap: 12px;
                      grid-row-gap: 6px;
                    }

                    .vitals__details > li span {
                      margin-left: 6px;
                    }

                    table {
                      border-collapse: collapse;
                      width: 100%;
                      text-align: right;
                      margin-top: 6px;
                    }

                    table th {
                      padding: 9px 6px;
                      background-color: #edf3fc;
                      color: #5c5c5c;
                      border-bottom: 1px solid #b8b8b8;
                    }

                    table th,
                    table td {
                      font-size: 14px;
                      font-weight: 600;
                    }

                    table th:nth-child(2),
                    table th:nth-child(3),
                    table th:nth-child(4),
                    table td:nth-child(2),
                    table td:nth-child(3),
                    table td:nth-child(4) {
                      width: 6rem;
                    }

                    table td {
                      padding: 6px;
                      color: #1f1f1f;
                      border-bottom: 1px solid #ebebeb;
                    }

                    table th:first-child,
                    table td:first-child {
                      text-align: left;
                    }
                    @page{
                      margin-top: ${
                        appointment?.prescription?.headerSpacing ?? 5
                      }mm
                    }
                  </style>
                </head>
                <body>
                  <div class="page">
                    <div class="letterhead">
                      <!-- Letterhead height have to be set user from setting -->
                    </div>
                    <div class="page__content">
                      <div class="patient">
                        <div class="patient__info">
                          <div class="patient__name">
                            <label for="name">Name</label>
                             <h3>${appointment?.patient?.name}</h3>
                          </div>
                          <div class="patient__dob">
                            <label for="date-of-birth">Date of Birth</label>
                            <h3>${
                              appointment?.patient?.dob
                                ? moment(appointment?.patient?.dob).format(
                                    "MMM Do YY"
                                  )
                                : "NA"
                            }</h3>
                          </div>
                          <div class="patient__gender">
                            <label for="gender">Gender</label>
                            <h3>${appointment?.patient?.gender ?? "NA"}</h3>
                          </div>
                        </div>
                        <div class="patient__info">
                          <div class="patient__email">
                            <label for="email">Email</label>
                            <h3>${appointment?.patient?.email ?? "NA"}</h3>
                          </div>
                          <div class="patient__contact">
                            <label for="mobile-number">Mobile Number</label>
                            <h3>${appointment?.patient?.mobileNumber}</h3>
                          </div>
                          <div class="patient__id">
                            <label for="uhid">UHID</label>
                            <h3>${appointment?.patient?.uhid ?? "NA"}</h3>
                          </div>
                        </div>
                        <div class="patient__info">
                          <div class="patient__address">
                            <label for="address">Address</label>
                            <h3>${appointment?.patient?.address ?? "NA"}</h3>
                          </div>
                          <div class="patient__last-visit">
                            <label for="last-visit">Last Visit</label>
                            <h3>${
                              appointment?.patient?.lastVisit
                                ? moment(
                                    appointment?.patient?.lastVisit
                                  ).format("MMM Do YY")
                                : "NA"
                            }</h3>
                          </div>
                        </div>
                      </div>

                      <div class="emr">
                        <div class="prov">
                          <label for="prov">Prov</label>
                          <ul>
                            <li>${appointment?.prescription?.prov ?? "NA"}</li>
                          </ul>
                        </div>
                        <div class="chief-complaints">
                          <label for="chief-complaints">Chief Complaints</label>
                          <ul>
                            <li>${
                              appointment?.prescription?.chiefComplaints ?? "NA"
                            }</li>
                          </ul>
                        </div>
                        <div class="hopi">
                          <label for="hopi">HOPI</label>
                          <ul>
                            <li>${appointment?.prescription?.hopi ?? "NA"}</li>
                          </ul>
                        </div>
                        <div class="vitals">
                          <label for="vitals">Vitals</label>
                            <ul class="vitals__details">
                          ${
                            appointment?.prescription?.vitals
                              ? Object.keys(
                                  appointment?.prescription?.vitals
                                )?.map(
                                  (key, index) =>
                                    `<li key=${index}>${key}:- <span>${appointment?.prescription?.vitals?.[key]}</span></li>`
                                )
                              : `<li>NA</li>`
                          }
                          </ul>
                          
                        </div>
                        <div class="co-morbidities">
                          <label for="co-morbidities">Co-morbidities</label>
                            <ul>
                          ${
                            appointment?.prescription?.coMorbidities
                              ? Object.keys(
                                  appointment?.prescription?.coMorbidities
                                )?.map(
                                  (key, index) =>
                                    `<li key=${index}>${key}:- <span>${appointment?.prescription?.coMorbidities?.[key]}</span></li>`
                                )
                              : `<li>NA</li>`
                          }
                            </ul>
                        </div>
                        <div class="examinations">
                          <label for="examinations">Examinations</label>
                          <ul>
                            <li>${
                              appointment?.prescription?.examination ?? "NA"
                            }</li>
                          </ul>
                        </div>
                        <div class="medications">
                          <label for="medications">Medications</label>
                          <table>
                            <thead>
                              <tr>
                                <th>Sr.</th>                              
                                <th>Name</th>
                                <th>Dose</th>
                                <th>Frequency</th>
                                <th>Interval</th>
                                <th>Remark</th>
                              </tr>
                            </thead>
                            <tbody>
                            ${appointment?.prescription?.medications?.map(
                              (med, index) =>
                                `<tr key=${index}>
                                <td>${index + 1}</td>
                                <td>${med.name}</td>
                                <td>${med.dose}</td>
                                <td>${med.frequency}</td>
                                <td>${med.num} ${med.interval}</td>
                                <td>${med.remarks}</td>
                              </tr>`
                            )}
                          </tbody>
                          </table>
                        </div>
                        <div class="surgical-plan">
                          <label for="surgical-plan">Surgical Plan</label>
                          <ul>
                            <li>${
                              appointment?.prescription?.surgicalPlan ?? "NA"
                            }</li>
                          </ul>
                        </div>
                       
                        
                        <div class="restrictions-and-instructions">
                          <label for="restrictions-and-instructions"
                            >Restrictions & Instructions</label
                          >
                          <ul>
                            <li>${
                              appointment?.prescription?.dietaryRestriction ??
                              "NA"
                            }</li>
                          </ul>
                        </div>
                        <div class="investigation">
                          <label for="investigation">Investigation</label>
                          <ul>
                            <li>${
                              appointment?.prescription?.investigation ?? "NA"
                            }</li>
                          </ul>
                        </div>
                        <div class="follow-up">
                          <label for="follow-up">Follow-up</label>
                          <ul>
                            <li>${
                              appointment?.prescription?.followUp ?? "NA"
                            }</li>
                          </ul>
                        </div>
                        <div class="follow-up">
                          <label for="follow-up">Your appointment Booked for</label>
                          <ul>
                            <li>
                            <span>${
                              nextAppointmentDateAndTIme
                                ? moment(nextAppointmentDateAndTIme).format(
                                    "ll"
                                  )
                                : "NA"
                            }</li></span>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </body>
              </html>
                `;

    const newWin = window.open();
    newWin.document.body.innerHTML = print_pdf;
    // newWin.onbeforeunload = ()=>newWin.close();
    // newWin.onafterprint = ()=>newWin.close();
    newWin.focus(); // Required for IE
    newWin.print();
  };

  const fetchPrescription = () => {
    if (admitType.current == true) {
      Api2.get(`/getprofile?id=${patientSlug1}`).subscribe((resp) => {
        let inProfileData = resp.data.data;
        setProfileData(inProfileData);
        // let roledata = inProfileData.notes
        // // console.log("roledata",roledata[0].userRole)

        // setRoleData(roledata);
        // setId1(resp.data.data._id);

        initPatientFormFields(resp.data.data);
      });
      return;
    }

    Api.get(
      `appointment/qr-code-new/${slug}?emrTemplateTypeId=${
        selectedEmrOptions?._id || selectedDefaults?._id
      }`
    ).subscribe(async (resp) => {
      if (
        isNaN(
          Number(resp?.data?.data?.prescription?.followUp?.split(" ")?.[0] || 1)
        )
      ) {
        setFollowUpDurationNumber(1);
      } else {
        setFollowUpDurationNumber(
          Number(resp?.data?.data?.prescription?.followUp?.split(" ")?.[0] || 1)
        );
      }

      //setIsFollowUpRqrdChecked(!resp?.data?.data?.prescription?.isFollowUpRequired)

      setFollowUpDurationInterval(
        resp?.data?.data?.prescription?.followUp?.split(" ")?.[1] || "Weeks"
      );

      setNextAppointmentDateAndTIme(
        resp?.data?.data?.prescription?.nextAppointmentDate
      );

      setAppointment(resp.data.data);
      todaysappointmentdata();

      setSelectedPrevPres(resp?.data?.data?.prevPrescriptions || []);
      // setSelectedPrevPres(resp?.data?.data?.prevPrescriptions);
      const prescription = resp?.data?.data?.prescription;
      const notesfield = resp?.data?.data?.patient?.note;

      initPatientFormFields(resp?.data?.data);
      setPrescription(prescription);

      if (
        prescription?.medications !== undefined &&
        prescription?.medications.length !== 0
      ) {
        setMedications((prescription && prescription?.medications) || []);
      }

      setUploadedDiagram(
        prescription && prescription?.diagrams
        // map(
        //   // (item) => `data:${item?.contenttype};base64,${item?.data?.data}`
        //   (item) => item.content
        // )) ||
        // []
      );

      // TODO: check if handwrittenPrescriptionFileUrls is null, then make it backward compatible
      // setImagePrecription(
      //   (prescription &&
      //     prescription.images?.map(
      //       (item) => `data:${item.contenttype};base64,${item.data.data}`
      //     )) ||
      //     []
      // );
      setImagePrecription(prescription?.handwrittenPrescriptionFileUrls)

      setIsFollowUpRqrdChecked(
        prescription && prescription.isFollowUpRequired !== null
          ? prescription.isFollowUpRequired
            ? false
            : true
          : false
      );

      setPrintLetterHead(
        prescription && prescription.printLetterHead ? true : false
      );

      setProv(prescription?.prov);

      setChiefComplaints(prescription?.chiefComplaints);

      setHopi(prescription?.hopi);

      setRemarks(prescription?.remarks);
      setExamination(prescription?.examination);
      setSurgicalPlan(prescription?.surgicalPlan);
      setRestrictions(prescription?.dietaryRestriction);
      setInvestigation(prescription?.investigation);

      setNotes(notesfield?.noteDescription);

      setReferringDoc(resp.data.data?.noteReferData?.referredByDoctor?.name);
      setReferringDocNo(
        resp.data.data?.noteReferData?.referredByDoctor?.mobileNumber
      );
      setReferringDocEmail(
        resp.data.data?.noteReferData?.referredByDoctor?.email
      );

      setReferringOtherEmail(prescription?.otherEmails);

      initVitals("VITALS", prescription && prescription.vitals, configVitals);
      initVitals(
        "COMORBIDITY",
        prescription && prescription.coMorbidities,
        configCoMorbidities
      );

      setNextAppointmentDateAndTIme(
        resp.data.data?.prescription?.nextAppointmentDate
      );

      if (!currentEmrTemp) {
        if (prescription && !prescription.date) {
          prescription.date = new Date().valueOf();
        }
        formApi.current.setValues(prescription);
      } else {
        initPatientFormFields(currentEmrTemp);
      }

      if (prescription && prescription?.emrTemplateTypeId && !firstLoad) {
        if (specialistData && specialistData.length > 0) {
          specialistData.forEach(function (item, index, arr) {
            if (item._id == prescription?.emrTemplateTypeId) {
              setSelectedEmrOptions(item);

              setSelectedEmr(item);
              return;
            }
          });
          setFirstLoad(true);
        }
      }

      fetchPreviousPrescription(resp.data.data.slug);
    });
  };

  const initVitals = (type, data, configData) => {
    let currPrescriptionVitals = [];
    if (data) {
      currPrescriptionVitals = Object.entries(data).map(([key, val]) => ({
        label: key,
        val,
        checked: true,
      }));
    }

    const formattedConfigVitals = configData.map(({ label }) => ({
      label,
      val: "",
      checked: false,
    }));

    const jointArrayOfVitals = [
      ...formattedConfigVitals,
      ...currPrescriptionVitals,
    ];

    const uniqueArrayOfVitals = jointArrayOfVitals.reduce((acc, item) => {
      const i = acc.findIndex((vital) => vital.label === item.label);

      if (i !== -1) {
        acc[i] = { ...acc[i], ...item };

        return acc;
      } else return acc.concat(item);
    }, []);

    if (type === "VITALS") {
      setVitals(uniqueArrayOfVitals);
    } else {
      setCoMorbidities(uniqueArrayOfVitals);
    }
  };

  const [removeImages, setRemoves] = useState(false);
  const [removeDiagram, setRemoveDiagram] = useState(false);

  const onRemoveImageClicked = (label) => {
    if (label === "diagram") {
      setDiagrams([]);
      setUploadedDiagram([]);
      setRemoveDiagram(true);
    } else {
      setImagePrecription([]);
      setRemoves(true);
    }
  };

  const [printLetterHeadDate, setprintLetterHeadDate] = useState();

  const printPrescription = async () => {
    // if handwritten prescription uploaded
    if (imagesPrecription && Array.isArray(imagesPrecription) && imagesPrecription.length > 0) {
      openImageGalleryInNewTab(imagesPrecription);
      return;
    }
    let extpra = "";

    if (
      printLetterHeadChecked === null ||
      printLetterHeadChecked === "" ||
      printLetterHeadChecked === "undefined" ||
      printLetterHeadChecked === false
    ) {
      extpra = extpra + "&&print=0";
    } else {
      extpra = extpra + "&&print=1";
    }

    if (
      printLetterHeadDate === null ||
      printLetterHeadDate === "" ||
      printLetterHeadDate === undefined
    ) {
      extpra = extpra + "";
    } else {
      extpra = extpra + "&&date=" + printLetterHeadDate.format();
    }

    window.open(
      `/#!` + Routes.APPOINTMENTPRINT + `?slug=${slug}` + extpra,
      "_blank"
    );
  };

  const fetchPreviousPrescription = (slug) => {
    DataService.get(`/appointment/previous-prescription/${slug}`).subscribe(
      (resp) => {
        setPrevPrescrptn(resp);
      }
    );
  };

  const fetchEmrTemplates = () => {
    const currentDoctorSlug = isPrimaryProfile ? primaryProfileInfo?.slug : switchedProfileInfo?.doctorSlug;
    DataService.fetchList(
      "emr-template/search_new",
      0,
      2000,
      {
        // custom: {
        //   filterType: "exact",
        //   filter: `forDoctor::slugSpec::${
        //     DocSlug.slug
        //   }::${generateSpecialityFilter(userSpeciality.speciality)}`,
        // },
        // TODO:
        createdBySlug: {
          filterType: "exact",
          filter: `${currentDoctorSlug}`,
        },
        emrTemplateTypeId: {
          filterType: "exact",
          filter: `${selectedEmrOptions?._id}`,
        },
      },
      []
    ).subscribe((resp) => {
      setEmrTemplates(resp.content);
    });
  };

  const fetchMedications = () => {
    DataService.getList(`medication/search/all`).subscribe((resp) => {
      const medList = (resp || []).map((med) => ({
        ...med,
        nameWithDose: `${med.name} ( ${med.dose} )`,
      }));
      setMedList(medList);
    });
  };

  const fetchAppointmentType = () => {
    const userObj = AuthService.getUser();
    if (userObj.appointmentType) {
      let appointmentTypeArr = userObj.appointmentType
        .filter((item) => item.active)
        .map((item) => item.label);
      setAppointmentTypes(appointmentTypeArr);
    }
  };

  // admit patient btn handler
  const handleclickpatientbtn = () => {
    window.location.href = `#!${Routes.ADMITEDPROFILE}/new?patientSlug=${appointment?.patientSlug}&appointmentSlug=${appointment?.slug}&doctorSlug=${appointment?.doctorSlug}`;
  };

  const fetchReports = useCallback(() => {
    DataService.fetchList("report/search-short", 0, 100, {
      patientSlug: {
        values: [appointment && appointment.patientSlug],
        filterType: "set",
      },
    }).subscribe((resp) => {
      setReports([...resp.content.reverse()]);
    });
  }, [appointment]);

  useEffect(fetchReports, [appointment]);
  useEffect(fetchMedications, []);
  useEffect(fetchAppointmentType, []);
  useEffect(fetchPrescription, [
    selectedEmrOptions,
    selectedDefaults,
    patientSlug1,
  ]);
  useEffect(() => {
    // fetch prescription on window resize
    const handleResize = () => {
      if (isMobile) {
        fetchPrescription();
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);
  useEffect(fetchEmrTemplates, [selectedDefaults, selectedEmrOptions]);

  const handleChange = async (label, evt) => {
    setImageLoading(true);
    setImageLabel(label);

    const files = Array.from(evt.target.files);

    try {
      // Get the appointment slug from the current state or props
      const appointmentSlug = slug || query.get("slug");

      // Use the new method to upload all files at once
      const uploadedUrls = await UploadAPI.uploadFileToBlobAndGetFileURLs(
        files,
        appointmentSlug
      );

      // Update state with new image URLs
      setImagePrecription((prevImages) => {
        // Ensure prevImages is an array
        const prevImagesArray = Array.isArray(prevImages) ? prevImages : [];
        return [...prevImagesArray, ...uploadedUrls];
      });

      setImageLoading(false);
      SHOW_SUCCESS_NOTIFICATION(
        `${files.length} file(s) uploaded successfully`
      );
    } catch (error) {
      setImageLoading(false);
      SHOW_ERROR_NOTIFICATION("Error uploading files");
      console.error("Upload error:", error);
    }
  };

  const handleChangeDiagram = (evt) => {
    setIsLoading(true);

    const files = Array.prototype.slice.call(evt.target.files);
    const supportedFormats = [
      "application/pdf",
      "image/jpeg",
      "image/png",
      "image/jpg",
    ];

    const unsupportedFiles = files.filter(
      (file) => !supportedFormats.includes(file.type)
    );

    if (unsupportedFiles.length > 0) {
      SHOW_ERROR_NOTIFICATION(
        "Only PDF, JPG, JPEG, and PNG files are allowed."
      );
      setIsLoading(false);
      return;
    }

    let fileArray = Object.values(evt.target.files);
    let formData = new FormData();

    for (let i = 0; i < fileArray.length; i++) {
      formData.append("files", fileArray[i]);
    }
    Api2.post(`upload_temp_image`, formData).subscribe(
      (resp) => {
        setUploadedDiagramid(resp.data.data);
        setUploadedDiagram(resp.data.data.map((content) => content));
        setIsLoading(false);

        // setRemoveDiagram(false);
      },
      (error) => {
        setIsLoading(false);
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const onToggleVitalEditMode = (type) => {
    if (type == "vitals") {
      setVitalEditMode(!vitalEditMode);
    } else {
      setComEditMode(!comEditMode);
    }
    setVitalType(type);
  };

  const onToggleTemplateMode = () => {
    setTemplateInfo(formApi.current.getValues());
    setTemplateMode(!templateMode);
  };

  const handleVitalNotesSubmit = (val) => {
    if (!val.remarks || !val.vitals) {
      SHOW_ERROR_NOTIFICATION("Please fill in the data before saving");
      return;
    }
    const obj = {
      remarks: val.remarks,
      vitals: val.vitals,
    };
    Api.put(`appointment/prescription/notes-vitals/${slug}`, obj).subscribe(
      () => {
        SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
        fetchPrescription();
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };

  const resetEmrTempateSelectInput = () => {
    setCurrentEmrTemp(null);
  };

  const getVital = useCallback((currentVital) => {
    const finalVitalObj = {};
    currentVital.map((vital) => {
      let newObj = {};
      newObj[vital.label] = vital.val;
      return Object.assign(finalVitalObj, newObj);
    });
    return { ...finalVitalObj };
  }, []);

  const handleChangeVital = useCallback((value, index, setVital) => {
    setVital((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState[index].val = value;
      return [...newState];
    });
  }, []);

  // let medicationData = medications?.filter((med) => med?.name && med?.dose);

  const handleSubmit = (val) => {
    let medicationData = medications?.filter((med) => med?.name && med?.dose);
    val.isFollowUpRequired = !isFollowUpRqrdChecked;
    if (
      isAuthorised(AppConstants.ENTITY.VITALS_NOTES) &&
      isCurrentRole(AppConstants.ROLE.NURSE)
    ) {
      handleVitalNotesSubmit(val);
    } else {
      {
        textData &&
          Object.keys(textData)?.length > 0 &&
          Object.keys(textData).map((key, index) => {
            return `val.${key} = ${textData[key]}`;
          });
      }

      val.emrTemplateTypeId = selectedEmrType && selectedEmrType?._id;
      val.notes = [{ noteDescription: notes }];
      val.referringDoctor = referringDoc;
      val.referringDoctorMobile = referringDocNo;
      val.referringDocEmail = referringDocEmail;
      val.otherEmails = referringOtherEmail;
      val.medications = medicationData;
      val.coMorbidities = getVital(selectedCoMorbidities);
      val.vitals = getVital(selectedVitals);
      val.imageIds = images?.map((imageId) => imageId.imageId);
      val.handwrittenPrescriptionFileUrls = imagesPrecription;
      val.diagramIds = uploadedDiagramid?.map((imageId) => imageId.imageId);
      val.removePrescriptions = removeImages;
      val.removeDiagrams = removeDiagram;
      val.appointmentNotNeeded = isFollowUpRqrdChecked;
      val.followUp = followUpDurationNumber + " " + followUpDurationInterval;

      val.printLetterHead = printLetterHeadChecked;
      // if (nextAppointment) {
      //   val.nextAppointmentDate = nextAppointment
      // }
      val.otherEmails = Array.isArray(val.otherEmails)
        ? val.otherEmails
        : val.otherEmails && val.otherEmails.split(",");
      val.testName = Array.isArray(val.testName)
        ? val.testName
        : val.testName && val.testName.split(",");
      if (isTestNeeded) {
        val.testNeeded = true;
      }

      if (!val.date) {
        val.date = new Date().valueOf();
      }

      //if (createAppointmentRespose) {
      if (nextAppointmentDateAndTIme) {
        // val.nextAppointmentDate = new Date(newDate).getTime();
        val.nextAppointmentDate = nextAppointmentDateAndTIme;
      }

      const templateObject = val;
      for (const key in emrObject) {
        if (emrObject.hasOwnProperty(key)) {
          templateObject[key] = emrObject[key];
        }
      }

      switch (submitButton) {
        case 2:
          val.action = "DRAFT";
          val.images = [];
          val.diagrams = [];

          val.notes = [{ noteDescription: notes }];
          val.removePrescriptions = removeImages;
          val.removeDiagrams = removeDiagram;
          val.otherEmails = referringOtherEmail;
          val.coMorbidities = getVital(selectedCoMorbidities);
          val.vitals = getVital(selectedVitals);
          val.medications = medicationData;
          val.emrTemplateTypeId = selectedEmrType && selectedEmrType?._id;
          val.handwrittenPrescriptionFileUrls = imagesPrecription ?? []

          if (isDetailedConsultRequired) {
            val.isDetailedConsult = isDetailedConsultRequired;
          }
          Api2.put(`add_appointment_prescription/${slug}`, val).subscribe(
            () => {
              SHOW_SUCCESS_NOTIFICATION("Saved As Draft !");
              fetchPrescription();
            },
            (error) => {
              DEFAULT_ERROR_CALLBACK(error);
            }
          );
          break;
        case 1:
          val.action = "COMPLETED";
          val.images = [];
          val.diagrams = [];

          val.notes = [{ noteDescription: notes }];
          val.removePrescriptions = removeImages;
          val.removeDiagrams = removeDiagram;
          val.otherEmails = referringOtherEmail;
          val.coMorbidities = getVital(selectedCoMorbidities);
          val.vitals = getVital(selectedVitals);
          val.medications = medicationData;
          val.emrTemplateTypeId = selectedEmrType && selectedEmrType?._id;
          val.followUp =
            followUpDurationNumber + " " + followUpDurationInterval;
          val.handwrittenPrescriptionFileUrls = imagesPrecription ?? [];

          Api2.put(
            `add_appointment_prescription_notify/${slug}`,
            val
          ).subscribe(
            () => {
              SHOW_SUCCESS_NOTIFICATION("Save And Notify Successfully !");
            },
            (error) => {
              DEFAULT_ERROR_CALLBACK(error);
            }
          );
          break;
        case 0:
          templateObject.action = "COMPLETED";
          templateObject.images = [];
          templateObject.notes = [{ noteDescription: notes }];
          templateObject.removePrescriptions = removeImages;
          templateObject.removeDiagrams = removeDiagram;
          templateObject.diagrams = [];
          templateObject.otherEmails = referringOtherEmail;
          templateObject.coMorbidities = getVital(selectedCoMorbidities);
          templateObject.vitals = getVital(selectedVitals);
          templateObject.medications = medicationData;
          templateObject.handwrittenPrescriptionFileUrls = imagesPrecription ?? [];
          templateObject.emrTemplateTypeId =
            selectedEmrType && selectedEmrType?._id;
          {
            if (isFollowUpRqrdChecked == true) {
              templateObject.followUp = " ";
            } else {
              templateObject.followUp =
                followUpDurationNumber + " " + followUpDurationInterval;
            }
          }
          templateObject.isFollowUpRequired = !isFollowUpRqrdChecked;
          if (admitType.current) {
            delete templateObject.followUp;
            delete templateObject.referringDoctor;
            delete templateObject.referringDoctorMobile;
            delete templateObject.referringDocEmail;
            delete templateObject.otherEmails;
            Api.post(`admit/history/add`, {
              admittedId: newId,
              prescription: templateObject,
            }).subscribe(
              () => {
                SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
                //move on another link
              },
              (error) => {
                DEFAULT_ERROR_CALLBACK(error);
              }
            );
          } else {
            Api2.put(
              `add_appointment_prescription/${slug}`,
              templateObject
            ).subscribe(
              () => {
                SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
                fetchPrescription();
              },
              (error) => {
                DEFAULT_ERROR_CALLBACK(error);
              }
            );
          }
          break;
        case 3:
          templateObject.action = "COMPLETED";
          templateObject.images = [];
          templateObject.notes = [{ noteDescription: notes }];
          templateObject.removePrescriptions = removeImages;
          templateObject.removeDiagrams = removeDiagram;
          templateObject.diagrams = [];
          templateObject.otherEmails = referringOtherEmail;
          templateObject.coMorbidities = getVital(selectedCoMorbidities);
          templateObject.vitals = getVital(selectedVitals);
          templateObject.medications = medicationData;
          templateObject.handwrittenPrescriptionFileUrls = imagesPrecription ?? [];
          templateObject.emrTemplateTypeId =
            selectedEmrType && selectedEmrType?._id;
          {
            if (isFollowUpRqrdChecked == true) {
              templateObject.followUp = " ";
            } else {
              templateObject.followUp =
                followUpDurationNumber + " " + followUpDurationInterval;
            }
          }
          templateObject.isFollowUpRequired = !isFollowUpRqrdChecked;
          if (admitType.current) {
            delete templateObject.followUp;
            delete templateObject.referringDoctor;
            delete templateObject.referringDoctorMobile;
            delete templateObject.referringDocEmail;
            delete templateObject.otherEmails;
            Api.post(`admit/history/add`, {
              admittedId: newId,
              prescription: templateObject,
            }).subscribe(
              () => {
                SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
                handleclickpatientbtn();
              },
              (error) => {
                DEFAULT_ERROR_CALLBACK(error);
              }
            );
          } else {
            Api2.put(
              `add_appointment_prescription/${slug}`,
              templateObject
            ).subscribe(
              () => {
                SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
                fetchPrescription();
                handleclickpatientbtn();
              },
              (error) => {
                DEFAULT_ERROR_CALLBACK(error);
              }
            );
          }
          break;
      }
    }
  };

  const initPrescriptionFields = (temp) => {
    if (prescription) {
      temp.referringDoctor = prescription.referringDoctor;
      temp.referringDoctorMobile = prescription.referringDoctorMobile;
      temp.vitals = prescription.vitals;
      temp.remarks = prescription.remarks;
      temp.notes = prescription.notes;
      temp.date = new Date().valueOf();
    }
    if (prescription && prescription.diagrams && temp.diagramIds) {
      temp.diagramIds.concat(prescription.diagrams);
      setDiagrams(temp.uploadedDiagramid);
    } else {
      setDiagrams(temp.uploadedDiagramid || []);
    }

    formApi.current.setValues(temp);
  };

  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [bmi, setBmi] = useState("");
  const [info, setInfo] = useState("");

  const initPatientFormFields = async (data) => {
    if (data?.patient) {
      patientFormApi.current.setValue("name", data?.patient?.name);
      setPatientDOB(dayjs(data?.patient?.dob));
      patientFormApi.current.setValue("dob", data?.patient?.dob);
      patientFormApi.current.setValue("email", data?.patient?.email);
      patientFormApi.current.setValue("allergies", data?.patient?.allergies);
      patientFormApi.current.setValue("creatorRole", data?.creatorRole);
      patientFormApi.current.setValue("creatorName", data?.creatorName);
      patientFormApi.current.setValue(
        "mobileNumber",
        data.patient?.mobileNumber
      );
      patientFormApi.current.setValue(
        "gender",
        data.patient?.gender ? data.patient?.gender : ""
      );
      patientFormApi.current.setValue("uhid", data.patient?.uhid);
      patientFormApi.current.setValue(
        "lastVisit",
        data.patient?.lastVisit
          ? moment(data.patient?.lastVisit).format("MMM Do YY")
          : "N/A"
      );
      patientFormApi.current.setValue(
        "address",
        data.patient?.address ?? "N/A"
      );
      patientFormApi.current.setValue("height", data.patient?.height);
      setHeight(data.patient?.height);
      patientFormApi.current.setValue("weight", data.patient?.weight);
      setWeight(data.patient?.weight);

      let cal = (
        [
          Number(data.patient?.weight) /
            Number(data.patient?.height) /
            Number(data.patient?.height),
        ] * 10000
      ).toFixed(1);
      // alert(JSON.stringify(`bmi: ${cal}`));
      await patientFormApi.current.setValue("bmi", `${cal}`);

      patientFormApi.current.setValue(
        "maritalStatus",
        data.patient?.maritalStatus
      );
      patientFormApi.current.setValue("remarks", allAppointDetails?.remarks);
      patientFormApi.current.setValue("occupation", data.patient?.occupation);
    } else {
      patientFormApi.current.setValue("name", data?.name);
      patientFormApi.current.setValue("dob", data?.dob);
      setPatientDOB(dayjs(data?.patient?.dob));
      patientFormApi.current.setValue("email", data?.email);
      patientFormApi.current.setValue("allergies", data?.allergies);

      patientFormApi.current.setValue("creatorRole", data?.creatorRole);
      patientFormApi.current.setValue("creatorName", data?.creatorName);

      patientFormApi.current.setValue("mobileNumber", data?.mobileNumber);
      patientFormApi.current.setValue(
        "gender",
        data?.gender ? data?.gender : ""
      );
      patientFormApi.current.setValue("uhid", data?.uhid);
      patientFormApi.current.setValue(
        "lastVisit",
        data?.lastVisit ? moment(data?.lastVisit).format("MMM Do YY") : "N/A"
      );
      patientFormApi.current.setValue("address", data?.address ?? "N/A");
      patientFormApi.current.setValue("height", data?.height);
      setHeight(data?.height);
      patientFormApi.current.setValue("weight", data?.weight);
      setWeight(data?.weight);

      let cal = (
        [Number(data?.weight) / Number(data?.height) / Number(data?.height)] *
        10000
      ).toFixed(1);

      await patientFormApi.current.setValue("bmi", `${cal}`);

      patientFormApi.current.setValue("maritalStatus", data?.maritalStatus);
      // patientFormApi.current.setValue("remarks", allAppointDetails?.remarks);
      patientFormApi.current.setValue("occupation", data?.occupation);
    }
  };

  useEffect(() => {
    if (weight && height) {
      let cal = (
        [Number(weight) / Number(height) / Number(height)] * 10000
      ).toFixed(1);

      setBmi(cal);
      if (cal < 18.5) {
        setInfo("UnderWeight");
      } else if (cal >= 18.5 && cal <= 24.9) {
        setInfo("Normal");
      } else {
        setInfo("Obese");
      }
    } else {
      setBmi("");
      // setInfo('');
    }
  }, [weight, height]);

  const handlePatientFormSubmit = (val) => {
    if ((val && appointmentRef.current) || (val && profiledataRef.current)) {
      let temp;

      if (appointmentRef.current?.patient) {
        temp = appointmentRef.current?.patient;
      } else {
        temp = profiledataRef.current;
      }

      temp.name = val.name;
      temp.dob = patientDOB;
      temp.gender = val.gender;
      temp.email = val.email;
      temp.address = val.address;
      temp.height = val.height;
      temp.weight = val.weight;
      temp.bmi = bmi;
      temp.occupation = val.occupation;
      temp.maritalStatus = val.maritalStatus;

      if (appointmentRef.current?.patient) {
        temp.remarks = val.remarks;
      }

      temp.allergies = val.allergies;

      updatePatient(temp);
    }
  };

  const updatePatient = (data) => {
    let requestData = {};

    if (admitType.current == true) {
      data.id = profiledataRef.current._id;
      const { _id, ...restData } = data;
      requestData = restData;
    }

    setSaved(true);
    if (admitType.current == true) {
      Api.put(`patient/profile`, requestData).subscribe(
        () => {
          setSaved(false);

          SHOW_SUCCESS_NOTIFICATION("Updated sucessfully");
          fetchPrescription();
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
          setSaved(false);
        }
      );
    } else {
      Api.put(`patient/profile`, data).subscribe(
        () => {
          setSaved(false);
          SHOW_SUCCESS_NOTIFICATION("Updated sucessfully");
          fetchPrescription();
        },
        (error) => {
          DEFAULT_ERROR_CALLBACK(error);
          setSaved(false);
        }
      );
    }
  };

  const clearAllFieldWithoutMedications = () => {
    formApi.current.reset();
    setUploadedDiagram([]);
    setImagePrecription([]);
    setRemoves(false);
    setRemoveDiagram(false);
    resetEmrTempateSelectInput();
    setFollowUpDurationNumber(1);
    setFollowUpDurationInterval("Weeks");
    setProv("");
    setChiefComplaints("");
    setHopi("");
    setRemarks("");
    setExamination("");
    setSurgicalPlan("");
    setRestrictions("");
    setInvestigation("");
    setNotes("");
    setReferringDoc("");
    setReferringDocNo("");
    setReferringDocEmail("");
    setReferringOtherEmail(null);
  };

  const onClearAllBtnClicked = () => {
    setEmrObject({});
    setSelectedEmrOption({});
    setTypeEmrTemplate({});
    setSelectedPrescriptionOption({});
    clearAllFieldWithoutMedications();
    setMedications([]);
    setTemplateSelected(false);
  };

  const onClearBtnClicked = (key) => {
    formApi.current.resetField(key);
    if (key === "medications") {
      setSelectedMedicationOption({});
      setSelectedMedicationPrev({});
      clearMedicationField();
      setMedications([]);
      // handlePatientsPrescriptionKeyUp();
      setTemplateSelected(false);
      // setAvailableTemplatesLabel([])
      return;
    } else if (key === "coMorbidities") {
      setSelectedCoMorbPrev({});
      initVitals("COMORBIDITY", null, configCoMorbidities);
      return;
    } else if (key === "vitals") {
      setSelectedCoMorbPrev({});
      initVitals("VITALS", null, configVitals);
      setRemarks("");
      return;
    }
  };

  const onMedicationSelected = (val, index) => {
    formApi.current.setValue(`medications[${index}].name`, val.name);
    formApi.current.setValue(`medications[${index}].dose`, val.dose);
    formApi.current.setValue(`medications[${index}].frequency`, val.frequency);
    formApi.current.setValue(`medications[${index}].num`, val.num);
    formApi.current.setValue(`medications[${index}].interval`, val.interval);
    formApi.current.setValue(`medications[${index}].remarks`, val.remarks);
    formApi.current.setValue(`medications[${index}].id`, val.id);

    setMedications((prev) => {
      const medication = JSON.parse(JSON.stringify(prev));
      medication[index] = val;
      return [...medication];
    });
  };

  const onTemplateSelect = (e, option) => {
    if (e) {
      setSelectedEmrOption(option);
    }
    const temp = option?.value;

    if (temp) {
      const prevMedicines = medications.filter((el) => {
        return !templateMedications?.find((element) => {
          return element.name === el?.name;
        });
      });

      setMedications([...prevMedicines]);
      setCurrentEmrTemp(temp);
      setProv(temp?.prov);
      setChiefComplaints(temp?.chiefComplaints);
      setHopi(temp?.hopi);

      setSurgicalPlan(temp?.surgicalPlan);
      setRestrictions(temp?.dietaryRestriction);
      setInvestigation(temp?.investigation);
      setExamination(temp?.examination);

      if (temp?.medications) {
        setMedications((prevState) => {
          const newState = JSON.parse(JSON.stringify(prevState));
          return [...newState, ...temp?.medications];
        });
        setTemplateMedications(temp?.medications);
      }
      setTemplateSelected(true);
      setTimeout(() => {
        initPrescriptionFields(temp);
      }, 200);
    } else {
      if (prescription) {
        onPrevPrescriptionSelect(null, prescription);
      } else {
        // clears all the fields
        clearAllFieldWithoutMedications();
      }
      const prevMedicines = medications.filter((el) => {
        return !templateMedications?.find((element) => {
          return element.name === el?.name;
        });
      });
      setMedications([...prevMedicines]);
      setTemplateSelected(false);
    }
  };

  const onMedicationTemplateSelected = (_, option, field) => {
    setSelectedMedicationOption(option);
    let val = option?.value;

    if (val && val.medications) {
      const prevMedicines = medications.filter((el) => {
        return !templateMedications?.find((element) => {
          return element.name === el?.name;
        });
      });
      setMedications([...prevMedicines]);

      setMedications((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        // return [...newState, ...val.medications];
        return [...val.medications];
      });
      setTemplateSelected([...val.medications]);

      setTimeout(() => {
        copyMedicationsData(medications);
      }, 200);
    } else {
      const prevMedicines = medications.filter((el) => {
        return !templateMedications?.find((element) => {
          return element.name === el?.name;
        });
      });
      setMedications([...prevMedicines]);
    }

    // handlePatientsPrescriptionKeyUp();
  };

  const onMedicationPrevPriscSelected = (_, option, field) => {
    setSelectedMedicationPrev(option);
    let val = option;

    if (val && val.medications) {
      const prevMedicines = medications.filter((el) => {
        return !templateMedications?.find((element) => {
          return element.name === el?.name;
        });
      });
      setMedications([...prevMedicines]);

      setMedications((prevState) => {
        const newState = JSON.parse(JSON.stringify(prevState));
        // return [...newState, ...val.medications];
        return [...val.medications];
      });
      setTemplateSelected([...val.medications]);

      setTimeout(() => {
        copyMedicationsData(medications);
      }, 200);
    } else {
      const prevMedicines = medications.filter((el) => {
        return !templateMedications?.find((element) => {
          return element.name === el?.name;
        });
      });
      setMedications([...prevMedicines]);
    }

    // handlePatientsPrescriptionKeyUp();
  };

  const onCoMorbidPrevPriscSelected = (_, option, field) => {
    setSelectedCoMorbPrev(option);
    initVitals(
      "COMORBIDITY",
      option && option.coMorbidities,
      configCoMorbidities
    );
  };

  const onVitalsPrevPriscSelected = (_, option, field) => {
    setSelectedVitalsPrev(option);
    setRemarks(option?.remarks ?? "");
    initVitals("VITALS", option && option.vitals, configVitals);
  };

  const handleClearObjectValues = useCallback(
    (key) => {
      individualTemplateOption[key] = null;
      //delete individualTemplateOption[key];
      setIndividualTemplateOption(individualTemplateOption);
      /* if (key == "dietaryRestriction") {
      setTempRestrictionOption(null);
    } */

      setEmrObject((prevFormData) => ({
        ...prevFormData,
        [key]: "",
      }));

      // setSelectedOption({ ...selectedOption, key: null });
    },
    [individualTemplateOption, setIndividualTemplateOption, setEmrObject]
  );

  const onRemoveMedicationClicked = (val) => {
    let prevMedications = JSON.parse(JSON.stringify(medications));
    prevMedications.splice(val, 1);
    setMedications(prevMedications);
    if (prevMedications.length === 1 && !prevMedications[0].name) {
      clearMedicationField();
    } else {
      setTimeout(() => {
        copyMedicationsData(prevMedications);
      }, 200);
    }
    // handlePatientsPrescriptionKeyUp();
  };

  const copyMedicationsData = (data) => {
    return data?.forEach((item, index) => {
      formApi.current.setValue(`medications[${index}].name`, item.name || "");
      formApi.current.setValue(`medications[${index}].dose`, item.dose || "");
      formApi.current.setValue(
        `medications[${index}].frequency`,
        item.frequency
      );
      formApi.current.setValue(`medications[${index}].num`, item.num);
      formApi.current.setValue(`medications[${index}].interval`, item.interval);
      formApi.current.setValue(
        `medications[${index}].remarks`,
        item.remarks || ""
      );
      formApi.current.setValue(`medications[${index}].id`, item.id || "id");
    });
  };

  const copyFollowUpTime = (number, interval) => {
    setFollowUpDurationInterval(interval);

    setFollowUpDurationNumber(number);
  };

  const copyMedicationsInterVal = (data) => {
    const { interval, num: number } = data;
    copyFollowUpTime(number, interval);
    setMedications((prevState) => {
      const newState = JSON.parse(JSON.stringify(prevState));
      newState.forEach((item) => {
        item.num = number;
        item.interval = interval;
      });
      return [...newState];
    });
    // handlePatientsPrescriptionKeyUp();
  };

  const clearMedicationField = () => {
    medications.forEach((item, index) => {
      formApi.current.resetField(`medications[${index}].name`);
      formApi.current.resetField(`medications[${index}].dose`);
      formApi.current.resetField(`medications[${index}].frequency`);
      formApi.current.resetField(`medications[${index}].num`);
      formApi.current.resetField(`medications[${index}].interval`);
      formApi.current.resetField(`medications[${index}].remarks`);
      formApi.current.resetField(`medications[${index}].id`);
    });
  };

  const handlePrintLetterHeadChange = () => {
    setPrintLetterHead(!printLetterHeadChecked);
  };

  const followUpIntervals = [
    { label: "Days", value: "Days" },
    { label: "Weeks", value: "Weeks" },
    { label: "Months", value: "Months" },
  ];

  const [loader, setLoader] = useState();
  const [createAppointmentRespose, setcreateAppointmentRespose] =
    useState(false);

  const [nextAppointmentDateAndTIme, setNextAppointmentDateAndTIme] =
    useState("");

  const onCreateAppoinment = async () => {
    setLoader(true);
    const slot = formApi.current.getValue("timeSlot");

    if (!slot && !selectedTime) {
      SHOW_INFO_NOTIFICATION(AppConstants.MSG.NO_SLOT_MSG);
      setLoader(false);
      return;
    }

    if (!appoinmentTypeId) {
      SHOW_INFO_NOTIFICATION("Select Appointment Type");
      setLoader(false);
      return;
    }

    const formData = new FormData();
    formData.append("startDateTime", startDateTime);
    formData.append("endDateTime", endDateTime);
    formData.append("doctorSlug", appointment.doctorSlug);
    formData.append("patientSlug", appointment.patientSlug);
    formData.append("slotId", slotId);
    formData.append("appointmentTypeId", appoinmentTypeId || "");
    formData.append("minutes", minutes || "");
    formData.append("fee", fees);
    formData.append("creatorName", user.name);
    formData.append("creatorRole", user.roleName);
    formData.append("creatorNumber", user.mobileNumber);
    formData.append("from", user.roleName);
    formData.append("notifyPatient", true);
    // formData.append("nextAppointmentDate", nextAppointmentDateAndTIme);

    const token = AuthService.getToken();
    try {
      const response = await Axios({
        method: "post",
        url: `${process.env.REACT_APP_API2_HOST}add_appointment`,
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          "X-AUTH-TOKEN": token,
        },
      });
      if (response) {
        if (response?.data?.message === "Added Successfully!") {
          SHOW_SUCCESS_NOTIFICATION(response?.data?.message);
          setLoader(false);
          setNextAppointmentDateAndTIme(response?.data?.data?.date);
          setcreateAppointmentRespose(true);
          setIsNewAppointment(!isNewAppointment);
        } else {
          SHOW_SUCCESS_NOTIFICATION(response?.data?.message);
          setLoader(false);
          setcreateAppointmentRespose(false);
        }
        // clear selected time slot
        setSelectedTime(null);
        setEndDateTime(null);
        setStartDateTime(null);
        setMinutes(null);
        setSlotId(null);
        setAppointmentId(null);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    Api2.get(`get_doctor_appointment_types?id=${user.slug}`).subscribe(
      (resp) => {
        if (resp) {
          setAllAppoinmentType(resp.data);
        }
      }
    );
  }, []);

  const [newDate, setnewDAte] = useState(null);

  useEffect(
    useCallback(() => {
      const date = new Date();
      let next_date = "";
      if (followUpDurationInterval === "Days") {
        next_date = format(
          new Date(date.setDate(date.getDate() + +followUpDurationNumber)),
          "yyyy-MM-dd"
        );
        setnewDAte(next_date);
      } else if (followUpDurationInterval === "Weeks") {
        next_date = format(
          new Date(date.setDate(date.getDate() + +followUpDurationNumber * 7)),
          "yyyy-MM-dd"
        );
        setnewDAte(next_date);
      } else if (followUpDurationInterval === "Months") {
        // next_date = date.add(followUpDurationNumber).month();
        next_date = format(
          new Date(date.setMonth(date.getMonth() + +followUpDurationNumber)),
          "yyyy-MM-dd"
        );
        setnewDAte(next_date);
      }

      Axios.get(
        `${process.env.REACT_APP_API2_HOST}get_slots_of_doctor_by_date?slug=${user.slug}&date=${next_date}&appointmentId=${appoinmentTypeId}`
      ).then((resp) => {
        if (resp) {
          setSlots(resp.data.data);
        }
      });
    }, [followUpDurationInterval, appoinmentTypeId, followUpDurationNumber]),
    [appoinmentTypeId, followUpDurationNumber, followUpDurationInterval]
  );

  const onSlotSelected = (time) => {
    console.log('selected time slot is ', time);
    if (selectedTime) {
      setSelectedTime(time.startTimeOnly);
      return;
    }
    setSelectedTime(time.startTimeOnly);
    setEndDateTime(time.endTimeString);
    setStartDateTime(time.startTimeString);
    setMinutes(time.minutes);
    setSlotId(time.slotId);
    setFees(time.fee);
  };

  const selectFollowUpInterval = (interval) => {
    setFollowUpDurationInterval(interval);
  };

  const options = useMemo(
    () =>
      emrTemplates.map(({ id, name, template }) => ({
        value: template,
        label: name,
      })),
    [emrTemplates]
  );

  const onPrevPrescriptionSelect = async (e, prevPrescription) => {
    prevPrescription.nextAppointmentDate = null;

    for (let key in prevPrescription) {
      if (key.indexOf("date") !== -1) {
        delete prevPrescription[key];
      }
    }

    const newdate = new Date(prevPrescription?.date);
    
    if (e) {
      setSelectedPrescriptionOption(prevPrescription);
      setEmrObject(prevPrescription);
    }

    if (prevPrescription) {
      const prevMedicines = medications?.filter((el) => {
        return !prevPrescriptionMedications?.find((element) => {
          return element.name === el?.name;
        });
      });
      if (!admitType.current) {
        setMedications([...prevMedicines]);
      }

      setPrescription(prevPrescription);
      if (!admitType.current) {
        setMedications(prevPrescription?.medications || []);
      }
      setImagePrecription(
        prevPrescription?.images?.map(
          (item) => `data:${item.contenttype};base64,${item.data.data}`
        ) || []
      );
      setRemoves(false);

      setPrevPrescriptionMedications([...prevPrescription?.medications]);

      setPrintLetterHead(
        prevPrescription && prevPrescription.printLetterHead ? true : false
      );

      initVitals(
        "VITALS",
        prevPrescription && prevPrescription.vitals,
        configVitals
      );
      initVitals(
        "COMORBIDITY",
        prevPrescription && prevPrescription.coMorbidities,
        configCoMorbidities
      );

      setProv(prevPrescription?.prov);

      setChiefComplaints(prevPrescription?.chiefComplaints);

      setHopi(prevPrescription?.hopi);

      setRemarks(prevPrescription?.remarks);
      setExamination(prevPrescription?.examination);
      setSurgicalPlan(prevPrescription?.surgicalPlan);
      setRestrictions(prevPrescription?.dietaryRestriction);

      setInvestigation(prevPrescription?.investigation);

      setReferringDoc(prevPrescription?.referringDoctor);
      setReferringDocNo(prevPrescription?.referringDoctorMobile);
      setReferringDocEmail(prevPrescription?.referringDocEmail);
      setReferringOtherEmail(prevPrescription?.otherEmails);
    } else {
      // clear all the fields
      if (currentEmrTemp) {
        onTemplateSelect(null, { value: currentEmrTemp });
      } else {
        clearAllFieldWithoutMedications();
      }
      const prevMedicines = medications?.filter((el) => {
        return !prevPrescriptionMedications?.find((element) => {
          return element.name === el?.name;
        });
      });
      if (!admitType.current) {
        setMedications([...prevMedicines]);
      }
    }
  };

  const handleChangeFollowUpDurationNumber = (e) => {
    const followUpDuration = e.target.value;

    setFollowUpDurationNumber(followUpDuration);
    // clear selected time slot
    setSelectedTime(null);
    setEndDateTime(null);
    setStartDateTime(null);
    setMinutes(null);
    setSlotId(null);
  };

  const debouncingPatinentDetails = (changeFunction, delay) => {
    let timer;
    return () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        patientFormApi?.current?.getValues() &&
          changeFunction(patientFormApi?.current?.getValues());
      }, delay);
    };
  };

  const handlePatientsPrescriptionKeyUp = useCallback();
  // debouncingPrescription(handleSavePrescriptionAsDraft, 10000),
  // []

  const handlePatientsPersonalDetailsKeyUp = useCallback(
    debouncingPatinentDetails(handlePatientFormSubmit, 10000),
    []
  );

  const handleSelectedReport = (report) => {
    setSelectedReport(report);

    Api.get(`report/${report.slug}`).subscribe(
      (resp) => {
        setSelectSingleImage(resp.data.data);
        setOpenReport(true);
        if (resp.data.data?.images) {
          let fileType = null;
          fileType = resp.data.data.images.map((data) => data.contenttype);
          setReportType(fileType);
        }
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
  };


  const handleCloseReport = useCallback(() => {
    setOpenReport(false);
  }, []);

  const handleIndividualTemplateChange = useCallback(
    (_, options, field) => {
      individualTemplateOption[field] = options;
      setIndividualTemplateOption(individualTemplateOption);

      //setTempRestrictionOption(options);
      setEmrObject((prevFormData) => ({
        ...prevFormData,
        [field]: options?.value[field],
      }));
    },
    [individualTemplateOption, setIndividualTemplateOption, setEmrObject]
  );

  const onIndividualPrescriptionSelect = useCallback(
    (e, options, field) => {
      /* if (prevPrescription) {
      const prevMedicines = medications?.filter((el) => {
        return !prevPrescriptionMedications?.find((element) => {
          return element.name === el?.name;
        });
      });
    } */
      individualPrevPrisc[field] = options;
      setIndividualPrevPrisc(individualPrevPrisc);

      setEmrObject((prevFormData) => ({
        ...prevFormData,
        [field]: options?.[field],
      }));
    },
    [individualPrevPrisc, setIndividualPrevPrisc, setEmrObject]
  );

  const handleCallNextBtn = useCallback(async () => {
    await todaysappointmentdata((first_slug) => {
      if (first_slug) {
        const patientSlug = first_slug;
        Api.put(`appointment/call-next/${patientSlug}`).subscribe(
          async (resp) => {
            SHOW_SUCCESS_NOTIFICATION(
              `${AppConstants.MSG.UPDATE_SUCCESS},openning new prescription.`
            );
            setTimeout(() => {
              window.open(
                `#!${Routes.PRESCRIPTION}?slug=${first_slug}`,
                "_blank"
              );
            }, 1000);
          },
          (error) => {
            DEFAULT_ERROR_CALLBACK(error);
          }
        );
      } else SHOW_INFO_NOTIFICATION("No patient in the queue.");
    });
  }, [toBeCalled]);

  const handleSendWhatsapp = useCallback(() => {
    const { prescription, patient } = appointment;
    const name = patient.name ?? "NA";
    const dob = patient.dob ?? "NA";
    const gender = patient.gender ?? "NA";
    const mobile = patient.mobileNumber ?? "NA";
    const email = patient.email ?? "NA";
    const uhid = patient.uhid ?? "NA";
    const address = patient.address ?? "NA";
    const prov = prescription?.prov ?? "NA";
    const chiefComplaints = prescription?.chiefComplaints ?? "NA";
    const hopi = prescription?.hopi ?? "NA";
    const remarks = prescription?.remarks ?? "NA";
    const examination = prescription?.examination ?? "NA";
    const surgical = prescription?.surgicalPlan ?? "NA";
    const restrictions = prescription?.dietaryRestriction ?? "NA";

    const investigation = prescription?.investigation ?? "NA";
    const note = prescription?.notes[0].noteDescription ?? "NA";
    const referDoc = prescription?.referringDoctor ?? "NA";
    const referMob = prescription?.referringDoctorMobile ?? "NA";
    const referEmail = prescription?.referringDocEmail ?? "NA";
    const followUp = prescription?.followUp ?? "Not required";

    const meds = medications.map((med) => {
      const medsInLink = [];
      medsInLink.push(
        `Name: ${med.name}`,
        `Dose: ${med.dose}`,
        `Interval: ${med.num} ${med.interval}`,
        `Frequency: ${med.frequency}`,
        `Remarks: ${med.remarkds ?? "NA"}`
      );
      return medsInLink.join(",%20");
    });
    const medicines = meds.join("%0a");

    const vitalsInLink = selectedVitals.map((vit) => {
      const temp = [];
      temp.push(`Name: ${vit.label}`, `Value: ${vit.val}`);
      return temp.join(",%20");
    });
    const vitals = vitalsInLink.join("%0a");

    const coMorbiditiesInLink = selectedCoMorbidities.map((cor) => {
      const temp = [];
      temp.push(`Name: ${cor.label}`, `value: ${cor.val}`);
      return temp.join(",%20");
    });
    const coMorbidities = coMorbiditiesInLink.join("%0a");

    const URL_PARAM = `?phone=${mobile}&text=Personal%20Information%0a%0aName:%20${name}%0aDOB:%20${getFormattedDate(
      dob,
      "DD-MM-YYYY"
    )}%0aGender:%20${gender}%0aMobile%20Number:%20${mobile}%0aEmail: ${email}%0aUHID:%20${uhid}%0aAddress:%20${address}%0a%0aPrescription%0aProv:%20${prov}%0aChief%20Complaints:%20${chiefComplaints}%0aHOPI:%20${hopi}%0aVitals:%20${vitals}%0aRemarks:%20${remarks}%0aCo-morbidities:%20${coMorbidities}%0aExamination:%20${examination}%0aMedications:%0a${medicines}%0aSurgical%20Plan:%20${surgical}%0aRestrictions%20and%20Instructions:%20${restrictions}%0aInvestigation:%20${investigation}%0aFollow%20up:%20${followUp}%0aNotes:%20${note}%0a%0aReferring Doctor:%20${referDoc}%0a​Mobile%20Number:%20${referMob}%0a​Email:%20${referEmail}&app_absent=0`;

    window.open(`${AppConstants.WHATSAPP_URL}${URL_PARAM}`, "_blank");
  }, [appointment, medications, selectedVitals, selectedCoMorbidities]);

  const handleSetDetailedConsult = useCallback(() => {
    setSubmitButton(2);
    setIsDetailedConsultRequired(true);
  }, []);

  // ---------------------//
  // ----------------------//
  const [AllAppoinmentType, setAllAppoinmentType] = useState();
  const [patientState, setPatientState] = useState(null);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_patient_appointment_stats?patientSlug=${allAppointDetails?.patientSlug}`
    ).then((resp) => {
      if (resp) {
        setPatientState(resp.data.data);
      }
    });
  }, [allAppointDetails]);

  useEffect(() => {}, [
    allAppointDetails,
    prevPrescrptn,
    selectedPrescriptionOption,
    appointment,
  ]);

  const [getwebsite, setGetWebsite] = useState();

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getwebsite`).then((resp1) => {
      if (resp1) {
        setGetWebsite(resp1.data[0].admin_url);
      }
    });
  }, [allAppointDetails, slug]);

  // const [printLetterHeadDate, setprintLetterHeadDate] = useState(format(new Date(), 'yyyy-MM-dd'));

  const handlePrintingLetterDate = (timeString) => {
    setprintLetterHeadDate(timeString);
  };

  const handleImageClick = (val) => {
    let data = val;
    let w = window.open("about:blank");
    let image = new Image();
    image.src = data;
    setTimeout(function () {
      w.document.write(image.outerHTML);
    }, 0);
  };

  //V2 Start from here

  useEffect(() => {
    Api.get(`emr-template/get_emr_template_type`).subscribe((resp) => {
      if (resp) {
        let emrTempTypes = resp?.data?.data;
        setSepcilistData(emrTempTypes);

        if (emrTempTypes && emrTempTypes.length > 0) {
          emrTempTypes.forEach(function (item, index, arr) {
            if (item.default === true) {
              setSelectedDefaults(item);

              // setSelectedEmr(item)
              if (!emrTemplateID?.length > 0) {
                setSelectedEmrOptions(item);
              }

              // setSelectedEmr(selectedDefaults);
              setSelectedEmr(item);
              if (admitType.current) {
                fetchAdmitDetails(item);
              }
            }
          });
        }
      }
    });
  }, []);

  const fetchAdmitDetails = (selectedEmrTypeInput) => {
    Api.get(`admit/id/${newId}`).subscribe((resp) => {
      setMedications(resp?.data?.data?.medications);
      setPatientslug1(resp?.data?.data?.patientSlug);

      Api.get(
        `appointment/qr-code-new/${
          resp?.data?.data?.appointmentSlug
        }?emrTemplateTypeId=${
          selectedEmrTypeInput?._id || selectedDefaults?._id
        }`
      ).subscribe(async (resp2) => {
        setSelectedPrevPres(resp2?.data?.data?.prevPrescriptions || []);
      });
    });
  };

  const handleChangeEmrType = (event, value) => {
    setSelectedEmrOptions(value);

    setSelectedEmr(value);
  };

  const handleChangeEmrTemplate = (event, value) => {
    setProv(value?.template?.prov);
    setChiefComplaints(value?.template?.chiefComplaints);
    setHopi(value?.template?.hopi);
    setExamination(value?.template?.examination);
    setTypeEmrTemplate(value);
    setEmrObject(value?.template);
    if (!admitType.current) {
      setMedications(value?.template?.medications);
      // setUploadedDiagram(value?.template?.diagrams);
    }
  };

  const handledatechange = (date, field) => {
    // setDate(date)
    date.set({ h: 0, m: 0 });

    setEmrObject((prevFormData) => ({
      ...prevFormData,
      [field]: date.format("YYYY-MM-DD"),
    }));
  };

  const handleAdmitPatient = () => {
    if (selectedEmrType) {
      setSubmitButton(3);
    } else {
      SHOW_ERROR_NOTIFICATION("EMR type is required");
      setSubmitButton(null);
      return;
    }
  };

  return (
    <>
      <Box className={globalClass.container} minHeight="90vh" mx={4}>
        {slug !== null || newId !== null ? (
          <>
            <AppBar
              className={classes.appbar}
              color="default"
              position="static"
              elevation={0}
            >
              <Toolbar>
                <Hidden lgUp>
                  <IconButton
                    color="inherit"
                    onClick={sidebarContext.handleSidebarOpen}
                  >
                    <MenuIcon />
                  </IconButton>
                </Hidden>

                <Box
                  flexGrow="1"
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent={isMobile ? "center" : "space-between"}
                  alignItems={isMobile ? "center" : "flex-start"}
                  mb="1.5rem"
                >
                  <Typography variant="h5" className={classes.title}>
                    Prescription
                  </Typography>
                  <Box display="flex" alignItems="center">
                    {isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                    isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                    isCurrentRole(AppConstants.ROLE.ADMIN) ? (
                      <Box
                        display="inline-block"
                        style={{
                          fontSize: 12,
                          marginRight: 12,
                          whiteSpace: "nowrap",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {saved ? (
                          <Box>
                            <LoopIcon /> Saving...
                          </Box>
                        ) : (
                          "Saved a few seconds ago"
                        )}
                      </Box>
                    ) : null}
                  </Box>
                  {isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                  isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                  isCurrentRole(AppConstants.ROLE.ADMIN) ? (
                    <Box my={6} display="flex" justifyContent="center">
                      <Button
                        onClick={onClearAllBtnClicked}
                        style={{ marginRight: 12, padding: 8 }}
                        variant="outlined"
                      >
                        Clear All
                      </Button>
                    </Box>
                  ) : null}

                  <Box
                    style={{
                      display: isMobile ? "flex" : "",
                      marginBottom: isMobile ? "20px" : "",
                      justifyContent: isMobile ? "center" : "",
                      alignItems: isMobile ? "center" : "",
                    }}
                  >
                    {isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                    isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                    isCurrentRole(AppConstants.ROLE.ADMIN) ? (
                      <Box display="inline-block" style={{ marginRight: 12 }}>
                        <Box>
                          <Autocomplete
                            style={{ width: isMobile ? "140px" : "200px" }}
                            id="my-autocomplete"
                            options={specialistData ? specialistData : []}
                            getOptionLabel={(option) => option.name}
                            onChange={handleChangeEmrType}
                            className={globalClass.roundWhiteBg}
                            // value={selectedEmrType?.name ?? selectedDefaults?.name}
                            value={selectedEmrOptions}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"Select EMR Type"}
                                variant="outlined"
                              />
                            )}
                            disablePortal={false}
                          />
                        </Box>
                      </Box>
                    ) : null}

                    {isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                    isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                    isCurrentRole(AppConstants.ROLE.ADMIN) ? (
                      <Box display="inline-block" style={{ marginRight: 12 }}>
                        {emrTemplates !== undefined &&
                        emrTemplates.length > 0 ? (
                          <Autocomplete
                            style={{ width: isMobile ? "140px" : "200px" }}
                            id="emr-template-dropdown"
                            options={emrTemplates ? emrTemplates : []}
                            getOptionLabel={(option) => option?.name}
                            className={globalClass.roundWhiteBg}
                            onChange={handleChangeEmrTemplate}
                            value={typEmrTemplate}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={"EMR Template"}
                                variant="outlined"
                              />
                            )}
                            disablePortal={false}
                          />
                        ) : (
                          ""
                        )}
                      </Box>
                    ) : null}
                  </Box>

                  {isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                  isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                  isCurrentRole(AppConstants.ROLE.ADMIN) ? (
                    <Box display="inline-block" style={{ marginRight: 12 }}>
                      {selectedPrevPres !== undefined &&
                      selectedPrevPres.length > 0 ? (
                        <Autocomplete
                          style={{ width: isMobile ? "300px" : "" }}
                          value={selectedPrescriptionOption || ""}
                          // required
                          // ref={prevPrescreptionRef}
                          className={globalClass.roundWhiteBg}
                          id="prev-prescription-dropdown"
                          placeholder="Old Prescriptions"
                          options={selectedPrevPres ? selectedPrevPres : []}
                          onChange={onPrevPrescriptionSelect}
                          disablePortal={false}
                          // disabled={!selectedPrevPres}
                          // getOptionLabel={(option) => option.label.toString()}
                          // getOptionLabel={(option) => {

                          // }}
                          // disabled={false}
                          // disabled={(selectedPrevPres === null || selectedPrevPres === undefined) ? true : false}
                        />
                      ) : (
                        ""
                      )}
                    </Box>
                  ) : null}
                </Box>
              </Toolbar>
            </AppBar>
          </>
        ) : (
          ""
        )}
        {slug !== null || newId !== null ? (
          <>
            <Box>
              <Grid container spacing={8}>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <p
                      style={{
                        border: isMobile ? "1px solid #ebebeb" : "",
                        width: isMobile ? "100%" : "",
                        backgroundColor: "white",
                        color: "black",
                        fontWeight: "700",
                        padding: "10px 4px",
                        display: isdesktop ? "" : "none",
                      }}
                      onClick={handleformclick}
                      variant="contained"
                    >
                      Patient Information
                    </p>
                    <div
                      style={{
                        position: isMobile ? "relative" : "",
                        right: isMobile ? "27px" : "",
                        bottom: isMobile ? "8px" : "",
                      }}
                    >
                      {" "}
                      <KeyboardArrowDownIcon
                        style={{
                          fontSize: "30px",
                          color: "rgb(12, 99, 232)",
                          display: isdesktop ? "" : "none",
                        }}
                      />
                    </div>
                  </div>
                  {isformopen && (
                    <Box
                      flexGrow={1}
                      // display={{ xs: "none", sm: "block" }}
                      className={clsx(
                        classes["prescription-details"],
                        globalClass.roundWhiteBg
                      )}
                    >
                      {appointment?.patient?.profilePic ? (
                        <Box>
                          <img
                            src={appointment?.patient?.profilePic}
                            style={{ width: "100px", borderRadius: "50%" }}
                            alt="Profile Pic"
                          />
                        </Box>
                      ) : (
                        <Box>
                          <img
                            src="/profilePic.png"
                            style={{ width: "100px", borderRadius: "50%" }}
                            alt="Profile Pic"
                          />
                        </Box>
                      )}

                      <br />
                      <Typography style={{ flex: 1 }}>
                        {" "}
                        <span className={classes.prescriptionTitle}>
                          Appointment Date & Time :{" "}
                        </span>{" "}
                        {allAppointDetails?.startDate} ,{" "}
                        {allAppointDetails?.startTime}
                      </Typography>
                      <br />

                      <Form
                        apiRef={patientFormApi}
                        onSubmit={handlePatientFormSubmit}
                        // onKeyUp={handlePatientsPersonalDetailsKeyUp}
                      >
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                marginBottom: "2rem",
                                width: "100%",
                              }}
                            >
                              <Typography style={{ flex: 1 }}>
                                {" "}
                                <span className={classes.prescriptionTitle}>
                                  Type:{" "}
                                </span>{" "}
                                {allAppointDetails?.type}
                              </Typography>
                              <Typography style={{ flex: 1 }}>
                                {" "}
                                <span className={classes.prescriptionTitle}>
                                  {" "}
                                  Cancellations :
                                </span>{" "}
                                {patientState?.cancelled_count}
                              </Typography>
                              <Typography style={{ flex: 1 }}>
                                {" "}
                                <span className={classes.prescriptionTitle}>
                                  {" "}
                                  No Show :{" "}
                                </span>{" "}
                                {patientState?.no_show_count}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>

                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              label="Name"
                              field="name"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <DatePicker
                              containerClassName={"mr"}
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              openTo="year"
                              inputVariant="outlined"
                              field="dob"
                              placeholder=" DOB"
                              validateOnBlur
                              validateOnChange
                              value={patientDOB}
                              onChange={(e) => setPatientDOB(e)}
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <SelectInput
                              required
                              containerClassName={"mr"}
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              options={gender}
                              field="gender"
                              placeholder="Gender"
                              label="Gender"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              disabled
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              label="Mobile Number"
                              field="mobileNumber"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              label="Email"
                              field="email"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              label="UHID"
                              field="uhid"
                              disabled
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              label="Last Visit"
                              field="lastVisit"
                              disabled
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              style={{ width: "100%" }}
                              className={globalClass.roundWhiteBg}
                              label="Address"
                              field="address"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          {!admitType.current ? (
                            <>
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                className={`${classes["prescription-details"]}__column`}
                              >
                                <TextInput
                                  style={{ width: "100%" }}
                                  className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                                  label="Creator role"
                                  field="creatorRole"
                                  validateOnBlur
                                  // value={appointment && (appointment.creatorRole || "")}
                                  disabled
                                  validateOnChange
                                />
                              </Grid>

                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={6}
                                className={`${classes["prescription-details"]}__column`}
                              >
                                <TextInput
                                  style={{ width: "100%" }}
                                  className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                                  label="Creator Name"
                                  field="creatorName"
                                  validateOnBlur
                                  // value={appointment && (appointment.creatorName || "")}
                                  disabled
                                  validateOnChange
                                />
                              </Grid>
                            </>
                          ) : (
                            ""
                          )}

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              label="Occupation"
                              field="occupation"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              label="Height in cm"
                              field="height"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              label="Weight in kg"
                              field="weight"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              label="BMI"
                              field="bmi"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <SelectInput
                              required
                              containerClassName={"mr"}
                              className={`${globalClass.roundWhiteBg} ${globalClass.prescriptionUserDetailsInput}`}
                              options={["Married", "Unmarried"]}
                              field="maritalStatus"
                              placeholder="Marital Status"
                              label="Marital Status"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={`${classes["prescription-details"]}__column`}
                          >
                            <TextInput
                              style={{ width: "100%" }}
                              className={globalClass.roundWhiteBg}
                              label="Allergies"
                              field="allergies"
                              validateOnBlur
                              validateOnChange
                            />
                          </Grid>
                        </Grid>

                        <Box my="1rem">
                          {saved ? (
                            <Button
                              variant="contained"
                              color="primary"
                              disabled
                            >
                              <CircularProgress size={20} />
                              Loading
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="primary"
                              type="submit"
                            >
                              Update
                            </Button>
                          )}
                        </Box>
                      </Form>
                    </Box>
                  )}
                </Grid>

                {isAuthorised(
                  AppConstants.ENTITY.CONSULTATION,
                  AppConstants.ACCESS.ANY
                ) ||
                isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                isCurrentRole(AppConstants.ROLE.ADMIN) ||
                isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ? (
                  <>
                    {!admitType.current ? (
                      <>
                        <Grid item xs={12} sm={8} md={6}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: isMobile ? "100%" : "" }}>
                                  <p
                                    style={{
                                      border: isMobile
                                        ? "1px solid #ebebeb"
                                        : "",
                                      backgroundColor: "white",
                                      color: "black",
                                      fontWeight: "700",
                                      padding: "10px 4px",
                                      display: isdesktop ? "" : "none",
                                    }}
                                    onClick={handleqrClick}
                                    variant="contained"
                                  >
                                    qr code
                                  </p>
                                </div>

                                <div
                                  style={{
                                    position: isMobile ? "relative" : "",
                                    right: isMobile ? "27px" : "",
                                    bottom: isMobile ? "8px" : "",
                                  }}
                                >
                                  {" "}
                                  <KeyboardArrowDownIcon
                                    style={{
                                      fontSize: "30px",
                                      color: "rgb(12, 99, 232)",
                                      display: isdesktop ? "" : "none",
                                    }}
                                  />
                                </div>
                              </div>
                              {isqropen && selectedEmrOptions !== null && (
                                <Box
                                  // display={{ xs: "none", sm: "block" }}
                                  className={clsx(
                                    classes["prescription-qr"],
                                    globalClass.roundWhiteBg
                                  )}
                                >
                                  <Box>
                                    <Typography
                                      variant="h6"
                                      className={classes.prescriptionTitle}
                                    >
                                      Scan to upload prescription{" "}
                                    </Typography>
                                    <div
                                      style={{
                                        height: "auto",
                                        margin: "0 auto",
                                        width: "200px",
                                      }}
                                    >
                                      <QRCode
                                        style={{
                                          height: "auto",
                                          maxWidth: "100%",
                                          width: "100%",
                                        }}
                                        value={`${getwebsite}/#!/admin${Routes.PRESCRIPTION}?slug=${slug}&token=${token}`}
                                        size={200}
                                        includeMargin={true}
                                      />
                                    </div>
                                  </Box>

                                  <Box>
                                    <Box mb="1rem">
                                      <Typography variant="h6">OR </Typography>
                                    </Box>
                                    <input
                                      ref={presFileRef}
                                      accept="image/*"
                                      className={classes.input}
                                      id="contained-button-filey"
                                      type="file"
                                      multiple
                                      onChange={(e) =>
                                        handleChange("prescription", e)
                                      }
                                    />
                                    <label htmlFor="contained-button-filey">
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        component="span"
                                      >
                                        Click to Upload Prescription
                                      </Button>
                                    </label>
                                  </Box>
                                </Box>
                              )}
                            </Grid>

                            {/* patient chat */}
                            {isPrimaryProfile && (isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                              isCurrentRole(AppConstants.ROLE.DOCTORADMIN)) && 
                              <Grid>
                              <Grid item xs={12} sm={6}>
                                <div
                                  style={{
                                    border: isMobile ? "1px solid #ebebeb" : "",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: isMobile ? "84vw" : "",
                                    backgroundColor: "white",
                                    marginTop: isMobile ? "11px" : "",
                                    // marginRight: isdesktop ? "40px" : ""
                                  }}
                                  onClick={handlechatbtnclick}
                                >
                                  <div
                                    style={{
                                      flex: 1,
                                      width: isMobile ? "84vw" : "",
                                      position: isMobile ? "relative" : "",
                                      paddingLeft: isMobile ? "8px" : "",
                                      paddingTop: isMobile ? "9px" : "",
                                      marginRight: isdesktop ? "40px" : "",
                                    }}
                                  >
                                    <p
                                      style={{
                                        color: "black",
                                        fontWeight: "700",
                                        display: isdesktop ? "" : "none",
                                      }}
                                    >
                                      {" "}
                                      chat{" "}
                                    </p>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      position: isMobile ? "relative" : "",
                                      left: isMobile ? "5px" : "",
                                      top: isMobile ? "0px" : "",
                                    }}
                                  >
                                    <KeyboardArrowDownIcon
                                      style={{
                                        fontSize: "30px",
                                        color: "rgb(12, 99, 232)",
                                        display: isdesktop ? "" : "none",
                                      }}
                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Grid>
                                <Button
                                  variant="contained"
                                  onClick={() => setLoadChat(true)}
                                >
                                  Start Chat
                                </Button>
                                {ischatopen && loadChat ? (
                                  <PatientChat
                                    appointment={appointment}
                                    patientSlug={appointment?.patientSlug}
                                    docSlug={user.slug}
                                  />
                                ) : (
                                  ""
                                )}
                              </Grid>
                            </Grid>}

                            {/* prescription chat */}
                              {(isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                                isCurrentRole(AppConstants.ROLE.DOCTORADMIN)) && <Grid container>
                                  <Grid item xs={12}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <p
                                        style={{
                                          border: isMobile
                                            ? "1px solid #ebebeb"
                                            : "",
                                          width: isMobile ? "100%" : "100%",
                                          backgroundColor: "white",
                                          color: "black",
                                          fontWeight: "700",
                                          padding: "10px 4px",
                                          display: isdesktop ? "" : "none",
                                          margin: isMobile ? "10px 0" : "",
                                        }}
                                        variant="contained"
                                        onClick={handlePatientSummaryClick}
                                      >
                                        Patient Summary
                                      </p>

                                      <div
                                        style={{
                                          position: isMobile ? "relative" : "",
                                          right: isMobile ? "27px" : "",
                                          bottom: isMobile ? "0px" : "",
                                        }}
                                      >
                                        {" "}
                                        <KeyboardArrowDownIcon
                                          style={{
                                            fontSize: "30px",
                                            color: "rgb(12, 99, 232)",
                                            display: isdesktop ? "" : "none",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </Grid>

                                  {isPatientSummaryOpen && (
                                    <Grid item xs={12}>
                                      <Box
                                        className={clsx(
                                          classes["prescription-qr"],
                                          globalClass.roundWhiteBg
                                        )}
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => setshowPatientSummary(true)}
                                      >
                                        {/* <Grid container alignItems="center"> */}
                                        <Grid container spacing={5}>
                                          <Grid item xs={12} sm={6}>
                                            <h5
                                              style={{
                                                fontWeight: "bold",
                                                textAlign: "left",
                                              }}
                                            >
                                              Patients Summary
                                            </h5>
                                          </Grid>
                                          <Grid item xs={12} sm={6}>
                                            {showPatientSummary ? (
                                              <i
                                                class="fa-solid fa-chevron-down"
                                                style={{ float: "right" }}
                                              ></i>
                                            ) : (
                                              <i
                                                style={{ float: "right" }}
                                                class="fa-solid fa-chevron-up"
                                              ></i>
                                            )}
                                          </Grid>
                                        </Grid>
                                        {showPatientSummary ? (
                                          <Prescriptionchat
                                            patientSlug={appointment?.patientSlug}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        {/* </Grid> */}
                                      </Box>
                                    </Grid>
                                  )}
                                </Grid>}
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                  </>
                ) : null}
              </Grid>

              <div className="row">
                <Form
                  className="col-md-12"
                  apiRef={formApi}
                  onSubmit={handleSubmit}
                  onKeyUp={handlePatientsPrescriptionKeyUp}
                >
                  {(slug !== null || newId !== null) && (
                    <Box>
                      {isAuthorised(
                        AppConstants.ENTITY.CONSULTATION,
                        AppConstants.ACCESS.ANY
                      ) ||
                      isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                      isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                      isCurrentRole(AppConstants.ROLE.ADMIN) ? (
                        <>
                          {selectedEmrType?.fields?.map((items, index) => {
                            return (
                              <>
                                {items.type === "text" ? (
                                  <>
                                    {/* todo */}
                                    <Box
                                      className={classes.prescriptionSection}
                                    >
                                      <Box
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Typography
                                          variant="h6"
                                          className={classes.prescriptionTitle}
                                        >
                                          {items.name}
                                        </Typography>

                                        {/* {prevPrescrptn && ( */}
                                        <Box
                                          mb="1rem"
                                          className={
                                            classes.restrictionControlBox
                                          }
                                        >
                                          <Button
                                            onClick={
                                              () =>
                                                handleClearObjectValues(
                                                  items.field
                                                )
                                              // handleClearField(setProv)
                                            }
                                            style={{ marginRight: "10px" }}
                                            variant="outlined"
                                          >
                                            Clear
                                          </Button>
                                          <Box mt="1rem" mr="1rem">
                                            <Typography
                                              variant="subtitle1"
                                              className={
                                                classes.prescriptionTitle
                                              }
                                            >
                                              Copy from
                                            </Typography>
                                          </Box>

                                          <div
                                            style={{
                                              display: isMobile ? "none" : "",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: isdesktop
                                                  ? ""
                                                  : "flex",
                                              }}
                                            >
                                              <Autocomplete
                                                value={
                                                  individualTemplateOption[
                                                    items.field
                                                  ]
                                                }
                                                //value={selectedOption[items.field]}
                                                //required
                                                className={
                                                  globalClass.roundWhiteBg
                                                }
                                                id="emr-template-dropdown"
                                                placeholder="EMR Template"
                                                options={options}
                                                field="specialitySlug"
                                                onChange={(event, options) => {
                                                  /* setSelectedOption({ ...selectedOption, [items.field]: options }); */
                                                  handleIndividualTemplateChange(
                                                    event,
                                                    options,
                                                    items.field
                                                  );
                                                }}
                                              />
                                              &nbsp;
                                              <Autocomplete
                                                value={
                                                  individualPrevPrisc[
                                                    items?.field
                                                  ]
                                                }
                                                // value={prescriptionOption[items.field]}
                                                // required
                                                // ref={prevPrescreptionRef}
                                                className={
                                                  globalClass.roundWhiteBg
                                                }
                                                id="prev-prescription-dropdown"
                                                placeholder="Old Prescriptions"
                                                options={
                                                  selectedPrevPres
                                                    ? selectedPrevPres
                                                    : []
                                                }
                                                onChange={(e, options) => {
                                                  onIndividualPrescriptionSelect(
                                                    e,
                                                    options,
                                                    items?.field
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Box>
                                      </Box>

                                      <Box
                                        style={{
                                          display: isdesktop ? "" : "none",
                                        }}
                                      >
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: isMobile
                                              ? "20px"
                                              : "",
                                          }}
                                        >
                                          <Autocomplete
                                            value={
                                              individualTemplateOption[
                                                items.field
                                              ]
                                            }
                                            //value={selectedOption[items.field]}
                                            //required
                                            className={globalClass.roundWhiteBg}
                                            id="emr-template-dropdown"
                                            placeholder="EMR Template"
                                            options={options}
                                            field="specialitySlug"
                                            onChange={(event, options) => {
                                              /* setSelectedOption({ ...selectedOption, [items.field]: options }); */
                                              handleIndividualTemplateChange(
                                                event,
                                                options,
                                                items.field
                                              );
                                            }}
                                          />
                                          &nbsp;
                                          <Autocomplete
                                            value={
                                              individualPrevPrisc[items?.field]
                                            }
                                            // value={prescriptionOption[items.field]}
                                            // required
                                            // ref={prevPrescreptionRef}
                                            className={globalClass.roundWhiteBg}
                                            id="prev-prescription-dropdown"
                                            placeholder="Old Prescriptions"
                                            options={
                                              selectedPrevPres
                                                ? selectedPrevPres
                                                : []
                                            }
                                            onChange={(e, options) => {
                                              onIndividualPrescriptionSelect(
                                                e,
                                                options,
                                                items?.field
                                              );
                                            }}
                                          />
                                        </Box>
                                      </Box>

                                      <Paper
                                        className={clsx(
                                          classes.container,
                                          globalClass.roundWhiteBg
                                        )}
                                      >
                                        <TextAreaCustom
                                          field={items.field}
                                          placeholder={items.placeholder}
                                          name={items.field}
                                          value={
                                            (emrObject &&
                                              emrObject[`${items.field}`]) ||
                                            selectedOption[items.field] ||
                                            ""
                                          }
                                          onChange={(event) =>
                                            handleTextChange(event, items.field)
                                          }
                                        />
                                      </Paper>
                                    </Box>
                                  </>
                                ) : null}

                                {items.type === "vitals"
                                  ? isAuthorised(
                                      AppConstants.ENTITY.VITALS_NOTES
                                    ) && (
                                      <Box
                                        className={classes.prescriptionSection}
                                      >
                                        <Box
                                          mt="2rem"
                                          mb="1rem"
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Typography
                                            style={{ marginBottom: "0px" }}
                                            variant="h6"
                                            className={
                                              classes.prescriptionTitle
                                            }
                                          >
                                            {items.name}
                                          </Typography>

                                          <div className="d-flex">
                                            <Box
                                              display="flex"
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              {/* {prevPrescrptn && ( */}
                                              <Box mr="2rem" mt="0.5rem">
                                                <Button
                                                  onClick={() =>
                                                    onClearBtnClicked("vitals")
                                                  }
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                  variant="outlined"
                                                >
                                                  Clear
                                                </Button>
                                              </Box>

                                              <Box mt="1rem" mr="1rem">
                                                <Typography
                                                  variant="subtitle1"
                                                  className={
                                                    classes.prescriptionTitle
                                                  }
                                                >
                                                  Copy from
                                                </Typography>
                                              </Box>
                                              {/* <Autocomplete
                                          value={selectedMedicationOption}
                                          required
                                          className={globalClass.roundWhiteBg}
                                          id="emr-template-dropdown"
                                          placeholder="EMR Template"
                                          options={options}
                                          field="specialitySlug"
                                          onChange={(event, options) => onMedicationTemplateSelected(event, options, items.field)}
                                        />&nbsp; */}
                                            </Box>

                                            <Box
                                              className={
                                                classes.prescriptionSection
                                              }
                                              display="flex"
                                              flexDirection="column"
                                              alignItems="end"
                                            >
                                              <Box
                                                mt="2rem"
                                                mb="1rem"
                                                display="flex"
                                                justifyContent="space-between"
                                                alignItems="center"
                                              >
                                                <Autocomplete
                                                  value={selectedVitalsPrev}
                                                  // value={prescriptionOption[items.field]}
                                                  // required
                                                  // ref={prevPrescreptionRef}
                                                  className={
                                                    globalClass.roundWhiteBg
                                                  }
                                                  id="prev-prescription-dropdown"
                                                  placeholder="Old Prescriptions"
                                                  options={
                                                    selectedPrevPres
                                                      ? selectedPrevPres
                                                      : []
                                                  }
                                                  onChange={(e, options) => {
                                                    onVitalsPrevPriscSelected(
                                                      e,
                                                      options,
                                                      items?.field
                                                    );
                                                  }}
                                                />

                                                {((prescription &&
                                                  prescription.vitals) ||
                                                  selectedVitals.length >
                                                    0) && (
                                                  <FormControlLabel
                                                    className="ms-1"
                                                    control={
                                                      <Switch
                                                        checked={vitalEditMode}
                                                        onChange={() => {
                                                          onToggleVitalEditMode(
                                                            "vitals"
                                                          );
                                                        }}
                                                        color="primary"
                                                      />
                                                    }
                                                    label="Edit"
                                                  />
                                                )}
                                              </Box>
                                            </Box>
                                          </div>
                                        </Box>

                                        {(prescription &&
                                          prescription.vitals) ||
                                        selectedVitals.length > 0 ? (
                                          selectedVitals.length > 0 ? (
                                            <Scope scope="vitals">
                                              <Grid container>
                                                {selectedVitals.map(
                                                  (vital, index) => (
                                                    <Grid
                                                      item
                                                      sm={4}
                                                      key={index}
                                                      className={`${classes.vitals}__column`}
                                                    >
                                                      <Box
                                                        className={
                                                          classes.vitalSign
                                                        }
                                                      >
                                                        <TextInput
                                                          className={
                                                            globalClass.roundWhiteBg
                                                          }
                                                          label={vital.label}
                                                          field={vital.label}
                                                          value={vital.val}
                                                          onChange={(e) =>
                                                            handleChangeVital(
                                                              e.target.value,
                                                              index,
                                                              setSelectedVitals
                                                            )
                                                          }
                                                          validateOnBlur
                                                          validateOnChange
                                                        />
                                                      </Box>
                                                    </Grid>
                                                  )
                                                )}
                                              </Grid>
                                            </Scope>
                                          ) : (
                                            <Paper
                                              className={clsx(
                                                classes.container,
                                                globalClass.roundWhiteBg
                                              )}
                                            >
                                              <Box mb={5}>
                                                <Typography
                                                  className={
                                                    globalClass.greyFont
                                                  }
                                                  variant="body1"
                                                >
                                                  No Vitals are selected, edit
                                                  the vitals settings and mark
                                                  vitals of your choice checked
                                                  from the dialog box. To access
                                                  the Vital Settings toggle the
                                                  edit mode of this section from
                                                  toggle button located at the
                                                  top right of this section.
                                                </Typography>
                                              </Box>
                                            </Paper>
                                          )
                                        ) : (
                                          <Paper
                                            className={clsx(
                                              classes.container,
                                              globalClass.roundWhiteBg
                                            )}
                                          >
                                            <Box mr={1} display="inline-block">
                                              <Typography
                                                className={globalClass.greyFont}
                                                variant="body1"
                                              >
                                                No Vitals Available
                                              </Typography>
                                            </Box>

                                            <IconButton
                                              color="primary"
                                              aria-label="add vital"
                                              size="small"
                                              onClick={() =>
                                                onToggleVitalEditMode("vitals")
                                              }
                                            >
                                              <AddCircleIcon fontSize="large" />
                                            </IconButton>
                                          </Paper>
                                        )}

                                        <Box my={8}>
                                          <Paper
                                            className={clsx(
                                              classes.container,
                                              globalClass.roundWhiteBg
                                            )}
                                          >
                                            <TextAreaCustom
                                              field="remarks"
                                              placeholder="Remarks...."
                                              name={items.field}
                                              value={remarks}
                                              onChange={(e) =>
                                                setRemarks(e.target.value)
                                              }
                                            />
                                          </Paper>
                                        </Box>
                                      </Box>
                                    )
                                  : null}

                                {items.type === "coMorbidities"
                                  ? isAuthorised(
                                      AppConstants.ENTITY.DOCTOR
                                    ) && (
                                      <Box
                                        className={classes.prescriptionSection}
                                      >
                                        <Box
                                          mt="2rem"
                                          mb="1rem"
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Typography
                                            style={{ marginBottom: "0px" }}
                                            variant="h6"
                                            className={
                                              classes.prescriptionTitle
                                            }
                                          >
                                            {items.name}
                                          </Typography>

                                          <div className="d-flex">
                                            <Box
                                              display="flex"
                                              justifyContent="center"
                                              alignItems="center"
                                            >
                                              {/* {prevPrescrptn && ( */}
                                              <Box mr="2rem" mt="0.5rem">
                                                <Button
                                                  onClick={() =>
                                                    onClearBtnClicked(
                                                      "coMorbidities"
                                                    )
                                                  }
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                  variant="outlined"
                                                >
                                                  Clear
                                                </Button>
                                              </Box>

                                              <Box mt="1rem" mr="1rem">
                                                <Typography
                                                  variant="subtitle1"
                                                  className={
                                                    classes.prescriptionTitle
                                                  }
                                                >
                                                  Copy from
                                                </Typography>
                                              </Box>
                                              <div
                                                style={{
                                                  display: isMobile
                                                    ? "none"
                                                    : "",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    display: isdesktop
                                                      ? ""
                                                      : "flex",
                                                  }}
                                                >
                                                  <Autocomplete
                                                    value={selectedCoMorbPrev}
                                                    // value={prescriptionOption[items.field]}
                                                    // required
                                                    // ref={prevPrescreptionRef}
                                                    className={
                                                      globalClass.roundWhiteBg
                                                    }
                                                    id="prev-prescription-dropdown"
                                                    placeholder="Old Prescriptions"
                                                    options={
                                                      selectedPrevPres
                                                        ? selectedPrevPres
                                                        : []
                                                    }
                                                    onChange={(e, options) => {
                                                      onCoMorbidPrevPriscSelected(
                                                        e,
                                                        options,
                                                        items?.field
                                                      );
                                                    }}
                                                  />

                                                  {((prescription &&
                                                    prescription.coMorbidities) ||
                                                    selectedCoMorbidities.length >
                                                      0) && (
                                                    <FormControlLabel
                                                      className="ms-1"
                                                      control={
                                                        <Switch
                                                          checked={comEditMode}
                                                          onChange={() =>
                                                            onToggleVitalEditMode(
                                                              "coMorbidities"
                                                            )
                                                          }
                                                          color="primary"
                                                        />
                                                      }
                                                      label="Edit"
                                                    />
                                                  )}
                                                </div>
                                              </div>
                                            </Box>
                                          </div>
                                        </Box>
                                        <Box
                                          style={{
                                            display: isdesktop ? "" : "none",
                                          }}
                                        >
                                          <Box
                                            style={{
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                              marginBottom: isMobile
                                                ? "20px"
                                                : "",
                                            }}
                                          >
                                            <Autocomplete
                                              value={selectedCoMorbPrev}
                                              // value={prescriptionOption[items.field]}
                                              // required
                                              // ref={prevPrescreptionRef}
                                              className={
                                                globalClass.roundWhiteBg
                                              }
                                              id="prev-prescription-dropdown"
                                              placeholder="Old Prescriptions"
                                              options={
                                                selectedPrevPres
                                                  ? selectedPrevPres
                                                  : []
                                              }
                                              onChange={(e, options) => {
                                                onCoMorbidPrevPriscSelected(
                                                  e,
                                                  options,
                                                  items?.field
                                                );
                                              }}
                                            />

                                            {((prescription &&
                                              prescription.coMorbidities) ||
                                              selectedCoMorbidities.length >
                                                0) && (
                                              <FormControlLabel
                                                className="ms-1"
                                                control={
                                                  <Switch
                                                    checked={comEditMode}
                                                    onChange={() =>
                                                      onToggleVitalEditMode(
                                                        "coMorbidities"
                                                      )
                                                    }
                                                    color="primary"
                                                  />
                                                }
                                                label="Edit"
                                              />
                                            )}
                                          </Box>
                                        </Box>

                                        {(prescription &&
                                          prescription.coMorbidities) ||
                                        selectedCoMorbidities.length > 0 ? (
                                          <Scope scope="coMorbidities">
                                            <Grid container>
                                              {selectedCoMorbidities.map(
                                                (vital, index) => (
                                                  <Grid
                                                    item
                                                    sm={4}
                                                    key={index}
                                                    className={`${classes.vitals}__column`}
                                                  >
                                                    <Box
                                                      className={
                                                        classes.vitalSign
                                                      }
                                                    >
                                                      <TextInput
                                                        className={
                                                          globalClass.roundWhiteBg
                                                        }
                                                        label={vital.label}
                                                        value={vital.val}
                                                        onChange={(e) =>
                                                          handleChangeVital(
                                                            e.target.value,
                                                            index,
                                                            setSelectedCoMorbidities
                                                          )
                                                        }
                                                        field={vital.label}
                                                        validateOnBlur
                                                        validateOnChange
                                                      />
                                                    </Box>
                                                  </Grid>
                                                )
                                              )}
                                            </Grid>
                                          </Scope>
                                        ) : (
                                          <Paper
                                            className={clsx(
                                              classes.container,
                                              globalClass.roundWhiteBg
                                            )}
                                          >
                                            <Box mr={1} display="inline-block">
                                              <Typography
                                                className={globalClass.greyFont}
                                                variant="body1"
                                              >
                                                No Co-morbidities Available
                                              </Typography>
                                            </Box>

                                            <IconButton
                                              color="primary"
                                              aria-label="add vital"
                                              size="small"
                                              onClick={() =>
                                                onToggleVitalEditMode(
                                                  "coMorbidities"
                                                )
                                              }
                                            >
                                              <AddCircleIcon fontSize="large" />
                                            </IconButton>
                                          </Paper>
                                        )}
                                      </Box>
                                    )
                                  : null}

                                {items.type === "medications" ? (
                                  <>
                                    <Box
                                      className={classes.prescriptionSection}
                                    >
                                      <Box
                                        mt="2rem"
                                        mb="1rem"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Typography
                                          style={{ marginBottom: "0px" }}
                                          variant="h6"
                                          className={classes.prescriptionTitle}
                                        >
                                          {items.name}
                                        </Typography>
                                        <div className="d-flex">
                                          <Box
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="center"
                                          >
                                            {/* {prevPrescrptn && ( */}
                                            <Box mr="2rem" mt="0.5rem">
                                              <Button
                                                onClick={() =>
                                                  onClearBtnClicked(
                                                    "medications"
                                                  )
                                                }
                                                style={{ marginRight: "10px" }}
                                                variant="outlined"
                                              >
                                                Clear
                                              </Button>
                                            </Box>

                                            <Box mt="1rem" mr="1rem">
                                              <Typography
                                                variant="subtitle1"
                                                className={
                                                  classes.prescriptionTitle
                                                }
                                              >
                                                Copy from
                                              </Typography>
                                            </Box>
                                          </Box>

                                          <div
                                            style={{
                                              display: isMobile ? "none" : "",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: isdesktop
                                                  ? ""
                                                  : "flex",
                                              }}
                                            >
                                              <Autocomplete
                                                value={selectedMedicationOption}
                                                required
                                                className={
                                                  globalClass.roundWhiteBg
                                                }
                                                id="emr-template-dropdown"
                                                placeholder="EMR Template"
                                                options={options}
                                                field="specialitySlug"
                                                onChange={(event, options) =>
                                                  onMedicationTemplateSelected(
                                                    event,
                                                    options,
                                                    items.field
                                                  )
                                                }
                                              />
                                              &nbsp;
                                              <Autocomplete
                                                value={selectedMedicationPrev}
                                                // value={prescriptionOption[items.field]}
                                                // required
                                                // ref={prevPrescreptionRef}
                                                className={
                                                  globalClass.roundWhiteBg
                                                }
                                                id="prev-prescription-dropdown"
                                                placeholder="Old Prescriptions"
                                                options={
                                                  selectedPrevPres
                                                    ? selectedPrevPres
                                                    : []
                                                }
                                                onChange={(e, options) => {
                                                  onMedicationPrevPriscSelected(
                                                    e,
                                                    options,
                                                    items?.field
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </Box>

                                      <Box
                                        style={{
                                          display: isdesktop ? "" : "none",
                                        }}
                                      >
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: isMobile
                                              ? "20px"
                                              : "",
                                          }}
                                        >
                                          <Autocomplete
                                            value={selectedMedicationOption}
                                            required
                                            className={globalClass.roundWhiteBg}
                                            id="emr-template-dropdown"
                                            placeholder="EMR Template"
                                            options={options}
                                            field="specialitySlug"
                                            onChange={(event, options) =>
                                              onMedicationTemplateSelected(
                                                event,
                                                options,
                                                items.field
                                              )
                                            }
                                          />
                                          &nbsp;
                                          <Autocomplete
                                            value={selectedMedicationPrev}
                                            // value={prescriptionOption[items.field]}
                                            // required
                                            // ref={prevPrescreptionRef}
                                            className={globalClass.roundWhiteBg}
                                            id="prev-prescription-dropdown"
                                            placeholder="Old Prescriptions"
                                            options={
                                              selectedPrevPres
                                                ? selectedPrevPres
                                                : []
                                            }
                                            onChange={(e, options) => {
                                              onMedicationPrevPriscSelected(
                                                e,
                                                options,
                                                items?.field
                                              );
                                            }}
                                          />
                                        </Box>
                                      </Box>

                                      <Paper
                                        className={clsx(
                                          classes.container,
                                          globalClass.roundWhiteBg
                                        )}
                                      >
                                        <Medication
                                          onRemoveMedicationClicked={
                                            onRemoveMedicationClicked
                                          }
                                          medList={medList}
                                          data={medications}
                                          setMedicationData={setMedications}
                                          onMedicationSelected={
                                            onMedicationSelected
                                          }
                                          copyMedicationsInterVal={
                                            copyMedicationsInterVal
                                          }
                                        />
                                      </Paper>
                                    </Box>
                                  </>
                                ) : null}

                                {!admitType.current ? (
                                  <>
                                    {items.type === "followUp" ? (
                                      <>
                                        {isAuthorised(
                                          AppConstants.ENTITY.CONSULTATION,
                                          AppConstants.ACCESS.ANY
                                        ) &&
                                        (isCurrentRole(
                                          AppConstants.ROLE.DOCTOR
                                        ) ||
                                          isCurrentRole(
                                            AppConstants.ROLE.DOCTORADMIN
                                          ) ||
                                          isCurrentRole(
                                            AppConstants.ROLE.ADMIN
                                          )) ? (
                                          <Box
                                            className={
                                              classes.prescriptionSection
                                            }
                                          >
                                            <Box mb={5}>
                                              <Box
                                                display="inline-block"
                                                mr="2rem"
                                              >
                                                <Typography
                                                  variant="h6"
                                                  className={
                                                    classes.prescriptionTitle
                                                  }
                                                >
                                                  {items.name}
                                                </Typography>
                                              </Box>

                                              <FormControlLabel
                                                control={
                                                  <Checkbox
                                                    checked={
                                                      isFollowUpRqrdChecked ||
                                                      false
                                                    }
                                                    onChange={(e) =>
                                                      setIsFollowUpRqrdChecked(
                                                        e.target.checked
                                                      )
                                                    }
                                                    color="primary"
                                                  />
                                                }
                                                label="Not required"
                                              />
                                            </Box>

                                            <Box
                                              display="flex"
                                              flexDirection="column"
                                              className={clsx(
                                                classes.container,
                                                globalClass.roundWhiteBg
                                              )}
                                            >
                                              <Box
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                  flexDirection: isMobile
                                                    ? "column"
                                                    : "",
                                                }}
                                              >
                                                <TextField
                                                  style={{
                                                    width: 100,
                                                    marginRight: "1rem",
                                                  }}
                                                  id="outlined-basic"
                                                  label="Interval"
                                                  variant="outlined"
                                                  value={followUpDurationNumber}
                                                  type="number"
                                                  onWheel={(e) =>
                                                    e.target.blur()
                                                  }
                                                  onChange={
                                                    handleChangeFollowUpDurationNumber
                                                  }
                                                />

                                                <Typography
                                                  variant="h5"
                                                  style={{
                                                    margin: "6%",
                                                    fontWeight: isMobile
                                                      ? "900"
                                                      : "",
                                                  }}
                                                >
                                                  {newDate}
                                                </Typography>

                                                <Box
                                                  style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    flexDirection: isMobile
                                                      ? "column"
                                                      : "",
                                                  }}
                                                >
                                                  {followUpIntervals?.map(
                                                    (interval) => (
                                                      <Box
                                                        className="mb"
                                                        display="inline-block"
                                                        mr={2}
                                                        mt={3}
                                                        mb={3}
                                                        key={interval.id}
                                                      >
                                                        <Button
                                                          onClick={() =>
                                                            selectFollowUpInterval(
                                                              interval.value
                                                            )
                                                          }
                                                          key={interval.id}
                                                          color={
                                                            followUpDurationInterval ===
                                                            interval.value
                                                              ? "primary"
                                                              : "default"
                                                          }
                                                          variant={
                                                            followUpDurationInterval ===
                                                            interval.value
                                                              ? "contained"
                                                              : "outlined"
                                                          }
                                                        >
                                                          {interval.value}
                                                        </Button>
                                                      </Box>
                                                    )
                                                  )}
                                                </Box>
                                                <Box
                                                  display="flex"
                                                  justifyContent="center"
                                                  alignItems="center"
                                                  style={{
                                                    marginRight: "1rem",
                                                    marginLeft: "1rem",
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    control={
                                                      <Checkbox
                                                        onClick={() => {
                                                          setSlots(null);
                                                          setIsNewAppointment(
                                                            !isNewAppointment
                                                          );
                                                        }}
                                                        checked={
                                                          isNewAppointment
                                                        }
                                                        color="primary"
                                                      />
                                                    }
                                                    label="Create Follow Up Appointment"
                                                  />
                                                </Box>
                                                {isNewAppointment && (
                                                  <>
                                                    <SelectInput
                                                      style={{ width: "100%" }}
                                                      containerClassName="mr"
                                                      required
                                                      onChange={(e) => {
                                                        setAppointmentId(
                                                          e.target.value
                                                        );
                                                      }}
                                                      valueKey="_id"
                                                      labelKey="title"
                                                      options={
                                                        AllAppoinmentType
                                                      }
                                                      field="appointmentType"
                                                      placeholder="Type"
                                                      validateOnBlur
                                                      validateOnChange
                                                    />

                                                    <Box
                                                      display="flex"
                                                      justifyContent="center"
                                                      alignItems="center"
                                                    >
                                                      <Button
                                                        type="button"
                                                        onClick={
                                                          onCreateAppoinment
                                                        }
                                                        style={{
                                                          height: "3rem",
                                                        }}
                                                        variant="contained"
                                                        color="primary"
                                                        component="span"
                                                      >
                                                        {loader ? (
                                                          <Loader
                                                            color="secondary"
                                                            height="20px"
                                                            size={20}
                                                          />
                                                        ) : (
                                                          "Create Appointment"
                                                        )}
                                                      </Button>
                                                    </Box>
                                                  </>
                                                )}
                                              </Box>
                                              <Box
                                                display="flex"
                                                flexDirection="column"
                                                style={{
                                                  padding: 10,
                                                }}
                                              >
                                                {isNewAppointment ? (
                                                  isSlotLoading ? (
                                                    <CircularProgress
                                                      disableShrink
                                                      size={20}
                                                    />
                                                  ) : slots ? (
                                                    <>
                                                      <Box mt={3}>
                                                        {slots?.length === 0 ? (
                                                          <p>
                                                            No Slot Available{" "}
                                                          </p>
                                                        ) : (
                                                          slots?.map(
                                                            (item, index) => {
                                                              return (
                                                                <Box
                                                                  className="mb"
                                                                  display="inline-block"
                                                                  mr={2}
                                                                  mt={3}
                                                                  mb={3}
                                                                  key={index}
                                                                >
                                                                  <Button
                                                                    onClick={onSlotSelected.bind(this, item)}
                                                                    color={selectedTime === item.startTimeOnly ? "primary" : "default"}
                                                                    disabled={!item.available}
                                                                    variant={selectedTime === item.startTimeOnly ? "contained" : "outlined"}
                                                                  >
                                                                    {item.startTimeOnly + "-" + item.endTimeOnly}
                                                                  </Button>
                                                                </Box>
                                                              );
                                                            }
                                                          )
                                                        )}
                                                      </Box>
                                                    </>
                                                  ) : (
                                                    <></>
                                                  )
                                                ) : (
                                                  <></>
                                                )}
                                              </Box>
                                            </Box>
                                          </Box>
                                        ) : null}
                                      </>
                                    ) : null}
                                  </>
                                ) : (
                                  <></>
                                )}

                                {items.type === "notes" ? (
                                  <>
                                    {isAuthorised(
                                      AppConstants.ENTITY.CONSULTATION,
                                      AppConstants.ACCESS.ANY
                                    ) && (
                                      <Box
                                        className={classes.prescriptionSection}
                                      >
                                        <Box display="flex">
                                          <Typography
                                            variant="h6"
                                            className={
                                              classes.prescriptionTitle
                                            }
                                          >
                                            {items.name}
                                          </Typography>
                                        </Box>

                                        <Paper
                                          className={clsx(
                                            classes.container,
                                            globalClass.roundWhiteBg
                                          )}
                                        >
                                          <Scope scope="notes[0]">
                                            <TextAreaCustom
                                              initialValue={
                                                prescription &&
                                                prescription.notes &&
                                                prescription.notes.length > 0 &&
                                                prescription.notes?.[tabIndex]
                                                  ?.noteDescription
                                              }
                                              field="noteDescription"
                                              placeholder="Type notes here...."
                                              value={notes}
                                              onChange={(e) =>
                                                setNotes(e.target.value)
                                              }
                                            />
                                          </Scope>
                                        </Paper>
                                      </Box>
                                    )}
                                  </>
                                ) : null}

                                {items.type === "diagrams" ? (
                                  <>
                                    {isAuthorised(
                                      AppConstants.ENTITY.CONSULTATION
                                    ) &&
                                    (isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                                      isCurrentRole(
                                        AppConstants.ROLE.DOCTORADMIN
                                      ) ||
                                      isCurrentRole(
                                        AppConstants.ROLE.ADMIN
                                      )) ? (
                                      <Paper
                                        className={clsx(
                                          classes.container,
                                          globalClass.roundWhiteBg
                                        )}
                                      >
                                        <Box
                                          style={{
                                            display: "flex",
                                            position: "relative",
                                          }}
                                        >
                                          <Box
                                            my="1rem"
                                            style={{ float: "left" }}
                                          >
                                            <Typography
                                              variant="h6"
                                              className={
                                                classes.prescriptionTitle
                                              }
                                              style={{ float: "left" }}
                                            >
                                              {items.name}
                                            </Typography>
                                          </Box>

                                          {uploadedDiagram &&
                                            uploadedDiagram?.length > 0 && (
                                              <Button
                                                style={{
                                                  position: "absolute",
                                                  right: "3%",
                                                }}
                                                onClick={onRemoveImageClicked.bind(
                                                  this,
                                                  "diagram"
                                                )}
                                                // onClick={onRemoveDiagramsClicked}
                                                color="primary"
                                                variant="outlined"
                                              >
                                                Remove Diagrams
                                              </Button>
                                            )}
                                        </Box>

                                        <input
                                          accept="image/*"
                                          className={classes.input}
                                          id="contained-button-file"
                                          type="file"
                                          multiple
                                          onChange={handleChangeDiagram}
                                        />
                                        <label htmlFor="contained-button-file">
                                          <Button
                                            variant="contained"
                                            color="primary"
                                            component="span"
                                          >
                                            {isLoading ? (
                                              <Loader
                                                color="secondary"
                                                height="20px"
                                                size={20}
                                              />
                                            ) : (
                                              "Click to Upload Diagrams"
                                            )}
                                          </Button>
                                        </label>
                                        <Box
                                          display="flex"
                                          flexWrap="wrap"
                                          mr={4}
                                          mt={10}
                                        >
                                          {uploadedDiagram &&
                                            uploadedDiagram?.length > 0 &&
                                            uploadedDiagram?.map(
                                              (item, index) => {
                                                return (
                                                  <img
                                                    key={index}
                                                    alt="diagram"
                                                    width="200"
                                                    src={
                                                      (item?.contenttype
                                                        ?.length > 0 &&
                                                        `data:${item?.contenttype};base64,${item?.data?.data}`) ||
                                                      (item?.data?.data
                                                        ?.length > 0 &&
                                                        item?.data?.data) ||
                                                      (item?.content?.length >
                                                        0 &&
                                                        item?.content)
                                                    }
                                                  />
                                                );
                                              }
                                            )}
                                        </Box>
                                      </Paper>
                                    ) : null}
                                  </>
                                ) : null}

                                {items.type === "dietaryRestriction" ? (
                                  <>
                                    {/* 1 */}
                                    <Box
                                      flexGrow="1"
                                      className={classes.prescriptionSection}
                                    >
                                      <Box
                                        mt="2rem"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Typography
                                          variant="h6"
                                          className={classes.prescriptionTitle}
                                        >
                                          {items.name}
                                        </Typography>
                                        <Box
                                          mb="1rem"
                                          className={
                                            classes.restrictionControlBox
                                          }
                                        >
                                          <Box className={classes.clrBtnBox}>
                                            <Button
                                              onClick={() =>
                                                // handleClearField(setRestrictions)
                                                handleClearObjectValues(
                                                  items.field
                                                )
                                              }
                                              style={{ marginRight: "10px" }}
                                              variant="outlined"
                                            >
                                              Clear
                                            </Button>
                                          </Box>
                                          <Box mt="1rem" mr="1rem">
                                            <Typography
                                              variant="subtitle1"
                                              className={
                                                classes.prescriptionTitle
                                              }
                                            >
                                              Copy from
                                            </Typography>
                                          </Box>

                                          <div
                                            style={{
                                              display: isMobile ? "none" : "",
                                            }}
                                          >
                                            <div
                                              style={{
                                                display: isdesktop
                                                  ? ""
                                                  : "flex",
                                              }}
                                            >
                                              <Autocomplete
                                                value={
                                                  individualTemplateOption[
                                                    items.field
                                                  ]
                                                }
                                                //value={tempRestrictionOption}
                                                //required
                                                className={
                                                  globalClass.roundWhiteBg
                                                }
                                                id="emr-template-dropdown"
                                                placeholder="EMR Template"
                                                options={options}
                                                field="specialitySlug"
                                                onChange={(event, options) =>
                                                  handleIndividualTemplateChange(
                                                    event,
                                                    options,
                                                    items.field
                                                  )
                                                }
                                              />
                                              &nbsp;
                                              <Autocomplete
                                                value={
                                                  individualPrevPrisc[
                                                    items.field
                                                  ]
                                                }
                                                // value={prescriptionOption[items.field]}
                                                // required
                                                // ref={prevPrescreptionRef}
                                                className={
                                                  globalClass.roundWhiteBg
                                                }
                                                id="prev-prescription-dropdown"
                                                placeholder="Old Prescriptions"
                                                options={
                                                  selectedPrevPres
                                                    ? selectedPrevPres
                                                    : []
                                                }
                                                onChange={(e, options) => {
                                                  onIndividualPrescriptionSelect(
                                                    e,
                                                    options,
                                                    items.field
                                                  );
                                                }}
                                              />
                                            </div>
                                          </div>
                                        </Box>
                                      </Box>
                                    </Box>

                                    {/* 2 */}
                                    <Box
                                      style={{
                                        display: isdesktop ? "" : "none",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          marginBottom: isMobile ? "20px" : "",
                                        }}
                                      >
                                        <Autocomplete
                                          value={
                                            individualTemplateOption[
                                              items.field
                                            ]
                                          }
                                          //value={tempRestrictionOption}
                                          //required
                                          className={globalClass.roundWhiteBg}
                                          id="emr-template-dropdown"
                                          placeholder="EMR Template"
                                          options={options}
                                          field="specialitySlug"
                                          onChange={(event, options) =>
                                            handleIndividualTemplateChange(
                                              event,
                                              options,
                                              items.field
                                            )
                                          }
                                        />
                                        &nbsp;
                                        <Autocomplete
                                          value={
                                            individualPrevPrisc[items.field]
                                          }
                                          // value={prescriptionOption[items.field]}
                                          // required
                                          // ref={prevPrescreptionRef}
                                          className={globalClass.roundWhiteBg}
                                          id="prev-prescription-dropdown"
                                          placeholder="Old Prescriptions"
                                          options={
                                            selectedPrevPres
                                              ? selectedPrevPres
                                              : []
                                          }
                                          onChange={(e, options) => {
                                            onIndividualPrescriptionSelect(
                                              e,
                                              options,
                                              items.field
                                            );
                                          }}
                                        />
                                      </Box>
                                    </Box>

                                    <Paper
                                      className={clsx(
                                        classes.container,
                                        globalClass.roundWhiteBg
                                      )}
                                      style={{
                                        width: "100%",
                                        marginBottom: "30px",
                                      }}
                                    >
                                      <TextAreaCustom
                                        field="dietaryRestriction"
                                        placeholder="Type here"
                                        value={
                                          (emrObject &&
                                            emrObject[`${items.field}`]) ||
                                          ""
                                        }
                                        onChange={(event) =>
                                          handleTextChange(event, items.field)
                                        }
                                      />
                                    </Paper>
                                  </>
                                ) : null}

                                {items.type === "surgeryDone" ? (
                                  <>
                                    <Box
                                      className={classes.prescriptionSection}
                                    >
                                      <Box
                                        mt="2rem"
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Typography
                                          variant="h6"
                                          className={classes.prescriptionTitle}
                                        >
                                          {items.name}
                                        </Typography>
                                        <Box
                                          mb="1rem"
                                          className={
                                            classes.restrictionControlBox
                                          }
                                        >
                                          <Box className={classes.clrBtnBox}>
                                            <Button
                                              onClick={() =>
                                                // handleClearField(setRestrictions)
                                                handleClearObjectValues(
                                                  items.field
                                                )
                                              }
                                              style={{ marginRight: "10px" }}
                                              variant="outlined"
                                            >
                                              Clear
                                            </Button>
                                          </Box>
                                          <Box mt="1rem" mr="1rem">
                                            <Typography
                                              variant="subtitle1"
                                              className={
                                                classes.prescriptionTitle
                                              }
                                            >
                                              Copy from
                                            </Typography>
                                          </Box>
                                          <Autocomplete
                                            value={
                                              individualTemplateOption[
                                                items.field
                                              ]
                                            }
                                            //value={tempRestrictionOption}
                                            //required
                                            className={globalClass.roundWhiteBg}
                                            id="emr-template-dropdown"
                                            placeholder="EMR Template"
                                            options={options}
                                            field="specialitySlug"
                                            onChange={(event, options) =>
                                              handleIndividualTemplateChange(
                                                event,
                                                options,
                                                items.field
                                              )
                                            }
                                          />
                                          &nbsp;
                                          <Autocomplete
                                            value={
                                              individualPrevPrisc[items.field]
                                            }
                                            // value={prescriptionOption[items.field]}
                                            // required
                                            // ref={prevPrescreptionRef}
                                            className={globalClass.roundWhiteBg}
                                            id="prev-prescription-dropdown"
                                            placeholder="Old Prescriptions"
                                            options={
                                              selectedPrevPres
                                                ? selectedPrevPres
                                                : []
                                            }
                                            onChange={(e, options) => {
                                              onIndividualPrescriptionSelect(
                                                e,
                                                options,
                                                items.field
                                              );
                                            }}
                                          />
                                        </Box>
                                      </Box>

                                      <MuiPickersUtilsProvider
                                        utils={MomentUtils}
                                      >
                                        <div
                                          className={clsx(
                                            classes.container,
                                            globalClass.roundWhiteBg
                                          )}
                                          style={{ maxWidth: "100%" }}
                                        >
                                          <CustomKeyboardDatePicker
                                            style={{
                                              width: "100%",
                                              borderWidth: "0px",
                                            }}
                                            className={`MuiFormControl-marginNormal m-0`}
                                            inputVariant="standard"
                                            format="DD/MM/YYYY"
                                            label="Date (DD/MM/YYYY)"
                                            value={
                                              emrObject &&
                                              emrObject[`${items.field}`]
                                                ? moment(
                                                    emrObject[`${items.field}`]
                                                  ).format()
                                                : null
                                            }
                                            onChange={(date) =>
                                              handledatechange(
                                                date,
                                                items.field
                                              )
                                            }
                                            InputProps={{
                                              readOnly: true,
                                            }}
                                          />
                                        </div>
                                      </MuiPickersUtilsProvider>
                                    </Box>
                                  </>
                                ) : null}

                                {items.type === "investigation" ? (
                                  <>
                                    <Box
                                      className={classes.prescriptionSection}
                                    >
                                      <Box
                                        flexGrow="1"
                                        mbclassName={
                                          classes.prescriptionSection
                                        }
                                      >
                                        <Box
                                          mt="2rem"
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Typography
                                            variant="h6"
                                            className={
                                              classes.prescriptionTitle
                                            }
                                          >
                                            {items.name}
                                          </Typography>
                                          <Box
                                            mb="1rem"
                                            className={
                                              classes.restrictionControlBox
                                            }
                                          >
                                            <Box className={classes.clrBtnBox}>
                                              <Button
                                                onClick={() =>
                                                  // handleClearField(setRestrictions)
                                                  handleClearObjectValues(
                                                    items.field
                                                  )
                                                }
                                                style={{ marginRight: "10px" }}
                                                variant="outlined"
                                              >
                                                Clear
                                              </Button>
                                            </Box>
                                            <Box mt="1rem" mr="1rem">
                                              <Typography
                                                variant="subtitle1"
                                                className={
                                                  classes.prescriptionTitle
                                                }
                                              >
                                                Copy from
                                              </Typography>
                                            </Box>

                                            <div
                                              style={{
                                                display: isMobile ? "none" : "",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: isdesktop
                                                    ? ""
                                                    : "flex",
                                                }}
                                              >
                                                <Autocomplete
                                                  value={
                                                    individualTemplateOption[
                                                      items.field
                                                    ]
                                                  }
                                                  //value={investigationOption}
                                                  //required
                                                  className={
                                                    globalClass.roundWhiteBg
                                                  }
                                                  id="emr-template-dropdown"
                                                  placeholder="EMR Template"
                                                  options={options}
                                                  field="specialitySlug"
                                                  onChange={(event, options) =>
                                                    handleIndividualTemplateChange(
                                                      event,
                                                      options,
                                                      items.field
                                                    )
                                                  }
                                                />
                                                &nbsp;
                                                <Autocomplete
                                                  value={
                                                    individualPrevPrisc[
                                                      items.field
                                                    ]
                                                  }
                                                  // value={prescriptionOption[items.field]}
                                                  // required
                                                  // ref={prevPrescreptionRef}
                                                  className={
                                                    globalClass.roundWhiteBg
                                                  }
                                                  id="prev-prescription-dropdown"
                                                  placeholder="Old Prescriptions"
                                                  options={
                                                    selectedPrevPres
                                                      ? selectedPrevPres
                                                      : []
                                                  }
                                                  onChange={(e, options) => {
                                                    onIndividualPrescriptionSelect(
                                                      e,
                                                      options,
                                                      items.field
                                                    );
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Box>
                                        </Box>
                                      </Box>

                                      <Box
                                        style={{
                                          display: isdesktop ? "" : "none",
                                        }}
                                      >
                                        <Box
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginBottom: isMobile
                                              ? "20px"
                                              : "",
                                          }}
                                        >
                                          <Autocomplete
                                            value={
                                              individualTemplateOption[
                                                items.field
                                              ]
                                            }
                                            //value={investigationOption}
                                            //required
                                            className={globalClass.roundWhiteBg}
                                            id="emr-template-dropdown"
                                            placeholder="EMR Template"
                                            options={options}
                                            field="specialitySlug"
                                            onChange={(event, options) =>
                                              handleIndividualTemplateChange(
                                                event,
                                                options,
                                                items.field
                                              )
                                            }
                                          />
                                          &nbsp;
                                          <Autocomplete
                                            value={
                                              individualPrevPrisc[items.field]
                                            }
                                            // value={prescriptionOption[items.field]}
                                            // required
                                            // ref={prevPrescreptionRef}
                                            className={globalClass.roundWhiteBg}
                                            id="prev-prescription-dropdown"
                                            placeholder="Old Prescriptions"
                                            options={
                                              selectedPrevPres
                                                ? selectedPrevPres
                                                : []
                                            }
                                            onChange={(e, options) => {
                                              onIndividualPrescriptionSelect(
                                                e,
                                                options,
                                                items.field
                                              );
                                            }}
                                          />
                                        </Box>
                                      </Box>

                                      <Paper
                                        className={clsx(
                                          classes.container,
                                          globalClass.roundWhiteBg
                                        )}
                                      >
                                        {isAuthorised(
                                          AppConstants.ENTITY.APPOINTMENT
                                        ) && (
                                          <TextAreaCustom
                                            field={items.field}
                                            placeholder={items.placeholder}
                                            name={items.field}
                                            value={
                                              (emrObject &&
                                                emrObject[`${items.field}`]) ||
                                              ""
                                            }
                                            onChange={(event) =>
                                              handleTextChange(
                                                event,
                                                items.field
                                              )
                                            }
                                          />
                                        )}
                                      </Paper>
                                      <Box>
                                        <Paper className={classes.containerBox}>
                                          <Typography variant="h6">
                                            Report(s)
                                          </Typography>
                                          {reports.length > 0 ? (
                                            <Box
                                              className={clsx(
                                                classes.presBox,
                                                globalClass.srollBar
                                              )}
                                            >
                                              {reports.map((data) => (
                                                <div
                                                  className={
                                                    classes.reportTitle
                                                  }
                                                  key={data.id}
                                                  onClick={() =>
                                                    handleSelectedReport(data)
                                                  }
                                                  style={{ cursor: "pointer", width: "fit-content" }}
                                                >
                                                  <h6>
                                                    {`${
                                                      data.reportName ?? ""
                                                    } / (${
                                                      getFormattedDate(
                                                        data.reportDate,
                                                        "Do MMMM YYYY"
                                                      ) ?? ""
                                                    })`}
                                                  </h6>
                                                </div>
                                              ))}
                                            </Box>
                                          ) : (
                                            <div className={classes.noReports}>
                                              <Typography variant="h6">
                                                No Reports Found
                                              </Typography>
                                            </div>
                                          )}
                                        </Paper>
                                      </Box>
                                    </Box>
                                  </>
                                ) : null}
                                {items.type === "images" ? (
                                  <>
                                    {isAuthorised(
                                      AppConstants.ENTITY.CONSULTATION
                                    ) &&
                                    (isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                                      isCurrentRole(
                                        AppConstants.ROLE.DOCTORADMIN
                                      ) ||
                                      isCurrentRole(AppConstants.ROLE.ADMIN)) &&
                                    imagesPrecription?.length > 0 ? (
                                      <Box
                                        my={8}
                                        m={1}
                                        className={classes.prescriptionSection}
                                      >
                                        <Box
                                          my="1rem"
                                          display="flex"
                                          justifyContent="space-between"
                                          alignItems="center"
                                        >
                                          <Typography
                                            variant="h6"
                                            className={
                                              classes.prescriptionTitle
                                            }
                                          >
                                            {items.name}
                                          </Typography>

                                          {imagesPrecription &&
                                            imagesPrecription?.length > 0 && (
                                              <Button
                                                onClick={() => {
                                                  onRemoveImageClicked.bind(
                                                    this,
                                                    "prescription"
                                                  );
                                                  // TODO: handle here as well
                                                  setImagePrecription(null)
                                                }}
                                                color="primary"
                                                variant="outlined"
                                              >
                                                Remove Prescription
                                              </Button>
                                            )}
                                        </Box>
                                        <Grid
                                          container
                                          spacing={3}
                                          className={clsx(
                                            classes.container,
                                            globalClass.roundWhiteBg
                                          )}
                                        >
                                          {imagesPrecription?.map(
                                            (img, index) => {
                                              return (
                                                <Grid
                                                  key={index}
                                                  item
                                                  xs={12}
                                                  sm={3}
                                                >
                                                  <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={img}
                                                  >
                                                    <img
                                                      alt="prescription"
                                                      width="200"
                                                      // src={img?.content || img}
                                                      src={img || ""}
                                                      key={index}
                                                    />
                                                  </a>
                                                </Grid>
                                              );
                                            }
                                          )}
                                        </Grid>
                                      </Box>
                                    ) : null}
                                  </>
                                ) : null}
                              </>
                            );
                          })}
                        </>
                      ) : null}
                    </Box>
                  )}

                  {!admitType.current ? (
                    <>
                      {isAuthorised(
                        AppConstants.ENTITY.CONSULTATION,
                        AppConstants.ACCESS.ANY
                      ) && isPrimaryProfile &&
                      (isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                        isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                        isCurrentRole(AppConstants.ROLE.ADMIN)) ? (
                        <Box className={classes.prescriptionSection} my="1rem">
                          <Typography
                            variant="h6"
                            className={classes.prescriptionTitle}
                          >
                            Referred Doctor
                          </Typography>
                          <ReferalDoctor
                            referringOtherEmail={referringOtherEmail}
                            setReferringOtherEmail={setReferringOtherEmail}
                            referringDoc={referringDoc}
                            referringDocNo={referringDocNo}
                            referringDocEmail={referringDocEmail}
                            setReferringDoc={setReferringDoc}
                            setReferringDocNo={setReferringDocNo}
                            setReferringDocEmail={setReferringDocEmail}
                            pateintSlug={allAppointDetails?.patientSlug}
                          />
                        </Box>
                      ) : null}
                    </>
                  ) : (
                    <></>
                  )}

                  {isAuthorised(
                    AppConstants.ENTITY.CONSULTATION,
                    AppConstants.ACCESS.ANY
                  ) && isPrimaryProfile &&
                  (isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                    isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                    isCurrentRole(AppConstants.ROLE.ADMIN)) ? (
                    <>
                      {!admitType.current ? (
                        <>
                          <Box className={classes.prescriptionSection}>
                            <Box display="inline-block" mr="2rem">
                              <Typography
                                variant="h6"
                                className={classes.prescriptionTitle}
                              >
                                Printing Options
                              </Typography>
                            </Box>

                            <FormControlLabel
                              control={
                                <Checkbox
                                  defaultChecked={false}
                                  onClick={handlePrintLetterHeadChange}
                                  checked={printLetterHeadChecked}
                                  color="primary"
                                />
                              }
                              label="Print Letterhead only"
                            />
                            {/* <PrintBox /> */}

                            {(isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                              isCurrentRole(AppConstants.ROLE.DOCTORADMIN)) && (
                              <DatePicker
                                label="Receipt Date (MM/DD/YYYY)"
                                value={printLetterHeadDate}
                                onChange={(newValue) => {
                                  handlePrintingLetterDate(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" />
                                )}
                                className={globalClass.roundWhiteBg}
                              />
                            )}
                          </Box>
                        </>
                      ) : null}
                    </>
                  ) : null}

                  <Box
                    textAlign="center"
                    my={8}
                    style={{
                      display: isMobile ? "grid" : "",
                      gridTemplateColumns: isMobile ? "1fr 1fr" : "",
                    }}
                  >
                    {isPrimaryProfile && <Box
                      ml={4}
                      display={{ sm: "inline-block" }}
                      className={globalClass.largeBtnContainer}
                    >
                      <Button
                        className={globalClass.largeBtnContainer}
                        type="submit"
                        style={{ width: "120px", height: "40px" }}
                        onClick={() => {
                          handleSave();
                        }}
                        variant="contained"
                        color="primary"
                      >
                        Save
                      </Button>
                    </Box>}

                    {!admitType.current ? (
                      <>
                        <Box
                          ml={4}
                          display={{ sm: "inline-block" }}
                          className={globalClass.largeBtnContainer}
                        >
                          <Button
                            className={classes.printBtnContainer}
                            style={{ width: "120px" }}
                            variant="contained"
                            type="submit"
                            color="primary"
                            onClick={() => {
                              setSubmitButton(2);
                            }}
                          >
                            {"Save As Draft"}
                          </Button>
                        </Box>
                        {isPrimaryProfile && <Box
                          ml={4}
                          display={{ sm: "inline-block" }}
                          className={globalClass.largeBtnContainer}
                        >
                          <Button
                            className={classes.printBtnContainer}
                            style={{ width: "120px" }}
                            variant="contained"
                            type="submit"
                            color="primary"
                            onClick={() => {
                              setSubmitButton(1);
                            }}
                          >
                            {"Save And Notify"}
                          </Button>
                        </Box>}
                        {isPrimaryProfile && <Box
                          ml={4}
                          display={{ sm: "inline-block" }}
                          className={globalClass.largeBtnContainer}
                        >
                          <Button
                            className={classes.printBtnContainer}
                            style={{ width: "120px" }}
                            variant="contained"
                            type="submit"
                            color="primary"
                            onClick={handleSetDetailedConsult}
                          >
                            Detailed Consult
                          </Button>
                        </Box>}
                      </>
                    ) : (
                      <></>
                    )}
                    {(isCurrentRole(AppConstants.ROLE.DOCTOR) ||
                      isCurrentRole(AppConstants.ROLE.DOCTORADMIN) ||
                      isCurrentRole(AppConstants.ROLE.ADMIN)) && isPrimaryProfile && (
                      <>
                        {!admitType.current ? (
                          <>
                            <Box
                              ml={4}
                              display={{ sm: "inline-block" }}
                              className={globalClass.largeBtnContainer}
                            >
                              <Button
                                className={classes.printBtnContainer}
                                style={{ width: "120px" }}
                                variant="contained"
                                color="primary"
                                onClick={printPrescription}
                              >
                                {isLoading ? (
                                  <CircularProgress
                                    disableShrink
                                    className={classes.loaderStyle}
                                    size={20}
                                  />
                                ) : (
                                  "Print"
                                )}
                              </Button>
                            </Box>
                          </>
                        ) : null}
                        {!admitType.current ? (
                          <>
                            <Box
                              ml={4}
                              display={{ sm: "inline-block" }}
                              className={globalClass.largeBtnContainer}
                            >
                              <Button
                                className={classes.printBtnContainer}
                                style={{ width: "120px" }}
                                variant="contained"
                                color="primary"
                                onClick={onToggleTemplateMode}
                              >
                                {"Save As template"}
                              </Button>
                            </Box>
                            <Box
                              ml={6}
                              display={{ sm: "inline-block" }}
                              className={globalClass.largeBtnContainer}
                            >
                              <Button
                                className={classes.printBtnContainer}
                                style={{ width: "120px" }}
                                variant="contained"
                                color="primary"
                                onClick={handleCallNextBtn}
                              >
                                {"Call Next Patient"}
                              </Button>
                            </Box>
                            <Box
                              ml={4}
                              display={{ sm: "inline-block" }}
                              className={globalClass.largeBtnContainer}
                            >
                              <Button
                                className={classes.printBtnContainer}
                                style={{ width: "120px" }}
                                variant="contained"
                                color="primary"
                                onClick={handleSendWhatsapp}
                              >
                                {"Send EMR on whatsapp"}
                              </Button>
                            </Box>
                            {
                              <Box
                                ml={4}
                                display={{ sm: "inline-block" }}
                                className={globalClass.largeBtnContainer}
                              >
                                {selectedEmrOptions?.admitPatient === "true" ? (
                                  <Button
                                    style={{ width: "120px" }}
                                    className={classes.printBtnContainer}
                                    variant="contained"
                                    color="primary"
                                    type="submit"
                                    onClick={() => {
                                      handleAdmitPatient();
                                    }}
                                  >
                                    {"Admit Patient"}
                                  </Button>
                                ) : (
                                  ""
                                )}
                              </Box>
                            }
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Box>
                  <Box
                    mt="1rem"
                    textAlign="center"
                    display={{ xs: "block", sm: "none" }}
                  >
                    <Typography className={globalClass.greyFont} variant="h6">
                      Edit Mode is turned off. To upload image please turn on
                      the Edit Mode.
                    </Typography>
                  </Box>
                </Form>
              </div>
            </Box>
          </>
        ) : (
          <Loader />
        )}
      </Box>

      <VitalsDialog
        handleClose={() => {
          onToggleVitalEditMode(vitalType);
        }}
        open={vitalEditMode || comEditMode}
        type={vitalType}
        selectedVitals={vitalType === "vitals" ? vitals : coMorbidities}
        updateVitalsCallback={
          vitalType === "vitals" ? setVitals : setCoMorbidities
        }
      />
      <TemplateDialog
        handleClose={onToggleTemplateMode}
        open={templateMode}
        uploadedDiagramid={uploadedDiagramid}
        savedPrescription={templateInfo}
        cb={fetchEmrTemplates}
      />

      <Dialog
        className={classes.dialogBackground}
        fullScreen
        open={openReport}
        onClose={handleCloseReport}
      >
        <div className={classes.reportContainer}>
          <div className={classes.reportClose} onClick={handleCloseReport}>
            <CloseIcon onClick={handleCloseReport} style={{cursor: "pointer"}}/>
          </div>
          <div className={classes.reportHeading}>
            <span>
              {`${selectSingleImage?.reportName ?? ""} / ${
                getFormattedDate(
                  selectSingleImage?.reportDate,
                  "Do MMMM YYYY"
                ) ?? ""
              }/
               Value: ${selectSingleImage?.value ?? ""}`}
            </span>
          </div>
        </div>
        {selectSingleImage?.images ? (
          selectSingleImage?.images.length === 1 ? (
            selectSingleImage?.images[0].contenttype === "application/pdf" ? (
              <iframe
                title="Report picture"
                src={selectSingleImage?.images[0].content}
                alt="Report"
                className={classes.reportImage}
                onClick={() =>
                  handleImageClick(selectSingleImage?.images[0].content)
                }
                style={{ cursor: "pointer" }}
              />
            ) : (
              <img
                title="Report picture"
                src={selectSingleImage.images[0].content}
                alt="Report"
                className={classes.reportImage}
                onClick={() =>
                  handleImageClick(selectSingleImage.images[0].content)
                }
                style={{ cursor: "pointer" }}
              />
            )
          ) : (
            <div className={classes.multipleImageContainer}>
              <Slider {...settings}>
                {selectSingleImage.images.map((im, index) => {
                  let fileType = null;
                  fileType = im.contenttype;
                  return (
                    <div key={index} style={{ width: "100%", height: "90vh" }}>
                      {im?.contenttype === "application/pdf" ? (
                        <iframe
                          title="Report picture"
                          src={im?.content}
                          alt="Report"
                          className={classes.reportImage}
                          onClick={() => handleImageClick(im?.content)}
                          style={{
                            cursor: "pointer",
                            width: "100%",
                            height: "90vh",
                          }}
                        />
                      ) : (
                        <img
                          title="Report picture"
                          src={im?.content}
                          alt="Report"
                          className={classes.reportMultipleImage}
                          onClick={() => handleImageClick(im?.content)}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </div>
                  );
                })}
              </Slider>
            </div>
          )
        ) : (
          <div className={classes.noReportImage}>
            Report image is not available.
          </div>
        )}
      </Dialog>
      {/* here admit patient container */}
      {open && (
        <AdmitPatient
          appointment={appointment}
          doctorSlug={doctorSlug}
          open={open}
          setOpen={setOpen}
          Prov={prov}
          Hopi={hopi}
          Chiefcomplaints={chiefComplaints}
          Examination={examination}
          medications={medications}
        />
      )}

      <Dialog
        className={classes.dialogBackground}
        fullScreen
        open={viewDiagram}
        onClose={() => setViewDiagram(false)}
      >
        <div className={classes.diagramContainer}>
          <div
            className={classes.diagramClose}
            onClick={() => setViewDiagram(false)}
          >
            <p className={classes.closeTitle}>Close</p>
          </div>
        </div>
        <img
          src={selectedDiagram}
          alt="Diagram"
          className={classes.diagramImage}
        />
      </Dialog>
    </>
  );
};

export const openImageGalleryInNewTab = (images) => {
  const html = `
    <!DOCTYPE html>
    <html lang="en">
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Prescription Images</title>
      <style>
        body, html {
          margin: 0;
          padding: 0;
          height: 100%;
        }
        .page {
          width: 100vw;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
          page-break-after: always;
        }
        img {
          max-width: 100%;
          max-height: 100%;
          object-fit: cover;
        }
      </style>
    </head>
    <body>
      ${images.map(img => `
        <div class="page">
          <img src="${img}" alt="Prescription Image">
        </div>
      `).join('')}
    </body>
    </html>
  `;

  const newWindow = window.open();
  newWindow.document.write(html);
  newWindow.document.close();
};

export default Prescription;
