const AppConstants = {
  THEME: {
    PRIMARY_COLOR: "#ED7E39",
    PRIMARY_COLOR_ALPHA: "#CCC",
  },
  AUTH_TOKEN_KEY: "X-AUTH-TOKEN",
  USER_KEY: "USER",
  CONFIG_KEY: "CONFIG",
  TENANT_KEY: "TENANT",
  SWITCH_PROFILE_REDUX_STATE: "SWITCH_PROFILE_REDUX_STATE",
  ICON: {
    DASHBOARD: "th-large",
    CATEGORY: "list-ul",
    VOUCHER: "receipt",
    COUNTRY: "globe-asia",
    USERS: "users",
    TXNS: "money-check-alt",
    CONTENT: "newspaper",
    USER: "user-circle",
    MEMBERSHIP: "wallet",
    LOGOUT: "sign-out-alt",
    IMAGE: "https://storage.googleapis.com/txr_assets/images/placeholder.png",
  },
  ENTITY: {
    APPOINTMENT: "APPOINTMENT",
    DOCTOR: "DOCTOR",
    PATIENT: "PATIENT",
    CHAT: "CHAT_SESSION",
    JOB: "JOB",
    CHAT_PACKAGE: "CHAT_PACKAGE",
    PAYMENT: "PAYMENT",
    CHECK_IN: "CHECK_IN",
    VITALS_NOTES: "VITALS_NOTES",
    CONSULTATION: "CONSULTATION",
    CHAT_SESSION: "CHAT_SESSION",
    TREATMENT: "TREATMENT",
    CALL_NEXT: "CALL_NEXT",
    CUSTOM_STATUS: "CUSTOM_STATUS",
  },

  ACCESS: {
    ANY: "ANY",
    READ: "READ",
    UPDATE: "UPDATE",
    DELETE: "DELETE",
    ADD: "ADD",
  },
  
  ROLE: {
    ADMIN: "ADMIN",
    DOCTOR: "DOCTOR",
    DOCTORADMIN : "DOCTOR_ADMIN",
    NURSE: "NURSE",
    ASSISTANT: "ASSISTANT",
    RECEPTIONIST: "RECEPTIONIST",
    QUEUE: "QUEUE",
  },

  MSG: {
    IMPORT_SUCCESS: "Imported successfully",
    GENERIC_ERROR: "Something bad happened!",
    ADD_SUCCESS: "Added successfully",
    UPDATE_SUCCESS: "Successfully updated",
    ADD_PATIENT_MSG: "No Patient Found. Please create a new patient ",
    NOT_COPIED_MSG: "No content to copy",
    CREATE_SUCCESS: "Created successfully",
    SELECT_TIME_MSG: "Please select time slot first",
    SELECT_DOC_MSG: "Please select doctor first",
    SELECT_FEES_MSG: "Fees cannot be blank",
    DELETE_SUCCESS: "Row(s) deleted successfully",
    SELECT_ROW_MSG: "Select at least one row to perform that action",
    SELECT_FILE_MSG: "Select an image to upload",
    COPY_WARN: "Only one item can be copied at a time",
    SELECT_DATE: "Please select appointment date",
    DELETE_CONFIRM: "Are you sure you ?",
    SELECT_STATUS_MSG: "Please select a status to change it",
    NO_SLOT_MSG: "Please select time slot ",
    ALL_FIELDS_REQUIRED: "All the fields are required!",
    INVALID_MOBILE_NUMBER: "InValid Mobile Number/Missing Country code",
    INVALID_EMAIL:"Invalid Email Id",
    INVALID_PASSWORD: "Invalid Password",
    EMAIL_FAILED: "Failed to Send Email"
  },
  ACTION_TYPE: {
    LATE: "LATE",
    LEAVE: "LEAVE",
  },
  PAGE_SIZE: 20,
  MEDICATION_FREQUENCIES: [
    "1-0-1",
    "1-1-1",
    "1-0-0",
    "0-1-0",
    "0-0-1",
    "0-1-1",
    "1-1-0",
  ],
  WHATSAPP_URL: "https://api.whatsapp.com/send/",
  FEATURE_ACCESS_LEVEL: {
    DRAFT_APPOINTMENT: "Draft Appointment",
  },
  DATE_FORMAT: "DD-MM-YYYY",
};

export const LOCAL_STORAGE_CONSTANTS = {
  CHECKED_IN_AUDIO: 'checkedInAudio'
}

export default AppConstants;
