import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import Routes from "../../routes/routes";
import Sidebar from "../../components/layout/sidebar";
import { useMediaQuery, Button } from "@material-ui/core";
import { SidebarContext } from "../../context/sidebar";
// import Dashboard from '../dashboard/dashboard';

import Doctor from "../doctor/doctor";
import Appointment from "../appointment/appointment";
import NewAppointment from "../appointment/new-appointment";
import Patient from "../patient/patient";
import NewPatient from "../patient/new-patient";
import Prescription from "../prescription/prescription";
import Chat from "../chat/chat";
import ChatPackage from "../chat-package/chat-package";
import NewChatPackage from "../chat-package/new-chat-package";
import AuthService from "../../utils/services/auth-service";
import PaymentPending from "../payment/pending";
import isAuthorised from "../../utils/role-util";
import AppConstants from "../../utils/app-constants";
import Specialists from "../specialist/specialist";
import SideTab from "../appointment/side-tab";
import { AppointmentContext } from "../../context/appointment";
import Emr from "../emr/emr";
import NewEmr from "../emr/new-emr";
import Report from "../appointment/report";
import Settings from "../settings/settings";
import Myavailability from "../appointment_type/myavailability";
import Customerstatus from "../customer-status/customer-status";
import Medication from "../medication/medication";
import AddMedication from "../medication/add.medication";
import OTPScreen from "../otp/otp";
import UpdateAppointment from "../update-appointment/update-appointment";
import NewStaff from "../doctor/new-staff";
import Quelists from "../que_system/que_system";
import Payment_option from "../payment_option/payment_option";
import ReferalDoctor from "../referal-doctor/referal-doctor";
import NewReferalDoctor from "../referal-doctor/new-referal-doctor";
import Social_media from "../social-media/social-media";
import Template from "../templates/templates";
import Website from "../website/website";
import Aboutus from "../aboutus/aboutus";
import Banner from "../banner/banner";
import Department from "../department/department";
import Precription from "../precription/precription";
import ProfileTab from "../profile/profileTab";
import PrecriptionPrint from "../appointment/precriptionPrint";
import QRstring from "../otherTab/QRstring";
import PrintLetterHead from "../appointment/letterHead";
import Receipt from "../receipt/receipt";
import chatImage from "../chat/chatImage";
//import appointment_type from '../appointment_type/appointment_type';
import ActiveScreenPatient from "../activePatientScreen/activePatient";
import TreatmentSummery from "../chat/treatmentSummery";
import ActivityRegistry from "../appointment/activityRegistry";
import AdmitedProfile from "../appointment/admitedProfile";
import Billing from "../appointment/billing";
import Compare from "../appointment/compare";
import NewReport from "../appointment/newReport";
import Folder from "../appointment/folder";
import visitRequest from "../appointment/visitRequest";
import FileList from "../appointment/filelist";
import Ipdrequest from "../appointment/ipdrequest";
import { useSelector } from "react-redux";
import { WaitingScreen } from "../waiting-screen";
import Job from "../job/Job";



const referalDoctor = [
  {
    displayName: "Name",
    name: "name",
    sequence: 0,
    dataType: "TEXT",
    validation: null,
    aggregationAllowed: false,
    localizedDisplay: {
      en: "Name",
    },
    collectionName: null,
    columnName: null,
    enumClass: null,
    defaultValue: null,
    allValues: null,
    locked: false,
    hidden: false,
    pinned: false,
  },
  {
    displayName: "Mobile Number",
    name: "mobileNumber",
    sequence: 1,
    dataType: "TEXT",
    validation: null,
    aggregationAllowed: false,
    localizedDisplay: {
      en: "Mobile Number",
    },
    collectionName: null,
    columnName: null,
    enumClass: null,
    defaultValue: null,
    allValues: null,
    locked: false,
    hidden: false,
    pinned: false,
  },
  {
    displayName: "Email",
    name: "email",
    sequence: 2,
    dataType: "TEXT",
    validation: null,
    aggregationAllowed: false,
    localizedDisplay: {
      en: "Email",
    },
    collectionName: null,
    columnName: null,
    enumClass: null,
    defaultValue: null,
    allValues: null,
    locked: false,
    hidden: false,
    pinned: false,
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
  },
  shiftContent: {
    // paddingLeft: 240,
    paddingLeft: 50,
  },
  content: {
    height: "100%",
    minHeight: "100vh",
    backgroundColor: theme.palette.bg,
  },
}));

const Main = () => {
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const isDesktop = useMediaQuery('(min-width: 1280px)');
  const isMobile = useMediaQuery('(max-width: 600px)');
  const sidebarContext = SidebarContext.useContainer();
  const [shiftContent, setShiftContent] = useState({ paddingLeft: 0 });
  
  // --- for profile switching -----------
  const isPrimaryProfile = useSelector(state => state.switchProfile?.isPrimaryProfile);

  useEffect(() => {
    if(!isDesktop){
     setShiftContent({paddingLeft: 0});
    }else {
     setShiftContent({paddingLeft: 240});
    }
  }, [isDesktop])


  const handleSidebarClose = () => {
    sidebarContext.handleSidebarClose();

    if(!isDesktop){
      setShiftContent({paddingLeft: 0});
    }
  }

  const handleToggle = () => {
    sidebarContext.handleSidebarOpen();
      setShiftContent((prevState) => ({
        ...prevState,
        paddingLeft: isMobile ? 0 : prevState.paddingLeft === 0 ? 240 : 240,
      })); 
  };

  const reduceTogglewidth = () => {
    if(isDesktop){
    setShiftContent((prevState) => ({
      ...prevState,
      paddingLeft: isMobile ? 0 : prevState.paddingLeft === 240 ? 0 : 240,
    }));
  }else {
    handleSidebarClose();
  }
  };



  const user = AuthService.getUser();

  let history = useHistory();
  AuthService.validateToken().subscribe(
    (data) => {
      AuthService.fetchConfig().subscribe((resp) => {
        const configData = resp.data;
        configData.referalDoctorField = referalDoctor;
        AuthService.setConfig(configData);
      });
    },
    (error) => {
      AuthService.clearLocalStorage();
      history.replace(Routes.LOGIN);
    }
  );

  const appntmentCntxt = AppointmentContext.useContainer();
  const shouldOpenSidebar = isDesktop ? true : sidebarContext.openSidebar;
  const appointmentDashboardPage = location.pathname !== "/app/dashboard";
  const receiptPrintPage = location.pathname.startsWith('/app/appointment/receiptprint');
  const isQueueUser = user?.roleName === AppConstants.ROLE.QUEUE;
  const isActivePatient = location.pathname === Routes.ActivePatient;

  return (

    <div className={classes.root}>
      <div className={classes.content} style={(!appointmentDashboardPage || receiptPrintPage) ? {} : shiftContent}>

      <div
          className={`${clsx({ [classes.root]: true })} 
             ${appointmentDashboardPage && !receiptPrintPage
              ? clsx({ [classes.shiftContent]: isDesktop })
              : !isQueueUser && !receiptPrintPage
                ? clsx({ [classes.shiftContent]: isDesktop })
                : ""
            }
           `}
        >
          <Route
            render={({ location }) =>
              location.pathname === Routes.APPOINTMENTPRINT ||
                location.pathname === Routes.QR_STRING ||
                location.pathname === Routes.APPOINTMENTPRINTHEAD ||
                location.pathname === Routes.ActivePatient ||
                location.pathname === Routes.RECEIPTPRINT ? null : (
                <>
                  {appointmentDashboardPage ? (
                    <Sidebar
                      reducewidth={reduceTogglewidth}
                      shiftContent={handleToggle}
                      onClose={handleSidebarClose}
                      open={shouldOpenSidebar}
                      variant={isDesktop ? "persistent" : "temporary"}
                    />
                  ) : !isQueueUser && !isActivePatient ? (
                    <Sidebar
                      onClose={handleSidebarClose}
                      open={shouldOpenSidebar}
                      variant={isDesktop ? "persistent" : "temporary"}
                    />
                  ) : (
                    ""
                  )}
                </>
              )
            }
          />

          <main className={classes.content}>
            {user?.roleName === AppConstants.ROLE.QUEUE ? (
              <Switch>
                <Route exact path={Routes.DASHBOARD} component={WaitingScreen} />
                <Redirect to={Routes.DASHBOARD} />
              </Switch>
            ) : (
              <Switch>
                {isAuthorised(AppConstants.ENTITY.APPOINTMENT) && (
                  <Route exact path={Routes.APPOINTMENT} component={Appointment} />
                )}
                <Route
                  exact
                  path={Routes.TREATMENTSUMMERY}
                  component={TreatmentSummery}
                />
                <Route
                  exact
                  path={Routes.ActivePatient}
                  component={ActiveScreenPatient}
                />
                <Route
                  exact
                  path={Routes.APPOINTMENTPRINT}
                  component={PrecriptionPrint}
                />
                <Route
                  exact
                  path={Routes.APPOINTMENTPRINTHEAD}
                  component={PrintLetterHead}
                />
                RECEIPTPRINT
                <Route exact path={Routes.RECEIPTPRINT} component={Receipt} />
                {isAuthorised(AppConstants.ENTITY.APPOINTMENT) && (
                  <Route
                    exact
                    path={Routes.NEW_APPOINTMENT}
                    component={NewAppointment}
                  />
                )}
                {isAuthorised(AppConstants.ENTITY.APPOINTMENT) && isPrimaryProfile && (
                  <Route
                    exact
                    path={Routes.UPDATE_APPOINTMENT}
                    component={UpdateAppointment}
                  />
                )}
                <Route exact path={Routes.REPORT} component={Report} />
                {isAuthorised(AppConstants.ENTITY.CHAT) && (
                  <Route exact path={Routes.CHAT} component={Chat} />
                )}
                <Route exact path={Routes.CHATIMAGE} component={chatImage} />
                {/* <Route exact path={Routes.SEARCHFUNCTION} component={searchFunction} /> */}
                <Route exact path={Routes.PROFILE} component={ProfileTab} />
                <Route exact path={Routes.QR_STRING} component={QRstring} />
                <Route exact path={Routes.JOB} component={Job}/>
                {isAuthorised(AppConstants.ENTITY.PATIENT) && (
                  <Route exact path={Routes.PATIENT} component={Patient} />
                )}
                {isAuthorised(AppConstants.ENTITY.PATIENT) && (
                  <Route exact path={Routes.NEW_PATIENT} component={NewPatient} />
                )}
                <Route exact path={Routes.OTP} component={OTPScreen} />
                {isAuthorised(AppConstants.ENTITY.CHAT_PACKAGE) && (
                  <Route exact path={Routes.CHAT_PACKAGE} component={ChatPackage} />
                )}
                {isAuthorised(AppConstants.ENTITY.CHAT_PACKAGE) && (
                  <Route
                    exact
                    path={Routes.NEW_CHAT_PACKAGE}
                    component={NewChatPackage}
                  />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.DOCTOR} component={Doctor} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.NEW_DOCTOR} component={NewStaff} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route
                    exact
                    path={Routes.REFERAL_DOCTOR}
                    component={ReferalDoctor}
                  />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.Specialists} component={Specialists} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route
                    exact
                    path={Routes.Payment_option}
                    component={Payment_option}
                  />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route
                    exact
                    path={Routes.CREATE_REFERAL_DOCTOR}
                    component={NewReferalDoctor}
                  />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.EMR} component={Emr} />
                )}
                <Route exact path={Routes.MEDICATION} component={Medication} />
                <Route
                  exact
                  path={Routes.NEW_MEDICATION}
                  component={AddMedication}
                />
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.NEW_EMR} component={NewEmr} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.UPDATE_EMR} component={NewEmr} />
                )}
                {isAuthorised(AppConstants.ENTITY.PAYMENT) && (
                  <Route exact path={Routes.PAYMENT} component={PaymentPending} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.SETTINGS} component={Settings} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.IPDREQUEST} component={Ipdrequest} />
                )}

                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route
                    exact
                    path={Routes.Appointment_type}
                    component={Myavailability}
                  />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route
                    exact
                    path={Routes.Customerstatus}
                    component={Customerstatus}
                  />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.Quelists} component={Quelists} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.Precription} component={Precription} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route
                    exact
                    path={Routes.Social_media}
                    component={Social_media}
                  />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.Template} component={Template} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.Website} component={Website} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.Aboutus} component={Aboutus} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.Banner} component={Banner} />
                )}
                {isAuthorised(AppConstants.ENTITY.DOCTOR) && (
                  <Route exact path={Routes.Department} component={Department} />
                )}
                <Route exact path={Routes.PRESCRIPTION} component={Prescription} />
                <Route exact path={Routes.ACTIVITYREGISTRY} component={ActivityRegistry} />
                <Route exact path={`${Routes.ADMITEDPROFILE}/:id2`} component={AdmitedProfile} />
                <Route exact path={Routes.BILLING} component={Billing} />
                <Route exact path={`${Routes.COMPARE}`} component={Compare} />
                <Route exact path={`${Routes.NEWREPORT}`} component={NewReport} />
                <Route exact path={`${Routes.VIEWFOLDER}`} component={Folder} />
                <Route exact path={`${Routes.VISITREQUEST}`} component={visitRequest} />
                <Route exact path={`${Routes.FILELIST}`} component={FileList} />





                {/*

          <Route exact path={Routes.SPECIALIST} component={Category} /> */}
                <Redirect to={Routes.APPOINTMENT} />
              </Switch>
            )}
          </main>

          <SideTab
            data={appntmentCntxt.currentAptmnt}
            open={appntmentCntxt.isSideTabOpen}
            toggleSideTab={appntmentCntxt.toggleSideTab}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;