import {
  Box,
  DialogContent,
  Divider,
  makeStyles,
  Typography,
  CircularProgress,
  Checkbox,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form } from "informed";
import React, { useState, useEffect } from "react";
import Api from "../../utils/api";
import Api2 from "../../utils/api2";
import {
  SHOW_SUCCESS_NOTIFICATION,
  SHOW_INFO_NOTIFICATION
} from "../../utils/app-util";
import useGlobalStyles from "../../utils/global-style";
import clsx from "clsx";
import Bro from "brototype";
import Axios from "axios";
import AuthService from "../../utils/services/auth-service";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { AppointmentAPI } from "../../apis/AppointmentAPI";
import { useSelector } from "react-redux";
import { TextInput } from "sixsprints-react-ui";

const useStyles = makeStyles((theme) => ({
  heading: {
    fontSize: ".7rem",
    fontWeight: "bold",
  },
  value: {
    fontSize: ".7rem",
  },
  loaderStyle: {
    color: theme.palette.common.primary,
  },
}));

const RescheduleDialog = ({
  open = false,
  handleClose,
  appointmentObj,
}) => {
  const classes = useStyles();
  const globalClass = useGlobalStyles();
  const [selectedTime, setSelectedTime] = useState(null);
  const currentUser = useSelector((state) => state?.user);

  const [selectedDate, setSelectedDate] = useState(dayjs(appointmentObj?.date));
  const [slots, setSlots] = useState(null);
  const [loading, setLoading] = useState(false);
  const selectedDoc = Bro(appointmentObj).iCanHaz("doctorSlug");
  const [notify, setNotify] = useState(true);
  const [uniqueslotId, setuniqueSlotId] = useState();
  const [appointmentSlug, setAppointmentSlug] = useState(appointmentObj?.slug);
  const [oldDate, setOldDate] = useState(appointmentObj?.date);
  const [oldTime, setOldTime] = useState(appointmentObj?.time);
  const onDateSelected = (val) => {
    setSelectedDate(val);
    if (selectedDoc) {
      fetchSlots(selectedDoc, val);
    }
  };

  const onSlotSelected = (time) => {
    if (selectedTime) {
      setSelectedTime(time.startTimeOnly);
      return;
    }
    setSelectedTime(time.startTimeOnly);
  };

  const fetchSlots = () => {
    setLoading(true);
    if (selectedDoc) {
      const rescheduledDateFormatted = selectedDate.format("YYYY-MM-DD");
      Api.get(
        `${process.env.REACT_APP_API2_HOST}get_slots_of_doctor_by_date?slug=${selectedDoc}&date=${rescheduledDateFormatted}&appointmentId=${uniqueslotId?.appointmentType}`
      ).subscribe((resp) => {
        setSlots(resp.data.data);
        setLoading(false);
      });
    }
  };

  useEffect(fetchSlots, [
    selectedDoc,
    selectedDate,
    uniqueslotId?.appointmentType,
  ]);

  const handleSubmit = (val) => {
    const reqParams = { ...appointmentObj };

    if (!val.slot && !selectedTime) {
      SHOW_INFO_NOTIFICATION("Please Select Time Slot");
      return;
    }

    reqParams.date = selectedDate.valueOf();
    reqParams.time = val.slot || selectedTime;

    // Check if an appointment already exists on the selected date
    AppointmentAPI.checkExistingAppointment(
      appointmentObj?.doctorSlug,
      appointmentObj?.patientSlug,
      selectedDate.format("YYYY-MM-DD")
    ).then((status) => {
      if (status) {
        SHOW_INFO_NOTIFICATION("Appointment already exists on this date");
      } else {
        // Instead of updating the current appointment -> cancel and create a new appointment with the same details
        AppointmentAPI.updateAppointmentStatus(
          appointmentObj?.slug,
          "CANCELLED"
        ).then((_) => {
          // create new appointment with the same details
          AppointmentAPI.addNewAppointment({
            startDateTime: `${selectedDate.format("YYYY-MM-DD")} ${selectedTime}`,
            endDateTime: `${selectedDate.format("YYYY-MM-DD")} ${addMinutes(
              selectedTime,
              uniqueslotId?.minutes
            )}`,
            doctorSlug: appointmentObj.doctorSlug,
            patientSlug: appointmentObj.patientSlug,
            minutes: uniqueslotId?.minutes,
            fees: appointmentObj?.fees,
            discount: appointmentObj.discount,
            creatorName: appointmentObj.creatorName,
            creatorRole: appointmentObj.creatorRole,
            creatorNumber: currentUser?.mobileNumber,
            appointmentTypeId: appointmentObj.appointmentTypeId,
            from: currentUser?.roleName,
            remark: appointmentObj.remarks,
            queue: 0,
            paymentMode: appointmentObj.mode,
            slotId: appointmentObj?.slotId,
            notifyPatient: false,
            isFreeChatActive: appointmentObj.isFreeChatActive,
            isFriends: appointmentObj.isFriends,
            isFamily: appointmentObj.isFamily,
          }).then((_) => {
            SHOW_SUCCESS_NOTIFICATION("Rescheduled successfully")
          });
        });
      }
    })

    if (notify) {
      const formData = new FormData();
      formData.append("appointmentSlug", appointmentSlug);
      formData.append(
        "oldDate",
        moment(oldDate).format("YYYY-MM-DD") + " " + oldTime
      );
      formData.append(
        "newDate",
        selectedDate.format("YYYY-MM-DD") + " " + selectedTime + ":00"
      );

      Api2.post("/notify_appointment_change", formData).subscribe((resp) => {
        console.log(resp);
      });
    }
    handleClose();
  };

  const userId = appointmentObj?.doctor?.id;
  const [AllAppoinmentData, setAllAppointmentData] = useState([]);
  const [appointmentType, setAppointmentType] = useState(null);

  useEffect(() => {
    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_appointment_time_doctor?id=${userId}`
    ).then((resp) => {
      if (resp) {
        if (resp.data === null) {
          setAllAppointmentData([]);
        } else {
          setAllAppointmentData(resp?.data);
        }
        // setLoading(true)
      }
    });

    Axios.get(
      `${process.env.REACT_APP_API2_HOST}get_doctor_appointment_types?id=${userId}`
    ).then((resp) => {
      if (resp) {
        setAppointmentType(resp.data);
      }
    });
  }, []);

  useEffect(() => {
    AllAppoinmentData.map((item) => {
      if (item._id === appointmentObj.slotId) {
        setuniqueSlotId(item);
      }
    });
  }, [appointmentObj.id, AllAppoinmentData]);

  const onNotifyClick = (event) => {
    setNotify(event.target.checked);
  };

  const handleTimeSelection = (selectedTime) => {

    setSelectedTime(selectedTime);
  };

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"sm"}
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" className={classes.heading}>
        Reschedule Appointment
      </DialogTitle>
      <Divider />
      <Form
        onSubmit={handleSubmit}
        initialValues={{ date: appointmentObj.date }}
      >
        <DialogContent>
          <Box className={classes.prescriptionSection}>
            <Box display="flex" sx={{alignItems: "center", gap: "5px"}}>
              <DatePicker
                label="Receipt Date (DD/MM/YYYY)"
                value={selectedDate}
                onChange={(newValue) => {
                  onDateSelected(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" />
                )}
                className={clsx(
                  globalClass.roundWhiteBg,
                  globalClass.inputWidth
                )}
                format="DD/MM/YYYY"
              />
              <TextInput
                className={clsx(
                  globalClass.roundWhiteBg,
                  globalClass.inputWidth
                )}
                containerClassName="mr"
                field="slot"
                value={selectedTime}
                label="Time Slot"
                type="time"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
                validateOnBlur
                validateOnChange
                onChange={(e) => handleTimeSelection(e.target.value)}
              />
            </Box>
          </Box>
          <div>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "2rem",
                }}
              >
                <CircularProgress
                  disableShrink
                  className={classes.loaderStyle}
                  size={20}
                />
              </div>
            ) : (
              slots && (
                <Box m={4} justifyContent="center">
                  <Typography variant="subtitle2">
                    <strong>{"Select Time"}</strong>
                  </Typography>

                  <Box mt={3}>
                    {slots.length === 0 ? (
                      <p>No Slot Available </p>
                    ) : (
                      slots &&
                      slots?.map((item, index) => {
                        // new Intl.DateTimeFormat('en-US', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(item.minutes);

                        var dis = false;
                        if (!item.available) {
                          dis = true;
                        }
                        return (
                          <Box
                            className={classes.mb}
                            display="inline-block"
                            mr={1}
                            key={index}
                          >
                            <Button
                              onClick={onSlotSelected.bind(this, item, index)}
                              color={
                                selectedTime === item.startTimeOnly
                                  ? "secondary"
                                  : "default"
                              }
                              disabled={dis}
                              variant={
                                selectedTime === item.startTimeString
                                  ? "outlined"
                                  : "contained"
                              }
                              className={classes.slots}
                            >
                              {item.startTimeOnly}
                            </Button>
                          </Box>
                        );
                      })
                    )}
                  </Box>
                </Box>
              )
            )}

            <Box>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox defaultChecked onChange={onNotifyClick} />}
                  label="Notify"
                />
              </FormGroup>
            </Box>
          </div>
          <Button
            style={{ marginTop: "18px" }}
            className={globalClass.largeBtnContainer}
            type="submit"
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </DialogContent>
      </Form>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

function addMinutes(time, minsToAdd) {
  if (time && minsToAdd) {
    function D(J) {
      return (J < 10 ? "0" : "") + J;
    }
    var piece = time?.split(":");
    var mins = piece[0] * 60 + +piece[1] + +minsToAdd;
    return D(((mins % (24 * 60)) / 60) | 0) + ":" + D(mins % 60);
  }
}

export default RescheduleDialog;
