import Api from "../utils/api";
import { useQuery, useMutation } from '@tanstack/react-query'
import { SHOW_SUCCESS_NOTIFICATION, SHOW_ERROR_NOTIFICATION } from '../utils/app-util';

export const AddJob = () => {
    return useMutation({
        mutationFn: async (payload) => {
            const response = await Api.post("/jobs", payload).toPromise();
            return response.data;
        },
        onSuccess: (data) => {
            if (data.status === true) {
                SHOW_SUCCESS_NOTIFICATION(data.message);
            } else {
                SHOW_ERROR_NOTIFICATION(data.message);
            }
        },
        onError: (error) => {
            if (error.response?.data?.message) {
                SHOW_ERROR_NOTIFICATION(error.response?.data?.message);
            } else {
                SHOW_ERROR_NOTIFICATION("An error occurred while adding the job");
            }
        }
    })
}

export const GetJobs = () => {
    return useQuery({
        queryKey: ['jobs'],
        queryFn: async () => {
            const response = await Api.get("/jobs").toPromise();
            return response.data;
        }
    })
}

export const EditJob = () => {
    return useMutation({
        mutationFn: async ({payload, id}) => {
            const response = await Api.put(`/jobs/${id}`, payload).toPromise();
            return response.data;
        },
        onSuccess: (data) => {
            if (data.status === true) {
                SHOW_SUCCESS_NOTIFICATION(data.message);
            }
        },
        onError: () => {
            SHOW_ERROR_NOTIFICATION("An error occurred while editing the job");
        }
    })
}

export const DeleteJob = () => {
    return useMutation({
        mutationFn: async (id) => {
            const response = await Api.delete(`/jobs/${id}`).toPromise();
            return response.data;
        },
        onSuccess: () => {
            SHOW_SUCCESS_NOTIFICATION("Deleted successfully");
        },
        onError: () => {
            SHOW_ERROR_NOTIFICATION("An error occurred while deleting the job");
        }
    })
}

export const SaveJobComment = () => {
    return useMutation({
        mutationFn: async ({payload}) => {
            const response = await Api.post(`/jobs/save-comment/`, payload).toPromise();
            return response.data;
        },
        onSuccess: () => {
            SHOW_SUCCESS_NOTIFICATION("Comment saved successfully");
        },
        
    })
}

export const UpdateJobStatus = () => {
    return useMutation({
        mutationFn: async ({payload, id}) => {
            const response = await Api.post(`/jobs/update-status/${id}`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).toPromise();
            return response.data;
        },
        onSuccess: () => {
            SHOW_SUCCESS_NOTIFICATION("Updated successfully");
        },
        onError: () => {
            SHOW_ERROR_NOTIFICATION("An error occurred while updating the job");
        }
    })
}

export const BatchDeleteJob = () => {
    return useMutation({
        mutationFn: async (payload) => {
            const response = await Api.post("/jobs/batch-delete-jobs", payload).toPromise();
            return response.data;
        },
        onSuccess: () => {
            SHOW_SUCCESS_NOTIFICATION("Deleted successfully");
        },
        onError: () => {
            SHOW_ERROR_NOTIFICATION("An error occurred while deleting the job");
        }
    })
}

export const UpdateBatchJobStatus = () => {
    return useMutation({
        mutationFn: async ({payload}) => {
            const response = await Api.post(`/jobs/update-status-multiple/`, payload).toPromise();
            return response.data;
        },
        onSuccess: () => {
            SHOW_SUCCESS_NOTIFICATION("Updated successfully");
        },
        onError: () => {
            SHOW_ERROR_NOTIFICATION("An error occurred while updating the job");
        }
    })
}