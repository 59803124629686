import { Box, DialogContent, Divider, makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Form } from "informed";
import React, { useEffect, useRef, useState } from "react";
import { SelectInput, TextInput } from "sixsprints-react-ui";
import Api from "../../utils/api";
import {
  DEFAULT_ERROR_CALLBACK,
  SHOW_SUCCESS_NOTIFICATION,
} from "../../utils/app-util";
import Bro from "brototype";
import useGlobalStyles from "../../utils/global-style";
import { isRequired } from "../../utils/validations";
import Axios from "axios";

const useStyles = makeStyles((theme) => ({
  templateName: {
    marginRight: "1rem",
  },

  prescriptionSection: {
    marginBottom: "2rem",
  },

  prescriptionTitle: {
    lineHeight: "normal",
    marginBottom: "10px",
  },
}));

const TemplateDialog = ({
  open = false,
  handleClose,
  savedPrescription,
  cb,
  uploadedDiagramid,
}) => {
  const globalClass = useGlobalStyles();
  const classes = useStyles();
  const [specialities, setSpecialities] = useState([]);
  const formApi = useRef();

  // const fetchSpecialities = () => {
  //   DataService.getList(`/data/specialities`).subscribe((resp) => {
  //     setSpecialities(resp);
  //   });
  // };

  useEffect(() => {
    Axios.get(`${process.env.REACT_APP_API2_HOST}getspeciality`).then(
      (resp) => {
        if (resp) {
          setSpecialities(resp?.data);
          // setSpecial(resp?.data?.name)
        }
      }
    );
  }, []);


  const handleSubmit = (val) => {

    const user = JSON.parse(localStorage.getItem("USER"));

    const requestbody = {
      name: val.name,
      specialitySlug: val.specialitySlug,
      createdBySlug: user?.slug,

      template: {
        chiefComplaints: Bro(savedPrescription).iCanHaz("chiefComplaints"),
        diagramIds: uploadedDiagramid.map((imageId)=> imageId.imageId),
        dietaryRestriction: Bro(savedPrescription).iCanHaz(
          "dietaryRestriction"
        ),
        prov: Bro(savedPrescription).iCanHaz("prov"),
        hopi: Bro(savedPrescription).iCanHaz("hopi"),
        treatment: Bro(savedPrescription).iCanHaz("treatment"),
        surgicalPlan: Bro(savedPrescription).iCanHaz("surgicalPlan"),
        investigation: Bro(savedPrescription).iCanHaz("investigation"),
        medications: Bro(savedPrescription).iCanHaz("medications"),

      },
    };
    Api.post(`emr-template`, requestbody).subscribe(
      () => {
        SHOW_SUCCESS_NOTIFICATION("Saved successfully !");
        cb();
      },
      (error) => {
        DEFAULT_ERROR_CALLBACK(error);
      }
    );
    handleClose();
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Add Template Info"}</DialogTitle>
        <Divider />
        <Form apiRef={formApi} onSubmit={handleSubmit}>
          <DialogContent>
            <Box className={classes.prescriptionSection}>
              <Box display="flex">
                <TextInput
                  className={globalClass.roundWhiteBg}
                  containerClassName={classes.templateName}
                  required
                  field="name"
                  type="text"
                  label="Template Name"
                  validateOnBlur
                  validateOnChange
                />
                <SelectInput
                  required
                  className={globalClass.roundWhiteBg}
                  options={specialities}
                  validate={isRequired}
                  field="specialitySlug"
                  placeholder="Speciality"
                  label="Speciality"
                  labelKey="name"
                  valueKey="_id"
                  validateOnBlur
                  validateOnChange
                />
              </Box>
            </Box>
            <Button
              className={globalClass.largeBtnContainer}
              type="submit"
              variant="contained"
              color="primary"
            >
              Save
            </Button>
          </DialogContent>
        </Form>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TemplateDialog;
